import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from "@angular/core";
import { HelperServiceService } from "src/app/helper-service.service";
import { IdVerificationService } from "src/app/id-verification/id-verification.service";
import { ElementsService } from "../../elements.service";

@Component({
  selector: "app-related-party-content-sp-side",
  templateUrl: "./related-party-content-sp-side.component.html",
  styleUrls: ["./related-party-content-sp-side.component.scss"],
})
export class RelatedPartyContentSpSideComponent implements OnInit {
  @Input() element;
  @Input() relatedParty;
  @Output() contentReturn = new EventEmitter<any>();
  @Output() displayLoader = new EventEmitter<any>();
  constructor(
    private elementService: ElementsService,
    private idVerificationService: IdVerificationService,
    private helperService: HelperServiceService,
  ) {}

  ngOnInit(): void {}

  /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
  onEmit(emitPayload) {
    if (emitPayload) {
      this.contentReturn.emit(emitPayload);
    }
  }

  /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
  onDisplayLoader(emitPayload: boolean) {
    if (emitPayload) {
      this.displayLoader.emit(emitPayload);
    }
  }

  relatedPartyUpdate() {
    this.onEmit({
      method: "OnUpdateActiveRelatedParty",
    });
  }

  documentUploadScreenRelatedParty(inputargs) {
    this.idVerificationService.skipToStep(6, inputargs);
  }
}
