import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { param } from "jquery";
import { Subject, of, forkJoin, concat, Subscription } from "rxjs";
import { buffer, catchError, share, takeUntil, toArray, tap } from "rxjs/operators";
import { HelperServiceService } from "src/app/helper-service.service";
import { IdVerificationService } from "src/app/id-verification/id-verification.service";
import { SidebarComponent } from "src/app/ui/components/sidebar/sidebar.component";
import { UserService } from "src/app/user.service";
import { trigger, state, style, animate, transition } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, Inject } from '@angular/core';

@Component({
  selector: 'app-new-cdd-process-control-screen',
  templateUrl: './new-cdd-process-control-screen.component.html',
  styleUrls: ['./new-cdd-process-control-screen.component.scss'],
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ height: 0, opacity: 0 }),
            animate('1s ease-out', 
                    style({ height: 300, opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ height: 300, opacity: 1 }),
            animate('0.5s ease-in', 
                    style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class NewCddProcessControlScreenComponent implements OnInit, OnDestroy {


  @ViewChild('sideBar', { static: false }) sideBarComponent: SidebarComponent;

  @Output() setReviewStatus = new EventEmitter<any>();
  animateOnMobile: boolean;

  activeInvestor;
  activeProfileNavBar;
  projectkey: string;
  requestKey: string;
  profileDVLoading = true;
  mainProfileRequestKey: string;
  reviewStatus;
  dvStatus;
  showPCSScreen: boolean = false;
  showARPCSScreen: boolean = false;
  displayRightPanel = true;


  // branding
  backgroundColor: string = "#ffffff";
  logo= "assets/images/logo-alternate.svg";

  activeEvidence: string = 'basic-information';
  currentEvidenceKey: any;
  verificationLink: any;
  currentRequestKey: any;
  currentEvidence: any;
  currentNavLink: any;
  theProcessControllIsCompleted : boolean = false;

  navBar = [];

  kvkResults: any;

  // if AR and LP some fields needs to be locked, or SP locked some fields
  fieldsLocked: string[] = [];

  fullyCompleted = false;
  verificationHeading;
  showConfirmationWindow = false;
  confirmationChoice = false;
  confirmationLoading = false;
  paramArguments;
  confirmationError;
  legalPersonTypeList;
  relatedLegalPersonTypeList;
  notAllowedLegalTypes;
  relatedPartyRoles;

  // relatedPartyRoles;
  showLanguageOption;
  displayInvestorReviewStatus: Subscription;
  reviewWindowClosed: Subscription;
  investorKey;
  projectTextColorClass = "dark-color";
  basicInformationWindow = false;
  requestCallResponse;

  // function to start a different review process
  // @Output() pcsReviewFunction = new EventEmitter<string>();
  activePill = null; // currently active pill


  contentDom = [];
  errorState = false;
  waitingState = false;
  stateMessage = this.translate.instant("ARPCS.errorMessage");
  displayLoader;
  record;

  // the asumption right now is that if we are on this page the user has already given
  // consent in the previous screen. Given that they may get to this screen
  // by typing url the consentParsed should be populated through a service
  consentParsed = true;
  constructor(
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private userService: UserService,
    private idVerificationService: IdVerificationService,
    private helperService: HelperServiceService,
    public translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) 
  {
    this.animateOnMobile = isPlatformBrowser(this.platformId) && window.innerWidth < 768;
  }


  ngOnInit(): void {
    $('body').addClass('body-bg-white');
    this.displayLoader = true;
    this.projectkey = this.idVerificationService.getActiveProject();
    // ####### imashe del if (!this.isReview) ama kolku hsto vidv segde se vrakja isReview da bide true taka da brishi

    // The request has gone trough the PCS proces or is not an automatic request, we proceed here
    this.displayInvestorReviewStatus =
      this.idVerificationService.detectisplayLoadInvestorReviewStatus.subscribe(
        async (statuses) => {
          if (statuses === "loading") {
            return;
          } else {
            this.displayLoader = false;
            this.showLanguageOption = true;

            this.reviewStatus = statuses.reviewState;
            this.dvStatus = statuses.dvState;

            this.getActiveInvestor();
            // ###############
            // ###########
            if (this.activeInvestor?.requestingParties?.[0]['projectBranding']) {
              this.getprojectBranding(this.activeInvestor?.requestingParties?.[0]['projectBranding']);
            }

            // decide wether to generate the PC or show the AR screen

            if(this.activeInvestor.typeOfInvestor === 'AutomaticRequest' 
              || (this.activeInvestor.isAutomaticRequest && !this.activeInvestor.convertedOn)) 
            {
              this.generateTheARPCS();
              // generate AR screen
            } else {
              let status;
              if(statuses.reviewState === 'review') {
                status = 'initial';
              }
              this.generateThePCS(status);
            }
          }
        },
      );

      // load related parties separately

      // ################ ova da go proveram
    this.reviewWindowClosed =
      this.idVerificationService.detectReviewWindowClosed.subscribe(
        (status) => {
          if (status === "resetData") {
            // resets the data from the BE
            const currentRoute = this.router.url;
            this.router
              .navigateByUrl("/verifications", { skipLocationChange: true })
              .then(() => {
                this.router.navigate([currentRoute]); // navigate to same route
              });
          } else {
            // resets the data internally
            this.idVerificationService.parseElementsForReview(
              this.idVerificationService.getActiveProject(),
              this.idVerificationService.getActiveInvestor(),
              this.idVerificationService.getActiveInvestorDetaisl()
                .relatedParties,
            );
          }
        },
      );

  }

  goToDashboard()
  {
    this.setReviewStatus.emit('no-review');
  }
  setTheProcessControllIsCompleted(theProcessControllIsCompleted)
  {
    this.theProcessControllIsCompleted = theProcessControllIsCompleted;
  }
  getActiveInvestor()
  {
    this.activeInvestor = this.idVerificationService.getActiveInvestors();
    if(this.activeInvestor && this.activeInvestor[0])
    {
      this.requestKey = this.activeInvestor[0].key
      this.mainProfileRequestKey = this.requestKey;
      if(this.activeInvestor[0].record)
      {
        this.activeInvestor = this.activeInvestor[0].record;
        this.projectkey =  this.activeInvestor.investorProjectKey;
        this.fieldsLocked = this.activeInvestor.fieldsSource && this.activeInvestor.fieldsSource[0]?.fieldsLocked && this.activeInvestor.fieldsSource[0].fieldNames ? this.activeInvestor.fieldsSource[0]?.fieldNames : [];
      }
    }
    this.activeProfileNavBar = this.activeInvestor;

  }
  async getprojectBranding(projectBranding)
  {
    // project color
    if(projectBranding.projectColor)
    {
      // branding presentf
      this.backgroundColor = projectBranding.projectColor;
      this.projectTextColorClass =
        this.helperService.detectTextColor(
          this.helperService.hexToRgb(projectBranding.projectColor),
        );
    }

    // project logo
    if (projectBranding.projectLogo) {
      this.logo = projectBranding.projectLogo;
    }
    else {
      // fetch the logo
      const endpoint =
        "/api/projects/" +
        this.activeInvestor.serviceProviderProjectKey +
        "/logo";
      if (
        !this.logo &&
        this.reviewStatus === "loading" &&
        this.dvStatus === "loading"
      ) {
        await this.userService
          .getEndPoint(
            endpoint,
            { responseType: "blob" },
            { observe: "body" },
            "funds",
          )
          .subscribe(
            async (brandingResponse) => {
              if (brandingResponse === undefined) {
                console.log("Error while fetching organization logo");
                this.logo = "assets/images/logo-alternate.svg";
                return;
              }

              if (brandingResponse["type"] === "application/json") {
                this.logo = JSON.parse(
                  await (brandingResponse as Blob).text(),
                ).link;
              } else {
                const urlCreator = window.URL
                  ? window.URL
                  : (window as any).webkitURL;
                this.logo = urlCreator.createObjectURL(brandingResponse);
              }
            },
            (error) => {
              console.log("Error while fetching organization logo");
              this.logo = "assets/images/logo-alternate.svg";
            },
          );
      }
    }
  }
  generateEvidenceForProfile(profile, relatedPartyKey?)
  {

    //GO TROUGH DIGITAL VERIFICATIONS , CONTRACT, IDV GO AND REMOVE COMPLETED STEPS IN CASE THERE ARE DUPLICATES

    if (
      profile.digitalVerifications &&
      profile.digitalVerifications.length) {
    }  {
      if (profile && profile.digitalVerifications && Array.isArray(profile.digitalVerifications)) {
        const verificationTypes = {};
        
        // Iterate through digital verifications
        for (let i = 0; i < profile.digitalVerifications.length; i++) {
          const verification = profile.digitalVerifications[i];
    
          // Check if the verification type already exists
          if (verification.type in verificationTypes) {
            // If the current verification has status "completed", remove it
            if (verification.status === "Completed" || verification.status === "Action required") {
              profile.digitalVerifications.splice(i, 1);
              i--; // Adjust the index after removal
            } else {
              // If the existing verification has status "completed", remove it
              if (verificationTypes[verification.type].status === "Completed" 
                 ||verificationTypes[verification.type].status === "Action required") {
                profile.digitalVerifications.splice(verificationTypes[verification.type].index, 1);
                i--; // Adjust the index after removal
              }
            }
          } else {
            // Store the index and status of the verification
            verificationTypes[verification.type] = {
              index: i,
              status: verification.status,
            };
          }
        }
      }
    }
    // END GO TROUGH DIGITAL VERIFICATIONS , CONTRACT, IDV GO AND REMOVE COMPLETED STEPS IN CASE THERE ARE DUPLICATES

    let pillCounter = 0;
    let activeStepSet = false;
    let profileEvidence = [];
    if (
      profile.isAutomaticRequest &&
      !profile.convertedOn
    ) {
      profileEvidence.push({
        evidenceKey: profile.basicFieldsEvidence?.key,
        currentEvidence: profile.basicFieldsEvidence,
        currentRequestKey: relatedPartyKey? relatedPartyKey : this.idVerificationService.getActiveInvestor(),
        id: "basicInfo",
        isAutomaticPill: false,
        title: this.translate.instant("ARPCS.title6"),
        type: "basicInfo",
        isActive: false,
        rejected: false,
        status: "COMPLETED",
      });
      pillCounter++;
    }


    profileEvidence.push({
      evidenceKey: profile.basicFieldsEvidence?.key,
      currentEvidence: profile.basicFieldsEvidence,
      currentRequestKey: relatedPartyKey? relatedPartyKey : this.idVerificationService.getActiveInvestor(),
      id: "basicInfo",
      isAutomaticPill: false,
      title: profile.typeOfInvestor == "NaturalPerson"
      ? this.translate.instant("evidenceFields.PersonalInformation")
      : this.translate.instant("evidenceFields.CompanyInformation"),
      type: "basicInfo",
      isActive:  profile.basicFieldsEvidence?.reviewState && profile.basicFieldsEvidence?.status.toUpperCase() != 'COMPLETED' ? true : false,
      rejected: false,
      status: profile.basicFieldsEvidence?.status,
    });
    pillCounter++;

    // END BASIC FIELDS
    // DIGITAL VERIFICATIONS
    // wether to show DV step
    // first we save the indentityGo step
    let showDVStep = true;
    if ( profile.digitalVerifications?.length ) {
      let skipableStepCount = 0;
      if (!profile.identityGoStep) {
        profile.identityGoStep =
          profile.digitalVerifications.filter(
            (type) => type.verificationType === "IdentityInstantAI",
          );
        skipableStepCount++;
      }
      if (!profile.contractStep) {
        profile.contractStep =
          profile.digitalVerifications.filter(
            (type) => type.verificationType === "Contract",
          );
        skipableStepCount++;
      }

      if (
        skipableStepCount <
        profile.digitalVerifications.length
      ) {
        profile.digitalVerifications =
          profile.digitalVerifications.filter(
            (type) => type.verificationType !== "IdentityInstantAI",
          );
        profile.digitalVerifications =
          profile.digitalVerifications.filter(
            (type) => type.verificationType !== "Contract",
          );
      }

      if (
        profile.digitalVerifications.length === 1 &&
        profile.digitalVerifications[0].verificationType ===
          "IdentityInstantAI"
      ) {
        showDVStep = false;
      }
    }

    if (
      profile.digitalVerifications &&
      profile.digitalVerifications.length &&
      showDVStep
    ) {
      profile.digitalVerifications =
        profile.digitalVerifications.filter(
          (type) => type.verificationType !== "IdentityInstantAI",
        );
      const totalVerifications =
        profile.digitalVerifications.length;
      let completedVerifications =
        profile.digitalVerifications.length;

      if (
        profile.digitalVerifications &&
        profile.digitalVerifications.length &&
        profile.identityGoStep &&
        profile.identityGoStep[0] &&
        this.reviewStatus === "no-review"
      ) {
        profile.digitalVerifications.push(
          profile.identityGoStep[0],
        );
        profile.digitalVerifications.push(
          profile.identityGoStep[0],
        );
      }

      // check for verificaiton url

      // if there is evidence add the evidence pill
      profileEvidence.push({
        id: "evidenceTypes",
        isAutomaticPill: false,
        displayPill: true,
        title: this.translate.instant("review.pcs.title2"),
        type: "digitalVerifications",
        subTitle: this.translate.instant("review.pcs.subtitle2", {
          total: totalVerifications,
          completed: completedVerifications,
        }),
        isActive: true,
        rejected: false,
        status: "LOADING",
        currentRequestKey: relatedPartyKey? relatedPartyKey : this.idVerificationService.getActiveInvestor(),
      });

      let keysArray =  [];
      for (
        let i = 0;
        i < profile.digitalVerifications.length;
        i++
      ) {
        if (
          profile.digitalVerifications[i]
            .status === "In progress"
        ) {
          completedVerifications = completedVerifications - 1;
          profileEvidence[pillCounter].subTitle =
            this.translate.instant("review.pcs.subtitle2", {
              total: totalVerifications,
              completed: completedVerifications,
            });
        }

        if(profile.digitalVerifications[i].verificationLink) {
          profileEvidence[pillCounter].status = "PENDING"
        }

        if (
          profile.digitalVerifications[i]
            .status === "Failed"
        ) {
          profileEvidence[pillCounter].status = "COMPLETED"
        }
        if (
          profile.digitalVerifications[i]
            .status === "Completed"
        ) {
          profileEvidence[pillCounter].status = "COMPLETED"
        }
        if (
          profile.digitalVerifications[i].status === "Action required"
        ) {
          profileEvidence[pillCounter].status = "COMPLETED"
        }

        keysArray.push(profile.digitalVerifications[i].originalEvidenceKey ? 
          profile.digitalVerifications[i].originalEvidenceKey : 
          profile.digitalVerifications[i].key );
      }

      profileEvidence[pillCounter].keysArray = keysArray;
      // check for verification url
      // check if any digital verification needs to be reviewed
      profile.digitalVerifications.forEach((dv) => {
        if(dv.verificationLink) {
          profileEvidence[pillCounter].verificationLink = dv.verificationLink;
        }
        if (dv.reviewState === false) {
          // this means that at least one DV evidence has been reviewed
          // it has already started and has a "started" progres
          profileEvidence[pillCounter].status = "STARTED";
        }
      });
      if (
        this.isDvReviewState(profile.digitalVerifications)
      ) {
        if (!activeStepSet) {
          profileEvidence[pillCounter].isActive = true;
          activeStepSet = true;
        }
      } else {
        // mark it as completed
        profileEvidence[pillCounter].status = "COMPLETED";
        profileEvidence[pillCounter].isActive = false;
        // check if any DVS are pending
        profile.digitalVerifications.forEach((dv) => {
          if (dv.status === "In progress") {
            profileEvidence[pillCounter].status = "WARNING";
            profileEvidence[pillCounter].isActive = true;
          }
        });
      }
      pillCounter++;
    }
        // // END DIGITAL VERIFICATIONS

        // START IDENTITY GO STEP
        if (
          profile.identityGoStep &&
          profile.identityGoStep.length
        ) {
          // if there is evidence add the evidence pill
          profileEvidence.push({
            keysArray : [profile.identityGoStep[0].key],
            id: "evidenceTypes",
            isAutomaticPill: false,
            title: this.translate.instant("verificationType.Identity"),
            type: "JumioGo",
            isActive: true,
            rejected: false,
            status: "LOADING",
            verificationLink : profile.identityGoStep[0].verificationLink,
            currentRequestKey: relatedPartyKey? relatedPartyKey : this.idVerificationService.getActiveInvestor(),

          });
          if(  profile.identityGoStep[0].verificationLink) {
            profileEvidence[pillCounter].status = "PENDING"

          }
          if (
            profile.identityGoStep[0].status === "Failed"
          ) {
            profileEvidence[pillCounter].status = "COMPLETED"
          }

          if (
            profile.identityGoStep[0].status === "Completed"
          ) {
            profileEvidence[pillCounter].status = "COMPLETED"
          }
          if (
            profile.identityGoStep[0].status === "Action required"
          ) {
            profileEvidence[pillCounter].status = "COMPLETED"
          }

          pillCounter++;
        }
        // END IDENTITY GO STEP
        // START CONTRACT STEP
        if (profile.contractStep?.length) {
          // if there is evidence add the evidence pill
          profileEvidence.push({
            currentRequestKey: relatedPartyKey? relatedPartyKey : this.idVerificationService.getActiveInvestor(),
            id: "evidenceTypes",
            isAutomaticPill: false,
            title: this.translate.instant("review.pcs.fillAndSign"),
            type: "contract",
            isActive: profile.contractStep[0]?.status.toUpperCase() != "COMPLETED"?  true : false,
            rejected: false,
            status: profile.contractStep[0].status 
            ? profile.contractStep[0].status.toUpperCase() == "IN PROGRESS" 
            ? profile.contractStep[0].verificationLink
            ? "IN PROGRESS"
            : "PENDING"  
            : profile.contractStep[0].status
            : "PENDING",
          });
          pillCounter++;
        }
        // END CONTRACT STEP

        // EVIDENCE
        // check if there are evidence types
        if (profile.evidenceTypes?.length) {
          let totalVerifications =
            profile.evidenceTypes.length;
          let completedVerifications = 0;
          // if there is evidence add the evidence pill
          let substeps = [];
          let isCompleted = true;
          let hasAtLeastOneCompleted = false;
          profile.evidenceTypes?.forEach( evidence => {
            if(evidence.status.toUpperCase() == "COMPLETED")
            {
              hasAtLeastOneCompleted = true;
            }
            else if(isCompleted)
            {
              isCompleted = false;
            }
          substeps.push({
          evidenceKey: evidence.key,
          currentEvidence: evidence,
          currentRequestKey: relatedPartyKey? relatedPartyKey : this.idVerificationService.getActiveInvestor(),
          name: evidence.customEvidenceType ? evidence.customEvidenceType : this.helperService.translateEvidenceType(evidence.type),
          type: evidence.type,
          reviewType: 'documents',
          status: evidence.status,
          isActive: evidence.reviewState && evidence.status.toUpperCase() != "COMPLETED"? true : false,
          rejected: false,
          fields:  evidence.reviewState ? this.idVerificationService.parseEvidenceFields(evidence.type) : null
        });
          })

          profileEvidence.push({
            id: "evidenceTypes",
            title: this.translate.instant("review.pcs.title3"),
            type: "documents",
            subTitle: this.translate.instant("review.pcs.subtitle3", {
              total: totalVerifications,
              completed: completedVerifications,
            }),
            isActive:this.isDvReviewState(profile.evidenceTypes) && !isCompleted ? true : false,
            rejected: false,
            substeps: substeps,
            status: isCompleted ? "COMPLETED" : hasAtLeastOneCompleted ? "IN PROGRESS" : "PENDING" ,
            clickAction: "reviewEvidenceTypes",
          });
          if (this.isDvReviewState(profile.evidenceTypes)) {
            if (!activeStepSet) {
              profileEvidence[pillCounter].isActive = true
              activeStepSet = true;
            }
            for (
              let i = 0;
              i < profile.evidenceTypes.length;
              i++
            ) {
              if (
                profile.evidenceTypes[i]
                  .reviewState === false
              ) {
                profileEvidence[pillCounter].status = "STARTED";
                completedVerifications = completedVerifications + 1;
                profileEvidence[pillCounter].subTitle =
                  this.translate.instant("review.pcs.subtitle3", {
                    total: totalVerifications,
                    completed: completedVerifications,
                  });
              }
            }
          } else {
            // mark it as completed
            profileEvidence[pillCounter].status = "COMPLETED";
            totalVerifications =
              profile.evidenceTypes.length;
            completedVerifications =
              profile.evidenceTypes.length;
            profileEvidence[pillCounter].subTitle =
              this.translate.instant("review.pcs.subtitle3", {
                total: totalVerifications,
                completed: completedVerifications,
              });
          }
          pillCounter++;
        }
        // END EVIDENCE

        return profileEvidence;

  }

  generateTheARPCS() {
    this.legalPersonTypeList =
    this.idVerificationService.getLegalPersontypeListCopyAR();
    this.relatedLegalPersonTypeList =
    this.idVerificationService.getLegalPersontypeListCopyAR();
    this.notAllowedLegalTypes = this.activeInvestor.notAllowedLegalTypes;
    this.relatedPartyRoles = [
      ...this.idVerificationService.getRelatedPartyRoles(),
    ];

    this.record =   this.activeInvestor;
    let title = this.translate.instant("ARPCS.title4");
    let message = this.translate.instant("ARPCS.content3-2");
    let imgClass = "d-none";
    if (this.fullyCompleted) {
      title = this.translate.instant("ARPCS.title3");
      message = this.translate.instant("ARPCS.content2");
      imgClass = "";
      this.verificationHeading = this.translate.instant("common.thankYou");
    }
    const res: any = {
      section_class: "results d-flex flex-column justify-items-center",
      group_name: "",
      result_container_class: "result-container my-2",
      is_collapsable: false,
      result_details: [
        {
          elements: [
            {
              type: "image",
              params: {
                src: "/assets/images/request_screening.svg",
                class: imgClass,
              },
            },
            {
              type: "selectEntityType",
              params: {
                record: this.record,
                legalEntityTypes: this.legalPersonTypeList,
                legalEntityRPTypes: this.relatedLegalPersonTypeList,
                legalEntityTypesOptionSelected: "emptySelect",
                relatedPartyRoles: this.relatedPartyRoles,
                relatedPartyRoleSelected: "emptySelect",
                submitMethod: "openConfirmationDialogue",
                openingTitle: title,
                notAllowedLegalTypes: this.notAllowedLegalTypes,
                organizationLogo: this.logo
              },
            },
          ],
        },
      ],
    };
    this.verificationHeading = this.translate.instant("ARPCS.title8");
    this.contentDom[0] = res;
    this.showARPCSScreen = true;
  }

  generateThePCS(rStatus)
  {
    this.navBar = [];

    // if the reviews are finished and the DV initiations are finished and there's a link provided by the BE redirect to that link
    if (
      this.reviewStatus === "no-review" &&
      this.dvStatus === "no-warning"
    ) {
      if (this.activeInvestor.redirectUrl) {
        let redirectUrl = this.activeInvestor.redirectUrl;
        redirectUrl =
          redirectUrl.indexOf("://") === -1
            ? "https://" + redirectUrl
            : redirectUrl;
        window.location.replace(redirectUrl);
      }
    }
    this.navBar.push(
      {
        id: 'mainProfile',
        type: 'mainProfile',
        isActive: true,
        title: this.idVerificationService.generateEntityName(this.activeInvestor.typeOfInvestor, this.activeInvestor.investorFields),
        evidence : this.generateEvidenceForProfile(this.activeInvestor),
        status : "PENDING"
      }
    );

    if(rStatus === 'initial') {
      this.initiateProfileDV();
    }

    // RELATED PARTIES
    if (this.activeInvestor.relatedParties?.length) {

      this.activeInvestor.relatedParties.forEach(relatedPartie => {

        let isActive =this.isRPReviewState(this.activeInvestor.relatedParties)
        let currentRPStatus;
        if(rStatus === 'loaded') {
          currentRPStatus =  isActive ? "PENDING" : "COMPLETED"
        } else {
          currentRPStatus = 'LOADING'
        }
        this.navBar.push({
          id: relatedPartie.key,
          title: this.idVerificationService.generateEntityName(relatedPartie.record.typeOfInvestor, relatedPartie.record.investorFields),
          evidence : this.generateEvidenceForProfile(relatedPartie.record, relatedPartie.key),
          isActive :isActive,
          status : currentRPStatus,
          type: "relatedParties",
          relatedPartyRoles: relatedPartie.record.relatedPartyRoles

        });
      });

      // this.activeInvestor.relatedParties.forEach((rp) => {
      //   // if at least one RP basic fields have been reviwed we will change the status to start
      //   if (
      //     rp.record?.basicFieldsEvidence &&
      //     !rp.record.basicFieldsEvidence.reviewState
      //   ) {
      //     this.navBar[0].status = "STARTED";
      //   }
      // });

      // this.activeInvestor.relatedParties.forEach((rp) => {
      //   // check the DV
      //   if (rp.record.digitalVerifications) {
      //     for (const dv of rp.record.digitalVerifications) {
      //       if (dv.reviewState === false) {
      //         this.navBar[0].status = "WARNING";
      //       }
      //     }
      //   }
      // });
      if(rStatus === 'initial')
      this.loadRelatedPartiesSeparately();
    }
    // pillCounter++;
    // END RELATED PARTIES

    this.showPCSScreen = true;


    // initiate starting evidence
      // this.currentEvidenceKey = this.idVerificationService.getActiveInvestorDetaisl().basicFieldsEvidence.key;
      // this.currentRequestKey = this.idVerificationService.getActiveInvestor();
      // this.currentEvidence = this.idVerificationService.getActiveInvestorDetaisl().basicFieldsEvidence;
      // this.activeEvidence = 'documents';
  }

  isDvReviewState(digitalVerificaitons) {
    let ret = false;
    digitalVerificaitons.forEach((digitalVerification) => {
      if (digitalVerification.reviewState) {
        ret = true;
      }
    });
    return ret;
  }

  markCurrentStepAsCompleted() {
    this.sideBarComponent.markCurrentStepAsCompleted(this.currentRequestKey);
  }

  loadRelatedPartiesSeparately() {
    // this.activeInvestor.relatedParties
    let relatedPartyObservables;
    const arrayOfRps = [];
    const responseRelatedPartiesArray = <Array<any>>(
      this.activeInvestor.relatedParties
    );
    responseRelatedPartiesArray.forEach((rp) => {
      // claimSideRelatedParty
      arrayOfRps.push(
        this.userService.getEndPoint(
          this.idVerificationService.buildEndPoint(
            "claimSideRelatedPartyInvestor",
            this.idVerificationService.getActiveProject(),
            rp.key
          ),
          { responseType: "json" },
          { observe: "body" },
          "funds",
        ),
      );
    });
    relatedPartyObservables = concat(...arrayOfRps).pipe(tap(fullRPData => {

    const newRPEvidence = this.generateEvidenceForProfile(fullRPData['record'], fullRPData['key']);
    this.sideBarComponent.updateRPData(newRPEvidence, fullRPData['key']);
    this.initiateProfileDV(fullRPData['key']);

    }),toArray());
    relatedPartyObservables.subscribe((responseRelatedParties) => {
        responseRelatedParties = responseRelatedParties.map(
          (item) => item,
        );
        this.activeInvestor.relatedParties = responseRelatedParties;
        
        this.generateThePCS('loaded');
    });
  }

  initiateProfileDV(relatedPartyKey?) {
    this.userService.postEndPoint(
      this.idVerificationService.buildEndPoint(
        "initiateDV",
        this.idVerificationService.getActiveProject(),
        relatedPartyKey ? relatedPartyKey   : this.requestKey
      ),
      {},
      { responseType: "json" },
      { observe: "body" },
      "funds",
    ).subscribe( response1 => {
      if(relatedPartyKey) {
        this.userService.postEndPoint(
          this.idVerificationService.buildEndPoint(
            "lazyResolveDigitalVerificationsFunds",
            this.idVerificationService.getActiveProject(),
            relatedPartyKey ? relatedPartyKey   : this.requestKey
          ),
          {},
          { responseType: "json" },
          { observe: "body" },
          "funds",
        ).subscribe( response2 => {
          this.sideBarComponent.updateDVIniateLink(response1, relatedPartyKey);
          
        },error => {
          console.log("ERROR INITIATE RESPONSE");
          // TODO ERROR RESPONSE 
        });
      } else {
        //mmarker335
        this.profileDVLoading = false;
        this.sideBarComponent.updateDVIniateLink(response1, relatedPartyKey);
      }
    },error => {
      this.profileDVLoading = false;
      if(relatedPartyKey) {
        this.userService.postEndPoint(
          this.idVerificationService.buildEndPoint(
            "lazyResolveDigitalVerificationsFunds",
            this.idVerificationService.getActiveProject(),
            relatedPartyKey ? relatedPartyKey   : this.requestKey
          ),
          {},
          { responseType: "json" },
          { observe: "body" },
          "funds",
        ).subscribe( response2 => {
          this.sideBarComponent.updateDVStatus(relatedPartyKey);
        },error => {
          console.log("ERROR INITIATE RESPONSE");
          // TODO ERROR RESPONSE 
          this.sideBarComponent.updateDVStatus(relatedPartyKey);
        });
      } else {

      }

    });
  }

  isRPReviewState(relatedParties) {
    let ret = false;
    relatedParties.forEach((relatedParty) => {
      // check the basic fields review state
      if (relatedParty.record.basicFieldsEvidence) {
        if (relatedParty.record.basicFieldsEvidence.reviewState) {
          ret = true;
        }
      }
      // check the digital verificaitons review state
      if (
        relatedParty.record.digitalVerifications &&
        relatedParty.record.digitalVerifications.length
      ) {
        relatedParty.record.digitalVerifications.forEach(
          (digitalVerification) => {
            if (digitalVerification.reviewState) {
              ret = true;
            }
          },
        );
      }
      // check the evidence review state
      if (
        relatedParty.record.evidenceTypes &&
        relatedParty.record.evidenceTypes.length
      ) {
        relatedParty.record.evidenceTypes.forEach((evidenceType) => {
          if (evidenceType.reviewState) {
            ret = true;
          }
        });
      }
    });
    return ret;
  }

  ngOnDestroy(): void {
    $('body').removeClass('body-bg-white');
    if (this.displayInvestorReviewStatus) {
      this.displayInvestorReviewStatus.unsubscribe();
    }
    if (this.reviewWindowClosed) {
      this.reviewWindowClosed.unsubscribe();
    }
  }

  // reviewBasicFields() {
  //   this.pcsReviewFunction.emit("basicFields");
  // }

  // reviewDigitalVerifications() {
  //   this.pcsReviewFunction.emit("digitalVerifications");
  // }

  // reviewDigitalVerificationsContract() {
  //   this.pcsReviewFunction.emit("digitalVerificationsContract");
  // }

  // reviewDigitalVerificationsIdentityInstantAI() {
  //   this.pcsReviewFunction.emit("digitalVerificationsIdentityGo");
  // }

  // reviewRelatedParties() {
  //   this.pcsReviewFunction.emit("relatedParties");
  // }

  // reviewEvidenceTypes() {
  //   this.pcsReviewFunction.emit("evidenceTypes");
  // }

  // function to parse the data and decide what active pill are we on
  decideActivePill(data) {
    if (data.record.isAutomaticRequest) {
      this.setActivePill(0);
    } else {
      this.setActivePill(1);
    }
  }

  setActivePill(pill) {
    pill["isActive"] = true;
    return pill;
  }

  // setActivePill(index) {
  //   this.activeVFEs[index]["isActive"] = true;
  //   this.activePill = this.activeVFEs[index];
  // }

  /**
   * check if the current pill should be made active
   * @param vfe - details about the vfe we are checking
   * @returns boolean
   */
  checkIsPillActive(pill) {
    if (pill && this.activePill) {
      return pill.id === this.activePill.id;
    }
  }


  /**
   * Generates the content of the Process Control Screen
   * i.e. displays the list of pills and their corresponding statuses
   */
  startBasicinformation() {
    // ERROR by Marija
    this.basicInformationWindow = true;
    let title = this.translate.instant("ARPCS.title4");
    let message = this.translate.instant("ARPCS.content3-2");
    let imgClass = "d-none";
    if (this.fullyCompleted) {
      title = this.translate.instant("ARPCS.title3");
      message = this.translate.instant("ARPCS.content2");
      imgClass = "";
      this.verificationHeading = this.translate.instant("common.thankYou");
    }
    const res: any = {
      section_class: "results d-flex flex-column justify-items-center",
      group_name: "",
      result_container_class: "result-container my-2",
      is_collapsable: false,
      result_details: [
        {
          elements: [
            {
              type: "image",
              params: {
                src: "/assets/images/request_screening.svg",
                class: imgClass,
              },
            },
            {
              type: "selectEntityType",
              params: {
                record: this.record,
                legalEntityTypes: this.legalPersonTypeList,
                legalEntityRPTypes: this.relatedLegalPersonTypeList,
                legalEntityTypesOptionSelected: "emptySelect",
                // Error
                // relatedPartyRoles: this.relatedPartyRoles,
                relatedPartyRoleSelected: "emptySelect",
                submitMethod: "openConfirmationDialogue",
                openingTitle: title,
                notAllowedLegalTypes: this.notAllowedLegalTypes,
              },
            },
          ],
        },
      ],
    };
    this.verificationHeading = this.translate.instant("ARPCS.title8");
    this.contentDom[0] = res;
  }


    /**
   * Generates the content of the Process Control Screen
   * i.e. displays the list of pills and their corresponding statuses
   */

  /**
   * call this method on click of a Content Builder button (not a submit)
   * @param returnedAction event
   */
  parseContentReturn(returnedAction) {
    if (
      typeof returnedAction.method === "string" &&
      returnedAction.method !== ""
    ) {
      // allow only strings as acceptable method name
      let params = "";
      // if (Array.isArray(returnedAction.params)) {
      params = returnedAction.params;
      // }
      try {
        this[returnedAction.method](params); // call it
      } catch (error) {
        console.log(returnedAction.method, "is not declared as method!", error);
      }
    } else {
      console.log("**method name not string or empty string");
    }
  }


  hideGeneralError() {
    $(".button-error-field ").removeClass("d-none");
  }

  startReviewProcess() {
    this.router.navigate([`investor/dashboard/${this.projectkey}/`]);
  }

  openConfirmationDialogue(params) {
    // validation check
    if (!$(".type-of-profile-link.active").length) {
      return;
    }
    this.paramArguments = params;
    this.finishBasicinformation(this.paramArguments);
    this.confirmationLoading = true;
  }

  finishBasicinformation(params) {
    // make the RP array calls first
    const rpArray = params.additionalParam.relatedProfiles;
    this.displayLoader = true;

    if (rpArray !== undefined) {
      let arrayOfRPRequests;
      arrayOfRPRequests = [];
      rpArray.forEach((relatedParty) => {
        // create the payload for each RP
        let payload;
        const rpType = relatedParty.type;
        if (rpType === "natural") {
          payload = {
            typeOfInvestor: "NaturalPerson",
            relatedPartyRole: relatedParty.naturalRole,
            investorFields: {
              FirstName: relatedParty.naturalFirstName,
              LastName: relatedParty.naturalLastName,
            },
          };
          if (relatedParty.isMinor) {
            payload.investorFields.isMinor = true;
          }
        } else {
          payload = {
            typeOfInvestor: "LegalPerson",
            relatedPartyRole: relatedParty.legalRole,
            investorFields: {
              Type: relatedParty.legalType,
              RegisteredName: relatedParty.legalName,
            },
          };
        }
        // smeento od shareKey vo requestKey;
        arrayOfRPRequests.push(
          this.userService.postEndPoint(
            this.idVerificationService.buildEndPoint(
              "pcsRPCreate",
              this.idVerificationService.getActiveProject(),
              this.requestKey,
            ),
            payload,
            { responseType: "json" },
            { observe: "body" },
            "funds",
          ),
        );
      });
      concat(...arrayOfRPRequests)
        .pipe(toArray())
        .subscribe(
          (responses) => {
            // go to the information request call
            this.createIRCall(params);
          },
          (error) => {
            this.confirmationLoading = false;
            this.confirmationError = true;
          },
        );
    } else {
      // if no related parties directly go to the information request call
      this.createIRCall(params);
    }
  }

  createIRCall(params) {

    let payload;
    const irType = params.additionalParam.type;
    const payloadInvestorFields = this.activeInvestor.investorFields;
    if (irType === "natural") {
      payloadInvestorFields.FirstName = params.additionalParam.naturalFirstName;
      payloadInvestorFields.LastName = params.additionalParam.naturalLastName;

      payload = {
        typeOfInvestor: "NaturalPerson",
        investorFields: payloadInvestorFields,
      };
      if (params.additionalParam.isMinor) {
        payload.investorFields.isMinor = true;
      }
    } else {
      payloadInvestorFields.Type = params.additionalParam.legalType;
      payloadInvestorFields.RegisteredName = params.additionalParam.legalName;
      if (payloadInvestorFields.EmailAddress) {
        delete payloadInvestorFields.EmailAddress;
      }
      payload = {
        typeOfInvestor: "LegalPerson",
        investorFields: payloadInvestorFields,
      };
    }
    payload.fieldsSource = [];
    if (
      irType !== "natural" &&
      this.idVerificationService.localFundCreationData &&
      this.idVerificationService.localFundCreationData.fetchedCompanyListDeatils
    ) {
      const irFType = payload.investorFields.Type.slice();
      payload.investorFields =
        this.idVerificationService.localFundCreationData.investorFields;
      payload.investorFields.Type = irFType;
      // adds in the fieldSource from the company search
      payload.fieldsSource = [
        this.idVerificationService.localFundCreationData.fieldsSource,
      ];
    }
    // need to add in the fieldSource from the SP
    if (this.activeInvestor.fieldsSource) {
      // if fieldsSource exists :
      if (!payload.fieldsSource) {
        payload.fieldsSource = this.activeInvestor.fieldsSource;
      }
    }
    this.userService
      .postEndPoint(
        this.idVerificationService.buildEndPoint(
          "pcsIRCreate",
          this.idVerificationService.getActiveProject(),
          this.requestKey,
        ),
        payload,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            this.confirmationLoading = false;
            this.confirmationError = true;
            return;
          }
          this.confirmationLoading = false;
          // this.activeVFEs[0].status = this.translate.instant("common.start");
          // this.setActivePill(1);
          // this.pills = this.generatePills();
          // this.generateContent();
          this.verificationHeading = this.translate.instant("ARPCS.title5");
          const currentRoute = this.router.url;
          this.router
            .navigateByUrl("/verifications", { skipLocationChange: true })
            .then(() => {
              this.router.navigate([currentRoute]); // navigate to same route
            });
        },
        (error) => {
          this.confirmationLoading = false;
          this.confirmationError = true;
        },
      );
  }

  
  splitDuplicateAddressVerifications() {
    if (!$(".type-of-profile-link.active").length) {
      return;
    }
    this.paramArguments = this.activatedRoute;
    this.finishBasicinformation(this.paramArguments);
    this.confirmationLoading = true;
  }

  splitDuplicateContracts() {
    this.basicInformationWindow = true;
    let title = this.translate.instant("ARPCS.title4");
    let message = this.translate.instant("ARPCS.content3-2");
    let imgClass = "d-none";
    if (this.fullyCompleted) {
      title = this.translate.instant("ARPCS.title3");
      message = this.translate.instant("ARPCS.content2");
      imgClass = "";
      this.verificationHeading = this.translate.instant("common.thankYou");
    }
    this.verificationHeading = this.translate.instant("ARPCS.title8");
    // this.contentDom[0] = res;
  }
  
  splitDuplicateVerifications() {
    this.legalPersonTypeList =
    this.idVerificationService.getLegalPersontypeListCopyAR();
    this.relatedLegalPersonTypeList =
    this.idVerificationService.getLegalPersontypeListCopyAR();
    this.notAllowedLegalTypes = this.activeInvestor.notAllowedLegalTypes;
    this.relatedPartyRoles = [
      ...this.idVerificationService.getRelatedPartyRoles(),
    ];

    this.record =   this.activeInvestor;
    let title = this.translate.instant("ARPCS.title4");
    let message = this.translate.instant("ARPCS.content3-2");
    let imgClass = "d-none";
    if (this.fullyCompleted) {
      title = this.translate.instant("ARPCS.title3");
      message = this.translate.instant("ARPCS.content2");
      imgClass = "";
      this.verificationHeading = this.translate.instant("common.thankYou");
    }
    const res: any = {
      section_class: "results d-flex flex-column justify-items-center",
      group_name: "",
      result_container_class: "result-container my-2",
      is_collapsable: false,
      result_details: [
        {
          elements: [
            {
              type: "image",
              params: {
                src: "/assets/images/request_screening.svg",
                class: imgClass,
              },
            },
            {
              type: "selectEntityType",
              params: {
                record: this.record,
                legalEntityTypes: this.legalPersonTypeList,
                legalEntityRPTypes: this.relatedLegalPersonTypeList,
                legalEntityTypesOptionSelected: "emptySelect",
                relatedPartyRoles: this.relatedPartyRoles,
                relatedPartyRoleSelected: "emptySelect",
                submitMethod: "openConfirmationDialogue",
                openingTitle: title,
                notAllowedLegalTypes: this.notAllowedLegalTypes,
                organizationLogo: this.logo
              },
            },
          ],
        },
      ],
    };
    this.verificationHeading = this.translate.instant("ARPCS.title8");
    this.contentDom[0] = res;
    this.showARPCSScreen = true;
  }


  // helper function to parse the BE response into usable arrays
  parseTypes(types) {
    let returnArray;
    returnArray = [];
    // if there's more than one type don't show the legal person that is just the placeholder / default option
    if (types.length > 1) {
      types = types.filter(function (type) {
        return type !== "LegalPerson";
      });
    }
    types.forEach((type) => {
      returnArray.push({
        key: type,
        name: this.helperService.translateLegalPersonType(type),
      });
    });
    returnArray.unshift({
      key: "emptySelect",
      name: "",
    });
    return returnArray;
  }

  changeEvidenceStatus(status) {
    this.currentNavLink.status = status;
    if(this.currentNavLink.status.toUpperCase() == "COMPLETED")
    {
      this.currentNavLink.isActive = false;
    }
    this.sideBarComponent.calculateIcons();
    this.sideBarComponent.goToNextStep();
  }

  changeRightPanel($event)
  {

    if(!$event.displayRightPanel)
    {
      this.displayRightPanel = false
    }
      this.activeEvidence = $event.navLink.type;
      if($event.navLink.reviewType) {
        this.activeEvidence = $event.navLink.reviewType;
      }
      this.currentNavLink = $event.navLink;
      this.currentEvidenceKey = $event.navLink.evidenceKey;
      this.currentRequestKey = $event.navLink.currentRequestKey;
      this.currentEvidence = $event.navLink.currentEvidence;
      this.verificationLink = $event.navLink.verificationLink;

      let _this = this;
      setTimeout(function () {
        _this.displayRightPanel = true;
        _this.sideBarComponent.calculateIcons();
      }, 300);
  }

  changeActiveProfile($event)
  {
    if($event == "mainProfile")
    {
      this.activeProfileNavBar = this.activeInvestor;
      this.requestKey = this.mainProfileRequestKey;
    }
    else{
      this.activeInvestor?.relatedParties?.forEach(RP => {
        if(RP.key == $event)
        {

          this.activeProfileNavBar = RP.record;
          this.requestKey = RP.key
        }
      })

    }
  }
  displayRightPanelChange($event : boolean)
  {
    this.displayRightPanel = $event
  }
}