<div *ngIf="!debugMode">
  <header
    class="d-flex flex-column p-2 p-sm-3 {{
      headerClasses['backgroundClass']
    }} {{ headerClasses['sectionClass'] }}"
    [ngStyle]="{ 'background-color': projectColor }"
    *ngIf="!resolved || !consent"
  >
    <div class="header-top d-flex justify-content-between mx-auto py-3">
      <div class="navbar mx-auto p-0">
        <a style="pointer-events: none" class="navbar-brand mr-0"
          ><img class="lightbox-class-logo-img" *ngIf="logo !== null"
        /></a>
      </div>
    </div>
    <div class="d-flex flex-column flex-fill justify-content-center">
      <div
        class="unauth-message text-center mx-auto"
        [ngClass]="projectTextColorClass"
        *ngIf="message !== ''"
        innerHtml="{{ message }}"
      ></div>
      <div
        class="unauth-message text-center mx-auto"
        [ngClass]="projectTextColorClass"
        *ngIf="
          message === '' &&
          !iosUnsupported &&
          !isUnsupportedBrowser &&
          !androidUnsupported
        "
      >
        <div class="d-flex mx-auto text-center" *ngIf="!resolved">
          <img
            src="/assets/images/spinner-gray.svg"
            width="32px"
            height="32px"
            class="mx-auto"
          />
        </div>
        <div *ngIf="resolved && !consent">
          {{
            this.projectMessage === undefined || this.projectMessage === ""
              ? this.helper.returnDefaultUserVerificationMessage(project)
              : this.projectMessage
          }}
          <div class="d-flex mt-3">
            <button
              class="form-button form-button-clear-bg mx-auto"
              [ngClass]="projectTextColorClass"
              (click)="OnExplicitConsent($event)"
            >
              {{ "IDPCS.button" | translate }}
            </button>
          </div>
          <div class="d-flex mt-3">
            <p
              class="mx-auto privacy-policy-wrapper"
              [innerHTML]="
                'IDPCS.idVerification.content2'
                  | translate
                    : {
                        privacyUrl: privacyUrl
                          ? privacyUrl
                          : 'https://meshid.com/privacy'
                      }
              "
            ></p>
          </div>
        </div>
      </div>
      <div
        class="unauth-message-large text-center mx-auto"
        [ngClass]="projectTextColorClass"
        *ngIf="
          message === '' &&
          iosUnsupported &&
          !isUnsupportedBrowser &&
          !androidUnsupported
        "
      >
        {{ "IDPCS.idVerification.validation" | translate }}
      </div>
      <div
        class="unauth-message-large text-center mx-auto"
        [ngClass]="projectTextColorClass"
        *ngIf="message === '' && isUnsupportedBrowser && !androidUnsupported"
      >
        {{ "IDPCS.idVerification.validation2" | translate }}
      </div>
      <div
        class="unauth-message-large text-center mx-auto"
        [ngClass]="projectTextColorClass"
        *ngIf="message === '' && androidUnsupported"
      >
        {{ "IDPCS.idVerification.validation3" | translate }}
      </div>
    </div>
    <div
      class="load-overlay d-flex position-fixed w-100 h-100"
      *ngIf="displayLoader"
    >
      <img
        src="/assets/images/spinner.svg"
        width="66px"
        height="66px"
        class="my-auto mx-auto"
      />
    </div>
  </header>

  <header
    class="d-flex flex-column p-2 p-sm-3 {{
      headerClasses['backgroundClass']
    }} {{ headerClasses['sectionClass'] }}"
    [ngStyle]="{ 'background-color': projectColor }"
    *ngIf="consent"
  >
    <div class="header-top d-flex justify-content-between mx-auto py-3">
      <div class="navbar mx-auto p-0">
        <a style="pointer-events: none" class="navbar-brand mr-0"
          ><img
            class="lightbox-class-logo-img"
            src="{{ logo }}"
            *ngIf="logo !== null"
        /></a>
      </div>
    </div>
    <div
      class="d-flex flex-column flex-fill justify-content-center"
      *ngIf="message"
    >
      <div
        class="unauth-message text-center mx-auto"
        [ngClass]="projectTextColorClass"
        innerHtml="{{ message }}"
      ></div>
    </div>
    <div
      class="unauth-message-large text-center mx-auto"
      [ngClass]="projectTextColorClass"
      *ngIf="
        message === '' &&
        iosUnsupported &&
        !isUnsupportedBrowser &&
        !androidUnsupported
      "
    >
      {{ "IDPCS.idVerification.validation" | translate }}
    </div>
    <div
      class="unauth-message-large text-center mx-auto"
      [ngClass]="projectTextColorClass"
      *ngIf="message === '' && isUnsupportedBrowser && !androidUnsupported"
    >
      {{ "IDPCS.idVerification.validation2" | translate }}
    </div>
    <div
      class="unauth-message-large text-center mx-auto"
      [ngClass]="projectTextColorClass"
      *ngIf="message === '' && androidUnsupported"
    >
      {{ "IDPCS.idVerification.validation3" | translate }}
    </div>
    <div
      class="iframe-wrapper iframe-verifications d-flex flex-column"
      *ngIf="
        iframeSrc &&
        !iosUnsupported &&
        !isUnsupportedBrowser &&
        !androidUnsupported
      "
    >
      <iframe
        [src]="iframeSrc"
        allow="camera;accelerometer;gyroscope;magnetometer fullscreen"
        allowfullscreen
        class="mx-auto tmpiframe"
      ></iframe>
    </div>
  </header>
</div>

<div *ngIf="debugMode">
  <header
    class="d-flex flex-column p-2 p-sm-3 {{
      headerClasses['backgroundClass']
    }} {{ headerClasses['sectionClass'] }}"
    [ngStyle]="{ 'background-color': projectColor }"
  >
    <div class="header-top d-flex justify-content-between mx-auto py-3">
      <div class="navbar mx-auto p-0">
        <a [routerLink]="['/']" class="navbar-brand mr-0"
          ><img
            class="lightbox-class-logo-img"
            src="{{ logo }}"
            *ngIf="logo !== null"
        /></a>
      </div>
    </div>
    <div class="iframe-wrapper d-flex flex-column">
      <iframe
        [src]="iframeSrc"
        allow="camera;accelerometer;gyroscope;magnetometer; fullscreen"
        allowfullscreen
        class="mx-auto tmpiframe"
      ></iframe>
    </div>
  </header>
</div>
