<div
  [ngClass]="{
    'document-fields-basicFields': element.params.basicFieldsPreview
  }"
  class="document-fields"
>
  <div
    *ngFor="let field of element.params.fieldTypes"
    [ngClass]="{
      'title-field': field.type === 'title',
      'd-none': !(
        field.type === 'title' ||
        (field.type !== 'title' && field.value)
      ),
      'd-none': field.type === 'title' && field.showTitle === false,
      'w-0': field.type !== 'title' && field.value === undefined
    }"
    class="document-field {{ field.titleClass }}"
  >
    <div
      *ngIf="field.type === 'title' || (field.type !== 'title' && field.value)"
      class="document-field-label"
    >
      {{ field.label }}
    </div>
    <div *ngIf="field.value" class="document-field-value">
      {{ field.value }}
    </div>
    <div
      *ngIf="
        element.params.fieldsSource &&
        element.params.fieldsSource.length &&
        element.params.fieldsSource[0].fieldNames.includes(field.name) &&
        element.params.fieldsSource[0].lastUpdate
      "
      class="document-additional-info"
    >
      last updated on
      {{
        helper.getDateFromTimeStampAdditional(
          element.params.fieldsSource[0].lastUpdate
        )
      }}
    </div>
  </div>
</div>
