<div
  class="element-wrapper document-preview-container document-preview-container-alternate mt-0"
  [ngClass]="element.class ? element.class : ''"
>
  <div class="load-container">
    <img
      src="/assets/images/spinner-green.svg"
      width="100px"
      height="100px"
      class="my-auto mx-auto"
    />
  </div>
  <div class="document-preview-stats">
    <div
      *ngIf="element.params.showStats"
      class="heading"
      [ngClass]="element.params.statsClass"
    >
      {{ element.params.statsTitle }}
    </div>
    <div
      *ngIf="idVerificationService.getAPSStatusses()"
      class="preview-verification-statuses flex-wrap"
    >
      <div
        *ngFor="
          let verificationStatus of idVerificationService.getAPSStatusses()
        "
        class="preview-verification-status"
      >
        <div>
          <img
            *ngIf="verificationStatus.src"
            [src]="verificationStatus.src"
            width="16px"
            height="16px"
          />
          <span
            [ngClass]="[
              verificationStatus.title ? 'verification-title' : '',
              verificationStatus.titleClass ? verificationStatus.titleClass : ''
            ]"
            >{{
              verificationStatus.title
                ? verificationStatus.title
                : verificationStatus.subtitle
            }}</span
          >
        </div>
        <div
          class="verification-status-subtitle"
          *ngIf="verificationStatus.title"
        >
          {{ verificationStatus.subtitle }}
        </div>
      </div>
      <div
        *ngIf="element.params.verificationSubtitle"
        class="verification-status-subtitle w-100 mt-3 font-weight-bold"
      >
        {{ element.params.verificationSubtitle }}
      </div>
    </div>
    <div
      class="status"
      [ngClass]="status.class"
      *ngFor="let status of element.params.statuses"
    >
      <div *ngIf="status.status" class="d-flex">
        <img
          *ngIf="status.icon"
          style="width: 17px; height: auto"
          src="{{ status.icon }}"
        />
        <div class="title" *ngIf="status.title">{{ status.title }}</div>
        <div class="details" [innerHTML]="status.status"></div>
      </div>
    </div>
    <div class="document-preview-buttons" *ngIf="!element.params.buttons">
      <a
        *ngIf="
          !element.params.noImages && this.element.params.imageDownloadButton
        "
        [attr.clickMethod]="'downloadPreviewFiles'"
        (click)="onEmit(elementService.OnButtonClick($event))"
      >
        <img class="preview-download-icon" src="/assets/images/download.svg" />
        <span>Download documents</span>
      </a>
      <a
        *ngIf="element.params.pdfDownloadButton"
        [attr.clickMethod]="element.params.pdfDownloadFuction"
        (click)="onEmit(elementService.OnButtonClick($event))"
      >
        <img class="preview-download-icon" src="/assets/images/download.svg" />
        <span>Download PDF Report</span>
      </a>
    </div>
    <div class="document-preview-buttons" *ngIf="element.params.buttons">
      <div *ngFor="let button of element.params.buttons">
        <a
          *ngIf="!button.hideButton"
          [attr.clickMethod]="button.action"
          (click)="onEmit(elementService.OnButtonClick($event))"
        >
          <img [ngClass]="button.class" src="{{ button.icon }}" />
          <span>{{ button.title }}</span>
        </a>
      </div>
    </div>
  </div>
  <div class="document-preview-content d-flex flex-column flex-lg-row">
    <!-- *ngIf="!element.params.noImages" -->
    <div *ngIf="!element.params.apsFields" class="document-preview-left">
      <div
        *ngIf="
          !element.params.noImages &&
          element.params.documentImages &&
          !element.params.basicFieldsPreview
        "
        style="background-color: #d9d9d9"
        id="documentCarousel"
        class="carousel slide document-carousel"
        data-interval="false"
        data-ride="carousel"
      >
        <!-- The slideshow -->
        <div class="carousel-inner">
          <ng-container
            *ngFor="
              let documentImage of element.params.documentImages;
              first as isFirst
            "
          >
            <div
              [ngClass]="
                element.params.startingFilname === documentImage.filename
                  ? 'carousel-item active'
                  : isFirst && element.params.startingFilname === undefined
                  ? 'carousel-item active'
                  : 'carousel-item'
              "
            >
              <div
                *ngIf="documentImage.type == 'image'"
                class="document-slide-container document-slide-container-imgs"
              >
                <img
                  class="downloadble-preview-file"
                  [attr.filename]="documentImage.filename"
                  [src]="documentImage.data | safeUrl"
                  alt=""
                />
              </div>
              <div
                *ngIf="documentImage.type == 'pdf'"
                class="document-slide-container"
              >
                <iframe
                  scrolling="no"
                  class="downloadble-preview-file"
                  [attr.filename]="documentImage.filename"
                  style="z-index: 1000"
                  height="100%"
                  width="100%"
                  [src]="documentImage.data | safeUrl"
                  alt=""
                  allow="camera;accelerometer;gyroscope;magnetometer;fullscreen"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </ng-container>
        </div>
        <!-- Left and right controls -->
        <a
          *ngIf="!element.params.hideArrows"
          class="carousel-control-prev"
          href="#documentCarousel"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon"></span>
        </a>
        <a
          *ngIf="!element.params.hideArrows"
          class="carousel-control-next"
          href="#documentCarousel"
          data-slide="next"
        >
          <span class="carousel-control-next-icon"></span>
        </a>
      </div>
      <div
        class="no-images d-flex align-items-center justify-content-center h-100"
        *ngIf="element.params.noImages"
      >
        <div>No document requested</div>
      </div>
    </div>
    <div
      *ngIf="!element.params.apsFields && !element.params.hideRight"
      class="document-preview-right"
    >
      <app-preview-basic-fields
        [element]="element"
        (contentReturn)="onEmit($event)"
      ></app-preview-basic-fields>
    </div>
    <app-preview-window-aps
      class="w-100"
      *ngIf="element.params.apsFields"
      [apsFields]="element.params.apsFields"
      [isFunds]="false"
      [olderComplianceCheck]="element.params.isMonitored == undefined"
    >
    </app-preview-window-aps>
  </div>
  <div class="expire-lightbox d-none form-container">
    <div class="form-content-container">
      <h1 class="form-type-heading position-relative">
        <div *ngIf="reasonDialogueType === 'expiry'">
          {{ "evidencePreview.button6" | translate }}
        </div>
        <div *ngIf="reasonDialogueType !== 'expiry'">
          {{ "evidencePreview.expiredLabel5" | translate }}
        </div>
        <a (click)="elementService.closeExpireWindow()" class="close-button">
          <img src="assets/images/close_form_icon.png" />
        </a>
      </h1>
      <div class="position-relative dtzc">
        <div class="loading-icon d-none">
          <img
            src="/assets/images/spinner-green.svg"
            width="20px"
            height="20px"
            class="my-auto mx-auto"
          />
        </div>
        <div>
          <span
            *ngIf="reasonDialogueType === 'expiry'"
            class="input-label font-weight-bold"
          >
            {{ "evidencePreview.expiredLabel3" | translate }}
          </span>
          <span
            *ngIf="reasonDialogueType !== 'expiry'"
            class="input-label font-weight-bold"
          >
            {{ "evidencePreview.expiredLabel4" | translate }}
          </span>
          <input
            [attr.isRequired]="
              reasonDialogueType.reason &&
              reasonDialogueType.reason == 'whitelisting'
                ? false
                : true
            "
            [attr.isMaxLength]="500"
            id="actionReason"
            type="text"
            class="text-input"
            (input)="uiService.checkAndClearInvalidation($event)"
            (keydown.enter)="$event.preventDefault()"
            (blur)="elementService.OnInputBlur($event)"
            *ngIf="
              !(
                reasonDialogueType.payload &&
                reasonDialogueType.payload.isWhitelisted
              )
            "
          />

          <div
            class="error-field"
            *ngIf="
              !(
                reasonDialogueType.payload &&
                reasonDialogueType.payload.isWhitelisted
              )
            "
            [attr.validatorMessage]="'*this field is required'"
            [attr.validatorMessageField]="'*this field is required'"
          ></div>

          <app-autocomplete-input
            [id]="'actionReason'"
            [isMaxLength]="500"
            *ngIf="
              reasonDialogueType.reason != undefined &&
              reasonDialogueType.payload != undefined &&
              reasonDialogueType.reason == 'whitelisting' &&
              reasonDialogueType.payload.isWhitelisted
            "
            [options]="commentsOnWhitelistingHit"
          >
          </app-autocomplete-input>
        </div>
        <button
          *ngIf="reasonDialogueType === 'expiry'"
          type="submit"
          class="form-button form-button-1 mt-4"
          (click)="
            elementService.expireDocument(
              $event,
              element.params.evidenceTypes,
              element.params.fieldValues,
              element.params.requestKey,
              element.params.evidenceKey,
              element.params.documentKeys
            )
          "
        >
          {{ "evidencePreview.button6" | translate }}
        </button>
        <button
          *ngIf="reasonDialogueType === 'acceptAPSIDV'"
          type="submit"
          class="form-button form-button-1 mt-4"
          [attr.clickMethod]="'OnConfirmAPSConfirm'"
          (click)="onEmit(elementService.OnButtonClick($event))"
        >
          {{ "evidencePreview.button9" | translate }}
        </button>
        <button
          *ngIf="reasonDialogueType === 'rejectAPSIDV'"
          type="submit"
          class="form-button form-button-1 mt-4"
          [attr.clickMethod]="'onRejectAPSConfirm'"
          (click)="onEmit(elementService.OnButtonClick($event))"
        >
          {{ "evidencePreview.button10" | translate }}
        </button>
        <button
          *ngIf="reasonDialogueType === 'acceptAddressIDV'"
          type="submit"
          class="form-button form-button-1 mt-4"
          [attr.clickMethod]="'OnConfirmAddressConfirm'"
          (click)="onEmit(elementService.OnButtonClick($event))"
        >
          {{ "evidencePreview.button9" | translate }}
        </button>
        <button
          *ngIf="
            reasonDialogueType.reason != undefined &&
            reasonDialogueType.payload != undefined &&
            reasonDialogueType.reason == 'whitelisting'
          "
          type="submit"
          class="form-button form-button-1 mt-4"
          (click)="
            idVerificationService.whitelistHit(
              reasonDialogueType.payload,
              reasonDialogueType.index,
              idVerificationService.getActivePerson()
            )
          "
        >
          {{ "common.continue" | translate }}
        </button>
        <button
          *ngIf="
            reasonDialogueType.reason != undefined &&
            reasonDialogueType.reason == 'commentHit'
          "
          type="submit"
          class="form-button form-button-1 mt-4"
          (click)="
            idVerificationService.commentHit(
              reasonDialogueType.index,
              idVerificationService.getActivePerson()
            )
          "
        >
          {{ "evidencePreview.expiredLabel5" | translate }}
        </button>
        <button
          *ngIf="reasonDialogueType === 'rejectAddressIDV'"
          type="submit"
          class="form-button form-button-1 mt-4"
          [attr.clickMethod]="'onRejectAddressConfirm'"
          (click)="onEmit(elementService.OnButtonClick($event))"
        >
          {{ "evidencePreview.button10" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
