import { BrowserModule, DomSanitizer } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { APP_INITIALIZER, NgModule, Pipe, PipeTransform } from "@angular/core";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { UiModule } from "./ui/ui.module";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { FormsModule } from "@angular/forms";

import { RouterModule } from "@angular/router";
import { IntegrityCheckComponent } from "./integrity-check/integrity-check.component";
import { IntegrityCheckSearchComponent } from "./integrity-check/pages/integrity-check-search/integrity-check-search.component";
import { IntegrityCheckResultComponent } from "./integrity-check/pages/integrity-check-result/integrity-check-result.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { IntegritySearchHistoryComponent } from "./integrity-check/pages/integrity-search-history/integrity-search-history.component";
import { AuthService } from "./auth/auth.service";
import { ROUTES } from "./app.routes";
import { IdVerificationDashboardComponent } from "./id-verification/pages/dashboard/id-verification-dashboard.component";
import { VerificationsComponent } from "./verifications/verifications.component";
import { VerificationsGoComponent } from "./verifications/verifications-go.component";
import { SuccessComponent as VerificationSuccessComponent } from "./verifications/success/success.component";
import { FailedComponent as VerificationFailedComponent } from "./verifications/failed/failed.component";
import { AngularSvgIconModule } from "angular-svg-icon";
import { IdVerificationCheckComponent } from "./id-verification/pages/id-verification-check/id-verification-check.component";
import { ColorPickerModule } from "ngx-color-picker";
import { ContributorInvitationComponent } from "./id-verification/pages/contributor-invitation/contributor-invitation.component";
import { ForbiddenAccessComponent } from "./forbidden-access/forbidden-access.component";
import { TrustsComponent } from "./trusts/trusts.component";
import { TimezoneInterceptor } from "./timezone-interceptor";
import { CallbackComponent } from "./callback/callback.component";
import { ShareRequestComponent } from "./id-verification/pages/share-request/share-request.component";
import { ShareRequestUserComponent } from "./id-verification/pages/share-request/share-request-user/share-request-user.component";
import { DefaultPageComponent } from "./ui/components/default-page/default-page.component";
import { AddressVerificationInvestorComponent } from "./id-verification/pages/address-verification-investor/address-verification-investor.component";
import { VerificationLandingComponent } from "./id-verification/pages/verification-landing/verification-landing.component";
import { ContractVerificationSubjectComponent } from "./id-verification/pages/contract-verification-subject/contract-verification-subject.component";
import { FormioAppConfig, FormioModule } from "@formio/angular";
import { DocumenTypetList } from "./id-verification/id-verification-const";
import { BsModalService } from "ngx-bootstrap/modal";
import { CddProcessControlScreenComponent } from "./id-verification/pages/share-request/cdd-process-control-screen/cdd-process-control-screen.component";
import { BasicInformationVerificationSubjectComponent } from "./id-verification/pages/basic-information-verification-subject/basic-information-verification-subject.component";
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AppConfig } from "./formioConfig";
import { WarningComponent } from "./verifications/warning/warning.component";
import { FullscrenLoadingOverlayComponent } from "./ui/content-builder/content-elements/components/fullscren-loading-overlay/fullscren-loading-overlay.component";
import { SuccessComponentGo } from "./verifications/success/success-go.component";
import { ContractVerificationOnboardSubjectComponent } from "./id-verification/pages/contract-verification-onboard-subject/contract-verification-onboard-subject.component";
import { ContractShareDialogComponent } from './id-verification/pages/share-request/new-cdd-process-control-screen/contract-share-dialog/contract-share-dialog.component';
import { CddProcessControlScreenModule } from "./id-verification/cdd-process-control-screen.module";
import { NewCddProcessControlScreenComponent } from "./id-verification/pages/share-request/new-cdd-process-control-screen/new-cdd-process-control-screen.component";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@Pipe({ name: "safeUrl" })
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustResourceUrl(value);
  }
}

@Pipe({ name: "orgLinkFilter" })
export class orgLinkFilter implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(link) {
    let result;
    const startingUrl = "http://";
    const httpsStartingUrl = "https://";
    if (link.startsWith(startingUrl) || link.startsWith(httpsStartingUrl)) {
      result = link;
    } else {
      result = httpsStartingUrl + link;
    }
    return result;
  }
}

export function appInitializerFactory(translate: TranslateService) {
  return () => {
    translate.setDefaultLang("en");
    return translate.use("en").toPromise();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    IntegrityCheckComponent,
    IntegrityCheckSearchComponent,
    IntegrityCheckResultComponent,
    IntegritySearchHistoryComponent,
    IdVerificationDashboardComponent,
    VerificationsComponent,
    VerificationsGoComponent,
    VerificationSuccessComponent,
    SuccessComponentGo,
    VerificationFailedComponent,
    IdVerificationCheckComponent,
    ContributorInvitationComponent,
    ForbiddenAccessComponent,
    TrustsComponent,
    CallbackComponent,
    ShareRequestComponent,
    ShareRequestUserComponent,
    DefaultPageComponent,
    SafeUrlPipe,
    AddressVerificationInvestorComponent,
    VerificationLandingComponent,
    orgLinkFilter,
    ContractVerificationSubjectComponent,
    CddProcessControlScreenComponent,
    BasicInformationVerificationSubjectComponent,
    WarningComponent,
    FullscrenLoadingOverlayComponent,
    ContractVerificationOnboardSubjectComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    // import HttpClientModule after BrowserModule.
    HttpClientModule,
    AppRoutingModule,
    UiModule,
    RouterModule.forRoot(ROUTES, { onSameUrlNavigation: "reload" }),
    BsDatepickerModule.forRoot(),
    NgbModule,
    AngularSvgIconModule,
    ColorPickerModule,
    FormioModule,
    CddProcessControlScreenModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TimezoneInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true,
    },
    // { provide: FormioAppConfig, useValue: AppConfig},
    AuthService,
    DocumenTypetList,
    BsModalService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}