import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { error } from 'console';
import { HelperServiceService } from 'src/app/helper-service.service';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
import { IpServiceService } from 'src/app/ip-service.service';
import { ElementsService } from 'src/app/ui/content-builder/content-elements/components/elements.service';
import { UiService } from 'src/app/ui/ui.service';
import { UserService } from 'src/app/user.service';

@Component({
  selector: 'app-document-share-dialog',
  templateUrl: './document-share-dialog.component.html',
  styleUrls: ['./document-share-dialog.component.scss']
})
export class DocumentShareDialogComponent implements OnInit {


  @ViewChild("gautocomplete") addresstextElement: ElementRef;
  @ViewChild("mapWrapper") mapElement: ElementRef;

  @Input() fields;
  @Input() currentEvidenceKey;
  @Input() currentEvidence;
  @Input() currentRequestKey;
  @Input() title?: string;
  @Input() profileDVLoading;
  @Input() fieldsLocked?: string[] = [];

  @Output() changeEvidenceStatus: EventEmitter<any> = new EventEmitter();

  documents : any[];
  image_upload_id: string = 'evidence_image_id';
  uploadNotes: string;
  image:any;
  isMobile;
  isBasicFields;
  map: google.maps.Map;
  autocomplete: any;
  addressImageURL: string;
  displayEvidence = false;
  evdEndpoint;
  loadingScreen = false;

  public type = "";

  private observerHack = new MutationObserver(() => {
    this.observerHack.disconnect();
    this.addresstextElement.nativeElement.setAttribute(
      "autocomplete",
      "chrome-off",
    );
  });

  constructor(
    public elementService: ElementsService,
    public helperService: HelperServiceService,
    public translate: TranslateService,
    public idVerificationService: IdVerificationService,
    public userService: UserService,
    public uiService: UiService,
    public ipService: IpServiceService,
    private ref: ChangeDetectorRef,

  ) {
   }

   ngOnInit(): void {

    this.loadingScreen = true;
    $(".right-pannel-wrapper").addClass('window-loading');

    this.isMobile = this.helperService.isMobile();
    if(this.currentEvidence.type === 'BASIC_REQUEST_INFO') {
      this.isBasicFields = true;
    }
    this.evdEndpoint =   "api/projects/" +
    this.idVerificationService.getActiveProject() +
    "/information/requests/" +
    this.currentRequestKey +
    "/evidence/" +
    this.currentEvidenceKey;
    this.retreiveEvidenceData();

    this.currentEvidence.key = this.currentEvidenceKey;
  }


   OnImageUploadDragEnter($event)
   {

   }

   OnImageUploadDragOver($event)
   {

   }

   OnImageUploadDragLeave($event) {

   }


   OnDocumentUploadClick($event) {
    $event.preventDefault();
    $event.stopPropagation();
    $("input[data-InputEvidenceKey = " + '"' + this.currentEvidenceKey + '"' + "]").trigger(
      "click",
    );
   }

   OnImageUploadDropEvidence($event) {

   }

   OnImageUploadSelectedEvidence(event) {
    console.log('clicke2');
    event.preventDefault();
    $(event.target).css("background", "transparent");
    const files = event.target.files;
    const match = ["image/jpeg", "image/png", "image/gif", "application/pdf"];
    for (let i = 0; i < files.length; i++) {
      if (
        !(
          files[i].type === match[0] ||
          files[i].type === match[1] ||
          files[i].type === match[2] ||
          files[i].type === match[3]
        )
      ) {
        alert(this.translate.instant("errors.error12"));
        return;
      }

      // get existing files
      const existingFilesNumber = $(".evidence-list-delete-button").length;
      if (existingFilesNumber + files.length >= 6) {
        alert(this.translate.instant("errors.error13"));
        return;
      }

      if (files[i].size > 26214400 && files[i].type === "application/pdf") {
        alert(this.translate.instant("errors.error14"));
        return;
      }
    }
    let eventTarget = $(event.target);
    if (!eventTarget.attr("uploadmethod")) {
      if (!eventTarget.hasClass("dropzone")) {
        eventTarget = $(event.target).closest(".dropzone");
      }
    }
    $(".error-field-required-image").addClass("d-none");
    $(".dropzone").removeClass("error-required");
    console.log(files);
    if(files[0])
    this.uploadRequestDocument( files[0]);
   }

   uploadRequestDocument(
    file: File) {

    $(".dropzone").addClass("dropzone-loading");
    $(".evidence-submit-button").addClass("loading");
    $(".evidence-delete-button").addClass("d-none");

    const fd = new FormData();
    fd.append("document", file, file.name);
    this.userService
      .postEndPoint(
        "api/projects/" +
        this.idVerificationService.getActiveProject() +
        "/information/requests/" +
        this.currentRequestKey +
        "/evidence/" +
        this.currentEvidenceKey +
        "/documents",
        fd,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            return;
          }
          // const evidenceField = this.returnEvidenceFieldByKey(
          //   evidenceKey,
          //   evidenceTypes,
          // );
          // this.saveFieldsState(evidenceKey, evidenceTypes);

          // DONT NEED TO RERENDER FLOW
          // this.loadEvidenceFieldAndImages(this.getActiveProject(),requestKey,evidenceKey,flowtype,evidenceTypes);

          this.currentEvidence["documents"].push({
            filename: file.name,
            Key: response["documentKey"],
            key: response["documentKey"],
          });

          $(".dropzone").removeClass("dropzone-loading");
          $(".evidence-submit-button").removeClass("loading");
          $(".evidence-delete-button").removeClass("d-none");
          $(".submit-fields-general-error").hide();
        },
        (error) => {
          console.log(
            "The following error occured when uploading the document",
            error,
          );
          alert(this.translate.instant("errors.error8"));
        },
      );
    return undefined;
  }

  OnDocumentDelete($event, document) {
    $event.preventDefault();
    $event.stopPropagation();

    const imageContainer = $(
      '.evidence-list-delete-button[data-documentKey="' + document['key'] + '"]',
    ).parent();
    imageContainer.addClass("button-deleting");
    $(".dropzone").addClass("dropzone-loading");
    $(".evidence-submit-button").addClass("loading");
    $(".evidence-delete-button").addClass("d-none");
    this.userService
      .deleteEndpoint(
        "api/projects/"+
        this.idVerificationService.getActiveProject() +
        "/information/requests/" +
         this.currentRequestKey +
         "/evidence/"+
         this.currentEvidenceKey +
         "/documents/" +
         document['key'],
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
          }
          // imageContainer.remove();
          imageContainer.removeClass("button-deleting");
          $(".dropzone").removeClass("dropzone-loading");
          $(".evidence-submit-button").removeClass("loading");
          $(".evidence-delete-button").removeClass("d-none");
          // remove document from state
          // const evidenceType = this.returnEvidenceFieldByKey(
          //   evidenceKey,
          //   evidenceTypes,
          // );
          // const documentIndex = this.returnDocumentPositionByKey(
          //   documentKey,
          //   evidenceType["documents"],
          // );
          // evidenceType["documents"].splice(documentIndex, 1);

          const documentIndex = this.idVerificationService.returnDocumentPositionByKey(
            document['key'],
            this.currentEvidence["documents"],
          );
          this.currentEvidence["documents"].splice(documentIndex, 1);
        },
        (error) => {
          console.log(
            "The following error occured when deleting the documnet",
            error,
          );
          // this.createPerson.next({  'status': false,
          //                           'personData': person,
          //                           'error': error });
          imageContainer.removeClass("button-deleting");
        },
      );
  }

  isFieldChecked(field: string = "", fieldHide: string = "", id = "") {
    if (fieldHide) {
      return !(
        $(`#NaturalPerson${fieldHide}`).prop("checked") ||
        $(`#LegalPerson${fieldHide}`).prop("checked")
      );
    }
    return (
      $(`#NaturalPerson${field}`).prop("checked") ||
      $(`#LegalPerson${field}`).prop("checked")
    );
  }

  OnReviewButtonClick(
    event
  ) {
    let skipCheck;
    $(".submit-fields-general-error").hide();
      if (
        $(event.target).attr("type") &&
        $(event.target).attr("type") === "submit"
      ) {
        // CHECK WITH VALIDATOR
        if (!this.uiService.validateLightboxFields(event)) {
          console.log("**not all fields are valid");
          event.preventDefault();
          // CHECK IF AT LEAST ONE DOCUMENT IS UPLOADED
          // WE ARE ADDING IT AGAIN
          if (
            !$(".evidence-list-image-container").length &&
            !skipCheck
          ) {
            event.preventDefault();
            $(".error-field-required-image").removeClass("d-none");
            $(".dropzone").addClass("error-required");
          }
          $(".submit-fields-general-error").show();
          return;
        }
        // CHECK IF AT LEAST ONE DOCUMENT IS UPLOADED
        if (
          !$(".evidence-list-image-container").length &&
          !skipCheck && !this.isBasicFields
        ) {
          event.preventDefault();
          $(".error-field-required-image").removeClass("d-none");
          $(".dropzone").addClass("error-required");
          console.log("**no image");
          $(".submit-fields-general-error").show();
          return;
        }
      }
    let eventTarget = event.target;
    if (
      eventTarget.nodeName !== "A" &&
      eventTarget.nodeName !== "BUTTON" &&
      eventTarget.nodeName !== "DIV"
      // eventTarget.nodeName !== "SPAN"
    ) {
      eventTarget = $(eventTarget).closest("a"); // for now only support fallback to anchor elements to cover the svg scenario
      if (eventTarget.length > 0) {
        eventTarget = eventTarget[0];
      }
    }
    event.preventDefault();
    const method = eventTarget.getAttribute("clickmethod");
    $(".review-flow-add-spinner").removeClass("d-none");
    const payload = this.idVerificationService.saveFieldsState(
      this.currentEvidenceKey,
      [this.currentEvidence],
      undefined,
      undefined,
      undefined,
      undefined,
      this.currentRequestKey,
    );
    this.loadingScreen = true;
    $(".right-pannel-wrapper").addClass('window-loading');


    this.userService
    .postEndPoint(
      this.evdEndpoint,
      payload,
      { responseType: "json" },
      { observe: "body" },
      "funds",
    )
    .subscribe(() => {
      this.userService
      .postEndPoint(
        this.evdEndpoint + "/correct",
        payload,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(() => {
        this.loadingScreen = false;
        $(".right-pannel-wrapper").removeClass('window-loading');
        this.changeEvidenceStatus.emit('COMPLETED');
        });
    },error => {
      this.loadingScreen = false;
      $(".right-pannel-wrapper").removeClass('window-loading');
      this.changeEvidenceStatus.emit('REJECTED');
    });

  }

  onEmit(emitPayload) {
    if (emitPayload) {
      console.log(emitPayload);
    }
  }

  OnInitGoogleAutocomplete(e) {
    this.getPlaceAutocomplete();
  }

  //mmarker

  private getPlaceAutocomplete() {
    if (!this.addresstextElement || this.autocomplete) {
      console.log(
        "no address text element or autocomplete already initialized! returning ...",
      );
      return;
    }
    this.addresstextElement.nativeElement.style.borderColor = "#00889c";
    // make sure google maps disable autocomplete for street-address
    this.observerHack.observe(this.addresstextElement.nativeElement, {
      attributes: true,
      attributeFilter: ["autocomplete"],
    });
    // $('.text-input.google-autocomplete').attr('autocomplete', 'chrome-off');
    this.autocomplete = new google.maps.places.Autocomplete(
      this.addresstextElement.nativeElement,
      {
        // componentRestrictions: { country: 'US' },
        types: ["address"], // 'establishment' / 'address' / 'geocode'
        fields: ["address_components", "geometry"],
      },
    );
    const _this = this;
    google.maps.event.addListener(
      this.autocomplete,
      "place_changed",
      function () {
        const place = _this.autocomplete.getPlace();
        _this.invokeEvent(place);
      },
    );
  }

  invokeEvent(place: any) {
    // if auto to manual, try and populate the manual fields:
    const naturalAddressFields = {
      NaturalPersonAddressStreet: "getStreet",
      NaturalPersonAddressNumber: "getStreetNumber",
      NaturalPersonAddressCity: "getCity",
      NaturalPersonAddressPostalCode: "getPostCode",
      NaturalPersonAddressCountry: "getCountry",
      NaturalPersonAddressCountryShort: "getCountryShort",
    };
    const legalAddressFields = {
      LegalPersonRegisteredAddressStreet: "getStreet",
      LegalPersonRegisteredAddressNumber: "getStreetNumber",
      LegalPersonRegisteredAddressCity: "getCity",
      LegalPersonRegisteredAddressPostalCode: "getPostCode",
      LegalPersonRegisteredAddressCountry: "getCountry",
      LegalPersonRegisteredAddressCountryShort: "getCountryShort",
    };

    if (this.currentEvidence.type ==='LegalPerson') {
      for (const [key, value] of Object.entries(legalAddressFields)) {
        if (this.ipService[value](place)) {
          if (key === "NaturalPersonAddressCountry" || key === "LegalPersonRegisteredAddressCountry") {
            let locPlace = this.ipService[value](place).replace(/ /g, "_");

            $(`[id="${key}"]`).val(locPlace);
            // if we cannot find the long name try the short name
            // this happened when using different localizations of chrome
            if (!$(`[id="${key}"]`).val()) {
              const name_short = this.ipService["getCountryShort"](place);
              locPlace = this.idVerificationService
                .getCountryListAV()
                .find((country) => country.value === name_short);
              if (locPlace) {
                locPlace = locPlace.label.toString().replace(/ /g, "_");
                $(`[id="${key}"]`).val(locPlace);
              }
            }
          } else {
            $(`[id="${key}"]`).val(this.ipService[value](place));
          }
          this.uiService.clearInvalidationTarget($(`[id="${key}"]`));
        } else {
          if (!$(`[name="useClassicAddress"]`).prop("checked")) {
            $(`[name="useClassicAddress"]`).trigger("click");
          }
          this.uiService.validateInput($(`[id="${key}"]`));
          console.log(key);
        }
      }
    } else {
      for (const [key, value] of Object.entries(naturalAddressFields)) {
        if (this.ipService[value](place)) {
          if (key === "NaturalPersonAddressCountry" || key === "LegalPersonRegisteredAddressCountry") {
            let locPlace = this.ipService[value](place).replace(/ /g, "_");
            console.log($(`[id="${key}"]`));
            $(`[id="${key}"]`).val(locPlace);
            if (!$(`[id="${key}"]`).val()) {
              const name_short = this.ipService["getCountryShort"](place);
              locPlace = this.idVerificationService
                .getCountryListAV()
                .find((country) => country.value === name_short);
              if (locPlace) {
                locPlace = locPlace.label.toString().replace(/ /g, "_");
                $(`[id="${key}"]`).val(locPlace);
              }
            }
          } else {
            $(`[id="${key}"]`).val(this.ipService[value](place));
          }
          this.uiService.clearInvalidationTarget($(`[id="${key}"]`));
        } else {
          if (!$(`[name="useClassicAddress"]`).prop("checked")) {
            $(`[name="useClassicAddress"]`).trigger("click");
          }
          this.uiService.validateInput($(`[id="${key}"]`));
        }
      }
    }

    const streetNumber = this.ipService.getStreetNumber(place)
      ? `, ${this.ipService.getStreetNumber(place)}`
      : "";
    // this.getControl('street').patchValue(`${this.getStreet(place)}${streetNumber}`, {onlySelf: true} );
    // this.getControl('town').patchValue(this.getCity(place), {onlySelf: true});
    // this.addresstextElement.nativeElement.value = `${this.ipService.getStreet(place)}${streetNumber}`;
    $(".checkboxUseClassicAddress").removeClass("d-none");
    $("#AddressInputField").val(
      `${this.ipService.getStreet(place)}${streetNumber}`,
    );
    $("#CountryInputField").val(this.ipService.getCountryShort(place));
    this.uiService.clearInvalidationTarget($("#CountryInputField"));
    $("#CityInputField").val(this.ipService.getCity(place));
    this.uiService.clearInvalidationTarget($("#CityInputField"));
    const zip = this.ipService.getPostCode(place);
    if (zip || zip === "") {
      $("#ZipInputField").val(zip);
      this.uiService.clearInvalidationTarget($("#ZipInputField"));
    }
    if ($("#LocationInputField").length > 0) {
      $("#LocationInputField").val(
        this.ipService.getAddrLocation(place, "string") as string,
      );
    }
    this.initializeMap(
      place.geometry.location.lat(),
      place.geometry.location.lng(),
    );
    this.ref.detectChanges();
  }

  initializeMap(lat, long) {
    console.log("initializing map with lat", lat, "long", long);
    this.addressImageURL =
      "https://maps.google.com/maps?q=,139.664123&hl=en&z=13&amp;output=embed";
    const lngLat = new google.maps.LatLng(lat, long);

    const mapOptions = {
      zoom: 13,
      center: lngLat,
      scrollwheel: false,
    };


    setTimeout(() => {
      this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
      const marker = new google.maps.Marker({
        position: lngLat,
      });
      marker.setMap(this.map);
      this.ref.detectChanges();
    }, 500);
  }


  parseDateContentReturn(event) {
    //
    this.uiService.clearInvalidation(
      $("#" + $.escapeSelector(event.params.model)).find("input"),
    );
  }

  retreiveEvidenceData() {
      // retreive document details
      // this is a default case it will get replaced later by calling the same function for the correct type
      this.fields = this.idVerificationService.parseEvidenceFields(
        'LegalPerson',
        [],
        false,
        false,
        false,
        false,
        false
      );
      this.userService
      .getEndPoint(
        this.evdEndpoint,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe((response) => {
        this.displayEvidence = true;
        this.currentEvidence = response['record'];
        this.currentEvidence.key = this.currentEvidenceKey;
        if(this.currentEvidence.type === 'BASIC_REQUEST_INFO') {
          if(this.currentEvidence.fields.FirstName) {
            this.currentEvidence.type = 'NaturalPerson';
          } else {
            this.currentEvidence.type = 'LegalPerson';

          }
        }


        let showAddressFields = false;

        if(this.currentEvidence.fields) {
          if(this.currentEvidence.fields["RegisteredAddressCountry"] ||
            this.currentEvidence.fields["AddressCountry"]
          ) {
            showAddressFields = true;
          }
        }
        this.fields = this.idVerificationService.parseEvidenceFields(
          this.currentEvidence.type,
          this.currentEvidence.fields,
          false,
          false,
          false,
          false,
          showAddressFields,
          true
        );
        this.currentEvidence.purposesOfUse =
        this.idVerificationService.parsePurposeOfUse(this.currentEvidence.access?.purposesOfUse)
        // account for map fields
        this.loadingScreen = false;
        $(".right-pannel-wrapper").removeClass('window-loading');

      });

  }

  selectValueIsValid(value, options) {
    if (!value || value === "") {
      return false;
    }
    value = value.trim();
    if (options && options.length) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].key === value) {
          return true;
        }
      }
    }
    return false;
  }

  textInputValueisValid(value) {
    if (!value) {
      return false;
    }
    value = value.trim();
    if (value === "") {
      return false;
    }
    return true;
  }
  change()
{
}
parseDate(value) {
  return value;
}

}
