<div id="mySidenav" class="sidenav d-block navbar navbar-expand-md">
  <button id="collapseBtn" class="navbar-toggler form-button form-button-6 " type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" (click)="ChangeRightPanelStatus()">
    <div class="navbar-toggler-icon">
      {{ "review.pcs.seeAllTask"  | translate }}
      <img class="right-arrow" src="/assets/images/arrow-left.svg" />
    </div>
  </button>
  <!-- <div class="collapseBtn" (click)="openCloseSidebar()">&times;</div> -->
  <div class="collapse navbar-collapse navbar-brand-wrapper" id="navbarSupportedContent">
  <div>
    <div class="d-flex justify-content-center">
    <div class="navbar-brand pl-3">
     <img  [src]="logo" class="finos-logo-img">
    </div>
  </div>
  <div class="progress-wrapper">
    <div class="progress">
      <div class="progress-bar progress-bar-striped bg-info font-weight-bold" role="progressbar" [style.width]="percentageOfCompletedEvidence + '%'" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">{{percentageOfCompletedEvidence == 100 ? ("review.pcs.allTaskCompleted"  | translate ): percentageOfCompletedEvidence + '%'}}</div>
    </div>
  </div>
  <ul class="timeline">
    <li class="nav-item" [ngClass]="link.class "
      *ngFor="let link of navLinks; index as linkIndex">
      <div *ngIf="link.type == 'title'" class="entity-name">
        {{link.title}}
        <span class="role" *ngFor="let role of link.relatedPartyRoles">{{ helper.parseKYCTemplateCreationName(role)}}</span>
      </div>
      <div class="nav-item-wrapper single-step" *ngIf=" link.type != 'title' && !link?.substeps"
        (click)="generateLink($event, link, link.type, linkIndex)">
        <div class="circle">
          <div class="img"></div>
        </div>

        <div class="title clickable">{{ link.title }}
          <span class="role" *ngFor="let role of link.relatedPartyRoles">{{ helper.parseKYCTemplateCreationName(role)}}</span>
        </div>
      </div>
      <div class="nav-item-wrapper link-with-substeps" *ngIf="link?.substeps?.length > 0">
        <div 
        class="dropdown"
        (click)="setSubstepsActive($event, linkIndex)"
        data-toggle="collapse" 
        [attr.data-target]="'#subSteps' + linkIndex" 
        aria-expanded="false"
        aria-controls="subSteps">
        <div class="circle">
          <div class="img"></div>
        </div>
          <div class="title" >{{link.title}}</div>
        </div>
        <div>
          <ul [id]="'subSteps' + linkIndex" class="collapse subSteps pb-0" [ngbCollapse]="isCollapsed" >
            <li class="pl-3 dropdown-step pb-0" [ngClass]="step.class "
              *ngFor="let step of link.substeps; index as substepIndex"
              (click)="generateLink($event, step, link.type, linkIndex, substepIndex)">
              <div class="circle">
                <div class="img"></div>
              </div>
              <div class="title clickable">{{step.name}}</div>
            </li>
          </ul>
        </div>
      </div>
    </li>
  </ul>
  </div>
</div>

</div>