<div
  *ngIf="showPCSScreen"
  class="d-flex flex-column flex-fill justify-content-center align-items-center mb-5 vlanding-container cdd-pcs-container"
  [ngClass]="{ 'review-flow': isReview }"
  style="margin-bottom: 0 !important; overflow: auto"
  [style.background-color]="backgroundColor ? backgroundColor : 'initial'"
>
  <div
    style="z-index: 2; overflow: auto; width: 100vw; margin-top: 10px"
    class="header-top d-flex justify-content-center justify-content-lg-between mx-auto py-3 flex-column"
  >
    <div
      class="organizational-details"
      *ngIf="errorState || waitingState"
      style="margin-top: 0px"
    >
      <h1 [ngClass]="projectTextColorClass" class="mb-4">
        {{ "common.pleaseWait" | translate }}
      </h1>
      <div>
        <div class="od-main-container">
          <img
            src="/assets/images/request_screening.svg"
            *ngIf="errorState"
            class="od-image"
          />
          <img
            src="/assets/images/spinner-green.svg"
            *ngIf="waitingState"
            class="od-image"
          />
          <h4 class="od-subtitle text-center" [innerHtml]="stateMessage"></h4>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <div class="mx-auto navbar p-0 py-3" style="margin-top: 100px">
      <a class="navbar-brand mx-auto">
        <img *ngIf="logo" [src]="logo | safeUrl" class="organization-logo" />
      </a>
    </div>
    <div
      *ngIf="!errorState && !waitingState && consentParsed"
      class="unauth-message text-center mx-auto pcs-unauth mt-0"
      style="color: initial"
    >
      <h1 style="color: initial" class="mb-2 mb-lg-3 mb-xl-4">
        {{ verificationHeading }}
      </h1>
      <div
        class="contentWrapper position-relative"
        [ngClass]="{ 'basic-information-window': basicInformationWindow }"
      >
        <app-content-builder
          [contentDOM]="contentDom"
          (contentReturn)="parseContentReturn($event)"
        ></app-content-builder>
        <app-language-switcher
          *ngIf="showLanguageOption"
        ></app-language-switcher>
        <div *ngIf="confirmationLoading" class="pcs-loading-screen">
          <img class="loading-spinner" src="/assets/images/spinner-green.svg" />
          <h3 class="dialogue-subtitle">
            {{ "common.pleaseWait" | translate }}...
          </h3>
          <p class="result-paragraph">{{ "ARPCS.content9" | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
<app-fullscren-loading-overlay *ngIf="displayLoader">
</app-fullscren-loading-overlay>
