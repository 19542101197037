<div class="position-relative">
  <h1 class="template-title d-flex align-items-center pb-3 mb-4">
    <div class="configuring mr-3">Configuring:</div>
    <div class="template-icon mr-3">
      <img
        [src]="
          requestType === 'NaturalPerson' ||
          requestType === 'NaturalPersonMinor'
            ? '/assets/images/ico-natural-small.svg'
            : '/assets/images/ico-legal-small.svg'
        "
      />
    </div>
    <div class="request-main-type mr-3">
      {{ mainType === "mainRequest" ? "Main profile - " : "Related party -" }}
    </div>
    <div class="request-type">
      {{
        helperService.parseKYCTemplateCreationName(element.params.templateType)
      }}
    </div>
  </h1>
  <div class="type-of-documents">
    <h1 class="template-subtitle mb-1">Type of verifications</h1>
    <div
      class="digital-verifications-checkbox-container align-items-center flex-wrap d-flex"
    >
      <div
        class="element-wrapper mt-3"
        *ngIf="
          requestType === 'NaturalPerson' ||
          requestType === 'NaturalPersonMinor'
        "
      >
        <input
          class="custom-checkbox custom-dv-checkbox"
          type="checkbox"
          id="IdentityVerification"
          name="IdentityVerification"
          value="IdentityVerification"
          [checked]="existingIDType"
          (click)="selectDVCheckbox($event)"
        />
        <label class="custom-checkbox-label" for="IdentityVerification"
          >Identity verification</label
        >
      </div>
      <div
        *ngIf="
          requestType === 'NaturalPerson' ||
          requestType === 'NaturalPersonMinor'
        "
        class="element-wrapper mt-3"
      >
        <input
          class="custom-checkbox custom-dv-checkbox"
          type="checkbox"
          id="AddressVerification"
          name="AddressVerification"
          value="AddressVerification"
          [checked]="existingAddressType"
          (click)="selectDVCheckbox($event)"
        />
        <label class="custom-checkbox-label" for="AddressVerification"
          >Address verification</label
        >
      </div>
      <div class="element-wrapper mt-3">
        <input
          class="custom-checkbox custom-dv-checkbox"
          type="checkbox"
          id="ContractVerification"
          name="ContractVerification"
          value="ContractVerification"
          [checked]="existingContractType"
          (click)="selectDVCheckbox($event)"
        />
        <label class="custom-checkbox-label" for="ContractVerification"
          >Contract verification</label
        >
      </div>
    </div>
    <div
      [ngClass]="{ 'd-none': !showDVTab }"
      class="tab-container digital-verifications-tab"
    >
      <div
        *ngIf="
          requestType === 'NaturalPerson' ||
          requestType === 'NaturalPersonMinor'
        "
        class="d-flex tab  unselectable tab-disabled {{
          currentlySelectedDVTab === 'IdentityVerification' ? 'active' : ''
        }} "
        id="IdentityVerificationTab"
        (click)="selectDVTab($event)"
      >
        Identity verification
      </div>
      <div
        *ngIf="
          requestType === 'NaturalPerson' ||
          requestType === 'NaturalPersonMinor'
        "
        class="d-flex tab unselectable tab-disabled {{
          currentlySelectedDVTab === 'AddressVerification' ? 'active' : ''
        }}"
        id="AddressVerificationTab"
        (click)="selectDVTab($event)"
      >
        Address verification
      </div>
      <div
        class="d-flex tab unselectable tab-disabled {{
          currentlySelectedDVTab === 'ContractVerification' ? 'active' : ''
        }}"
        id="ContractVerificationTab"
        (click)="selectDVTab($event)"
      >
        Contract
      </div>
    </div>
    <div [hidden]="!showDVTab" class="digital-verificaitons-tab-content">
      <div
        *ngIf="
          requestType === 'NaturalPerson' ||
          requestType === 'NaturalPersonMinor'
        "
        class="dv-tab-content"
        [ngClass]="{
          'd-flex': currentlySelectedDVTab === 'IdentityVerification'
        }"
        id="IdentityVerificationContent"
      >
        <div class="input-fields-wrapper input-fields-wrapper-dv">
          <!-- INPUT FIELDS -->
          <div
            *ngIf="element.params.showCountriesAndTypes"
            class="evidence-fields type-country-wrapper"
          >
            <div class="element-wrapper input-transparent">
              <span class="input-label font-weight-bold"
                >Accept documents from</span
              >
              <span class="select-wrapper">
                <select
                  class="select-input"
                  [disabled]="element.investorSide"
                  id="IDVerificationCountry"
                  name="IDVerificationCountry"
                  (change)="onEmit(elementService.OnChangeSelect($event))"
                  [attr.changemethod]="'onChangeTypeCountry'"
                  [attr.selectedCountry]="
                    existingIDType && existingIDType.verificationConfig.country
                  "
                  [attr.isRequired]="'false'"
                  [value]="'emptySelect'"
                >
                  <option
                    value="{{ option.countryCode }}"
                    [selected]="
                      existingIDType &&
                      existingIDType.verificationConfig.country ==
                        option.countryCode
                        ? true
                        : false
                    "
                    *ngFor="let option of element.params.countryList"
                  >
                    {{ option.countryName }}
                  </option>
                </select>
              </span>
              <div
                class="error-field"
                [attr.validatorMessage]="element.params.validatorMessage"
                [attr.validatorMessageField]="element.params.validatorMessage"
              ></div>
            </div>
          </div>
          <div class="evidence-fields verification-type-wrapper-stand-in"></div>
          <div
            *ngIf="element.params.showCountriesAndTypes"
            class="evidence-fields verification-type-wrapper d-none"
          >
            <div class="element-wrapper input-transparent">
              <span class="input-label font-weight-bold"
                >Accept documents of type</span
              >
              <span class="select-wrapper">
                <select
                  class="select-input"
                  id="IDVerificationType"
                  name="IDVerificationType"
                  [attr.isRequired]="'false'"
                  [value]="'emptySelect'"
                >
                  <option
                    [selected]="
                      verificationPresets &&
                      verificationPresets.type == option.key
                        ? true
                        : false
                    "
                    value="{{ option.key }}"
                    *ngFor="let option of element.params.idTypeList"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </span>
              <div
                class="error-field"
                [attr.validatorMessage]="element.params.validatorMessage"
                [attr.validatorMessageField]="element.params.validatorMessage"
              ></div>
            </div>
          </div>
          <div class="evidence-fields">
            <div class="element-wrapper input-transparent">
              <span class="input-label font-weight-bold">Not older than</span>
              <span class="select-wrapper">
                <select
                  class="select-input"
                  [disabled]="element.investorSide"
                  id="idVNotOlderThan"
                  name="Not older than"
                  [attr.isRequired]="'false'"
                  (change)="OnChangeNotOlderThan($event)"
                >
                  <!-- if value is included in the array of default values, that is the current value if not it is custom -->
                  <option
                    value="{{ option.key }}"
                    [selected]="isSelectedOption(option.key, 'idV')"
                    *ngFor="let option of templatenotOlderThan"
                  >
                    {{ option.name }}
                  </option>
                </select>
                <div
                  class="notOlderThan-text-input"
                  [ngClass]="
                    existingIDType &&
                    !checkIfValueIsInTemplate(existingIDType.notOlderThanM)
                      ? 'd-block'
                      : 'd-none'
                  "
                >
                  <span
                    style="margin-top: 13px"
                    class="input-label d-flex font-weight-bold"
                    >Custom number of months
                  </span>
                  <input
                    class="text-input"
                    id="idVNotOlderThanCustom"
                    type="number"
                    min="1"
                    [value]="
                      existingIDType && existingIDType.notOlderThanM
                        ? existingIDType.notOlderThanM
                        : 5
                    "
                  />
                </div>
              </span>
              <div
                class="error-field"
                [attr.validatorMessage]="element.params.validatorMessage"
                [attr.validatorMessageField]="element.params.validatorMessage"
              ></div>
            </div>
          </div>
          <div class="evidence-fields">
            <div class="element-wrapper input-transparent">
              <span class="input-label font-weight-bold"
                >Capture methods allowed</span
              >
              <span class="select-wrapper">
                <select
                  class="select-input"
                  [disabled]="element.investorSide"
                  id="WorkflowID"
                  name="WorkflowID"
                  [attr.isRequired]="'false'"
                  [value]="'201'"
                >
                  <option
                    [selected]="
                      existingIDType &&
                      existingIDType.verificationConfig.workflowId == option.key
                        ? true
                        : false
                    "
                    value="{{ option.key }}"
                    *ngFor="let option of element.params.workflowList"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </span>
              <div
                class="error-field"
                [attr.validatorMessage]="element.params.validatorMessage"
                [attr.validatorMessageField]="element.params.validatorMessage"
              ></div>
            </div>
            <div class="element-wrapper mt-2 input-transparent">
              <input
                class="custom-checkbox"
                type="checkbox"
                id="idVerificationAlternate"
                name="idVerificationAlternateN"
                value="id alternate"
                [checked]="
                  existingIDType &&
                  (existingIDType.verificationConfig.workflowId === 100 ||
                    existingIDType.verificationConfig.workflowId === 101 ||
                    existingIDType.verificationConfig.workflowId === 102)
                "
              />
              <label
                class="custom-checkbox-label"
                for="idVerificationAlternate"
              >
                Verify document only</label
              >
            </div>
          </div>
          <div class="evidence-fields">
            <div class="element-wrapper input-transparent">
              <span class="input-label font-weight-bold">Language</span>
              <span class="select-wrapper">
                <select
                  class="select-input"
                  [disabled]="element.investorSide"
                  id="Locale"
                  name="Locale"
                  [attr.isRequired]="'false'"
                  [(ngModel)]="
                    existingIDType && existingIDType.verificationConfig.locale
                      ? existingIDType.verificationConfig.locale
                      : element.params.defaultLocale
                  "
                >
                  <option
                    value="{{ option.key }}"
                    *ngFor="let option of element.params.localeList"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </span>
              <div
                class="error-field"
                [attr.validatorMessage]="element.params.validatorMessage"
                [attr.validatorMessageField]="element.params.validatorMessage"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          requestType === 'NaturalPerson' ||
          requestType === 'NaturalPersonMinor'
        "
        id="AddressVerificationContent"
        class="dv-tab-content"
        [ngClass]="{
          'd-flex': currentlySelectedDVTab === 'AddressVerification'
        }"
      >
        <div class="input-fields-wrapper input-fields-wrapper-av">
          <div *ngIf="element.params.allowiDIN" class="evidence-fields">
            <div class="element-wrapper">
              <div class="input-label-large mb-0">
                <!--ALTERNATE  SUBTITLE USED IN NEW FORMS-->
                <h4>iDIN verification (NL only)</h4>
              </div>
            </div>
            <div *ngIf="element.params.allowiDIN" class="evidence-fields">
              <div class="element-wrapper mt-2">
                <input
                  class="custom-checkbox"
                  type="checkbox"
                  id="addressVerificationiDN"
                  name="addressVerificationiDN"
                  value="IDn verification"
                  (click)="onEmit(elementService.OnContentElementClick($event))"
                  (change)="OnChangeSelect($event)"
                  [checked]="
                    existingAddressType &&
                    existingAddressType.verificationConfig.idinAllowed
                  "
                  [attr.changeMethod]="element.params.changemethod"
                />
                <label
                  style="color: #333333"
                  class="custom-checkbox-label"
                  for="addressVerificationiDN"
                >
                  Allow the use of iDIN verification (NL only)</label
                >
              </div>
            </div>
          </div>
          <div class="evidence-fields">
            <div class="element-wrapper">
              <div class="input-label-large">
                <!--ALTERNATE  SUBTITLE USED IN NEW FORMS-->
                <h4>Enhanced verification</h4>
              </div>
            </div>
          </div>
          <div class="evidence-fields">
            <div class="element-wrapper mt-2">
              <input
                class="custom-checkbox"
                type="checkbox"
                id="addressVerificaitonLetter"
                name="addressVerificaitonLetter"
                value="Include verification letter"
                [attr.changeMethod]="element.params.changemethod"
                [checked]="
                  existingAddressType &&
                  existingAddressType.verificationConfig
                    .verificationLetterRequested
                "
              />
              <label
                style="color: #333333"
                class="custom-checkbox-label"
                for="addressVerificaitonLetter"
              >
                Send a verification letter by physical mail to address</label
              >
            </div>
          </div>
          <div class="evidence-fields">
            <div class="element-wrapper">
              <div class="input-label-large">
                <!--ALTERNATE  SUBTITLE USED IN NEW FORMS-->
                <h4>Supporting evidence</h4>
              </div>
            </div>
          </div>
          <div class="evidence-fields">
            <div class="element-wrapper mt-2">
              <input
                class="custom-checkbox"
                type="checkbox"
                id="addressSupportingDocument"
                name="addressSupportingDocument"
                value="Include supporting document"
                (click)="onEmit(elementService.OnContentElementClick($event))"
                (change)="addressVerificationDocumentChange()"
                [checked]="
                  existingAddressType &&
                  existingAddressType.verificationConfig.documentsRequested
                "
                [attr.changeMethod]="'addressVerificationDocumentChange'"
              />
              <label
                style="color: #333333"
                class="custom-checkbox-label"
                for="addressSupportingDocument"
              >
                Request additional documents</label
              >
            </div>
          </div>
          <div class="evidence-fields">
            <div
              class="element-wrapper address-counter-group counter-group checkbox-borderless mt-3 d-none"
            >
              <div
                style="font-size: 14px; color: #767676"
                class="custom-checkbox-label pl-2 pb-2"
              >
                Select which of the below documents will be accepted forms of
                evidence
              </div>
              <!-- Checkbox Group -->
              <div class="counter-container checkbox-container">
                <div
                  *ngFor="let checkbox of existingAdddressDocuments"
                  class="single-checkbox-wrapper"
                >
                  <div class="custom-counter counter-alternate">
                    <div
                      [ngClass]="checkbox.counter ? 'd-none' : 'd-flex'"
                      class="counter-passive"
                    >
                      <button
                        (click)="elementService.increaseCountEmpty($event)"
                        class="button-plus-empty"
                      >
                        <span>+</span>
                      </button>
                    </div>
                    <div
                      [ngClass]="checkbox.counter ? 'd-flex' : 'd-none'"
                      class="counter-active"
                    >
                      <button
                        (click)="
                          elementService.increaseCountMinus(
                            $event,
                            undefined ? undefined : 0
                          )
                        "
                        class="button-minus"
                      >
                        <span>-</span>
                      </button>
                      <input
                        id="{{ checkbox.type }}"
                        [attr.baseValue]="
                          checkbox.counter ? checkbox.counter : 0
                        "
                        type="text"
                        readonly="readonly"
                        class="custom-counter-count d-none"
                        value="{{ checkbox.counter ? checkbox.counter : 0 }}"
                      />
                      <button
                        (click)="elementService.increaseCountPlus($event)"
                        class="button-plus d-none"
                      >
                        <span>+</span>
                      </button>
                    </div>
                    <div class="custom-counter-label">
                      {{ helperService.parseBackendName(checkbox.type) }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="address-documents-error-field error-field"
                [attr.validatorMessage]="element.params.validatorMessage"
                [attr.validatorMessageField]="element.params.validatorMessage"
              ></div>
            </div>
          </div>
          <div class="evidence-fields">
            <div
              style="padding-left: 5px"
              class="address-counter-group counter-group d-none custom-address-template-documents"
            >
              <div
                class="counter-container custom-evidence-counter-container checkbox-container"
              >
                <div
                  class="single-checkbox-wrapper"
                  *ngFor="let checkbox of element.params.checkboxes"
                >
                  <div class="custom-counter">
                    <div
                      c
                      [ngClass]="checkbox.params.counter ? 'd-none' : 'd-flex'"
                      class="counter-passive pr-2"
                    >
                      <button
                        (click)="
                          elementService.increaseCountEmpty(
                            $event,
                            true,
                            '.custom-address-template-documents '
                          )
                        "
                        class="button-plus-empty"
                      >
                        <span>+</span>
                      </button>
                    </div>
                    <div
                      [ngClass]="checkbox.params.counter ? 'd-flex' : 'd-none'"
                      class="counter-active counter-active-letters"
                    >
                      <button
                        (click)="
                          elementService.increaseCountMinus(
                            $event,
                            checkbox.params.counter
                              ? checkbox.params.counter
                              : 0,
                            true,
                            '.custom-address-template-documents '
                          )
                        "
                        class="button-minus button-inactive"
                      >
                        <span>-</span>
                      </button>
                      <input
                        id="{{ checkbox.params.id }}"
                        [attr.baseValue]="
                          checkbox.params.counter ? checkbox.params.counter : 0
                        "
                        type="text"
                        readonly="readonly"
                        class="custom-counter-count invisible"
                        value="0"
                      />
                      <input
                        *ngFor="
                          let evidenceKey of checkbox.params.dataEvidenceKeys
                        "
                        [attr.data-Oftype]="checkbox.params.id"
                        readonly="readonly"
                        class="hidden-counter-field"
                        value="{{ evidenceKey }}"
                        type="hidden"
                      />
                      <button
                        style="margin-bottom: -12px"
                        (click)="
                          elementService.increaseCountPlus(
                            $event,
                            true,
                            '.custom-address-template-documents '
                          )
                        "
                        class="custom-counter-add-more"
                      >
                        Add more
                      </button>
                    </div>
                    <div class="custom-counter-label pl-0">
                      Custom documents
                    </div>
                  </div>
                  <div
                    *ngIf="checkbox.params.existingCustomTypes.length"
                    class="custom-evidence-types-input-wrapper"
                  >
                    <div
                      [ngClass]="
                        existingCustomType.customEvidenceType
                          ? 'evd-disabled'
                          : ''
                      "
                      class="mt-2 custom-evidence-types-container"
                      *ngFor="
                        let existingCustomType of checkbox.params
                          .existingCustomTypes;
                        let i = index
                      "
                    >
                      <!-- <span class="input-label"> Custom evidence type </span> -->
                      <input
                        [value]="existingCustomType.customEvidenceType"
                        [disabled]="existingCustomType.customEvidenceType"
                        type="text"
                        class="text-input"
                        [id]="'customEvidenceType' + i + 1"
                      />
                      <div class="custom-evidence-delete-button d-none"></div>
                    </div>
                  </div>
                  <div
                    *ngIf="!checkbox.params.existingCustomTypes.length"
                    class="custom-evidence-types-input-wrapper ml-0 mt-1 d-none"
                  >
                    <div class="custom-evidence-types-container mb-3">
                      <!-- <span class="input-label"> Custom evidence type </span> -->
                      <input
                        type="text"
                        class="text-input"
                        [id]="'customEvidenceType' + '1'"
                      />
                      <div class="custom-evidence-delete-button"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="element-wrapper mt-1">
                <!-- Textarea (autogrow) -->
                <span class="input-label">
                  Additional document notes for the subject
                </span>
                <textarea
                  maxlength="400"
                  autosize
                  class="text-input-div"
                  id="avNotes"
                  name="avNotes"
                  (focus)="onEmit(elementService.OnInputFocus($event))"
                  (blur)="onEmit(elementService.OnInputBlur($event))"
                  >{{
                    existingAddressType &&
                      existingAddressType.verificationConfig.documentNotes &&
                      existingAddressType.verificationConfig.documentNotes.trim()
                  }}</textarea
                >
                <div
                  class="error-field"
                  [attr.validatorMessage]="element.params.validatorMessage"
                  [attr.validatorMessageField]="element.params.validatorMessage"
                ></div>
              </div>
            </div>
          </div>
          <div class="evidence-fields">
            <div class="element-wrapper input-transparent">
              <span class="input-label font-weight-bold">Not older than</span>
              <span class="select-wrapper">
                <select
                  class="select-input"
                  [disabled]="element.investorSide"
                  id="AVNotOlderThan"
                  name="Not older than"
                  [attr.isRequired]="'false'"
                  (change)="OnChangeNotOlderThan($event)"
                >
                  <!-- if value is included in the array of default values, that is the current value if not it is custom -->
                  <option
                    value="{{ option.key }}"
                    [selected]="isSelectedOption(option.key, 'AV')"
                    *ngFor="let option of templatenotOlderThan"
                  >
                    {{ option.name }}
                  </option>
                </select>
                <div
                  class="notOlderThan-text-input"
                  [ngClass]="
                    existingAddressType &&
                    !checkIfValueIsInTemplate(existingAddressType.notOlderThanM)
                      ? 'd-block'
                      : 'd-none'
                  "
                >
                  <span
                    style="margin-top: 13px"
                    class="input-label d-flex font-weight-bold"
                    >Custom number of months
                  </span>
                  <input
                    class="text-input"
                    id="AVNotOlderThanCustom"
                    type="number"
                    min="1"
                    [value]="
                      existingAddressType && existingAddressType.notOlderThanM
                        ? existingAddressType.notOlderThanM
                        : 5
                    "
                  />
                </div>
              </span>
              <div
                class="error-field"
                [attr.validatorMessage]="element.params.validatorMessage"
                [attr.validatorMessageField]="element.params.validatorMessage"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="ContractVerificationContent"
        class="dv-tab-content"
        [ngClass]="{
          'd-flex': currentlySelectedDVTab === 'ContractVerification'
        }"
      >
        <div class="input-fields-wrapper input-fields-wrapper-dv">
          <!-- INPUT FIELDS -->
          <div class="evidence-fields">
            <div class="element-wrapper input-transparent">
              <span class="input-label font-weight-bold"
                >Contract templates</span
              >
              <span class="select-wrapper">
                <select
                  class="select-input"
                  [disabled]="element.investorSide"
                  id="ContractTemplate"
                  name="ContractTemplate"
                  [attr.isRequired]="'false'"
                  [value]="element.params.template"
                >
                  <option
                    [selected]="
                      existingContractType &&
                      existingContractType.verificationConfig.template ==
                        option.key
                        ? true
                        : false
                    "
                    value="{{ option.key }}"
                    *ngFor="let option of element.params.contractTemplates"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </span>
              <div
                class="error-field"
                [attr.validatorMessage]="element.params.validatorMessage"
                [attr.validatorMessageField]="element.params.validatorMessage"
              ></div>
            </div>
          </div>
          <!-- <div class="evidence-fields">
                <div class="input-label font-weight-bold mt-4">
                  Document used for:
                </div>
                <div class="purpose-tag-wrapper d-flex">
                  <div class="purpose-tag px-3 mt-1">
                    Proof of identity
                  </div>
                </div>
              </div> -->
        </div>
      </div>
    </div>
    <h1 class="template-subtitle border-top mt-4 mb-3 pt-4">
      Type of documents
    </h1>
    <div
      class="counter-container main-evidence-counter-container d-flex flex-wrap"
    >
      <div class="counter-container-sub-wrapper">
        <div
          *ngFor="let checkbox of evidenceTypesFirst"
          class="document-checkbox single-checkbox-wrapper flex-column align-items-start pb-0"
        >
          <div
            [ngClass]="[
              checkbox.params.oneDocumentDisabled ? ' counter-disabled' : '',
              checkbox.params.class ? checkbox.params.class : ''
            ]"
            class="custom-counter counter-alternate"
          >
            <div
              [ngClass]="checkbox.params.counter ? 'd-none' : 'd-flex'"
              class="counter-passive"
            >
              <button
                (click)="
                  elementService.increaseCountEmpty($event);
                  toggleEvdConfig($event)
                "
                class="button-plus-empty"
              >
                <span>+</span>
              </button>
            </div>
            <div
              [ngClass]="checkbox.params.counter ? 'd-flex' : 'd-none'"
              class="counter-active"
            >
              <button
                (click)="
                  elementService.increaseCountMinus(
                    $event,
                    checkbox.params.counter ? checkbox.params.counter : 0
                  );
                  toggleEvdConfig($event)
                "
                class="button-minus"
              >
                <span>-</span>
              </button>
              <input
                id="{{ checkbox.params.id }}"
                [attr.baseValue]="
                  checkbox.params.counter ? checkbox.params.counter : 0
                "
                type="text"
                readonly="readonly"
                class="custom-counter-count"
                value="{{
                  checkbox.params.counter ? checkbox.params.counter : 0
                }}"
                [ngClass]="checkbox.params.oneDocument ? 'd-none' : ''"
                [attr.verificationDisabled]="
                  checkbox.params.oneDocumentDisabled ? 'true' : ''
                "
              />
              <input
                *ngFor="let evidenceKey of checkbox.params.dataEvidenceKeys"
                [attr.data-Oftype]="checkbox.params.id"
                readonly="readonly"
                class="hidden-counter-field"
                value="{{ evidenceKey }}"
                type="hidden"
              />
              <button
                (click)="elementService.increaseCountPlus($event)"
                [ngClass]="checkbox.params.oneDocument ? 'd-none' : ''"
                class="button-plus"
              >
                <span>+</span>
              </button>
            </div>
            <div
              (click)="
                checkbox.params.oneDocument ? counterLabelClick($event) : ''
              "
              class="custom-counter-label unselectable"
            >
              {{ checkbox.params.label }}
            </div>
          </div>
          <div
            [ngClass]="{ 'd-none': !checkbox.params.counter }"
            class="evidence-config-lightbox-button"
          >
            <a (click)="configureEvidence($event)">Edit settings</a>
          </div>
        </div>
      </div>
      <div class="counter-container-sub-wrapper">
        <div
          *ngFor="let checkbox of evidenceTypesSecond"
          class="document-checkbox single-checkbox-wrapper flex-column align-items-start pb-0"
        >
          <div
            [ngClass]="[
              checkbox.params.oneDocumentDisabled ? ' counter-disabled' : '',
              checkbox.params.class ? checkbox.params.class : ''
            ]"
            class="custom-counter counter-alternate"
          >
            <div
              [ngClass]="checkbox.params.counter ? 'd-none' : 'd-flex'"
              class="counter-passive"
            >
              <button
                (click)="
                  elementService.increaseCountEmpty($event);
                  toggleEvdConfig($event)
                "
                class="button-plus-empty"
              >
                <span>+</span>
              </button>
            </div>
            <div
              [ngClass]="checkbox.params.counter ? 'd-flex' : 'd-none'"
              class="counter-active"
            >
              <button
                (click)="
                  elementService.increaseCountMinus(
                    $event,
                    checkbox.params.counter ? checkbox.params.counter : 0
                  );
                  toggleEvdConfig($event)
                "
                class="button-minus"
              >
                <span>-</span>
              </button>
              <input
                id="{{ checkbox.params.id }}"
                [attr.baseValue]="
                  checkbox.params.counter ? checkbox.params.counter : 0
                "
                type="text"
                readonly="readonly"
                class="custom-counter-count"
                value="{{
                  checkbox.params.counter ? checkbox.params.counter : 0
                }}"
                [ngClass]="checkbox.params.oneDocument ? 'd-none' : ''"
                [attr.verificationDisabled]="
                  checkbox.params.oneDocumentDisabled ? 'true' : ''
                "
              />
              <input
                *ngFor="let evidenceKey of checkbox.params.dataEvidenceKeys"
                [attr.data-Oftype]="checkbox.params.id"
                readonly="readonly"
                class="hidden-counter-field"
                value="{{ evidenceKey }}"
                type="hidden"
              />
              <button
                (click)="elementService.increaseCountPlus($event)"
                [ngClass]="checkbox.params.oneDocument ? 'd-none' : ''"
                class="button-plus"
              >
                <span>+</span>
              </button>
            </div>
            <div
              (click)="
                checkbox.params.oneDocument ? counterLabelClick($event) : ''
              "
              class="custom-counter-label unselectable"
            >
              {{ checkbox.params.label }}
            </div>
          </div>
          <div
            [ngClass]="{ 'd-none': !checkbox.params.counter }"
            class="evidence-config-lightbox-button"
          >
            <a (click)="configureEvidence($event)">Edit settings</a>
          </div>
        </div>
      </div>
      <div class="counter-container-sub-wrapper">
        <div
          *ngFor="let checkbox of evidenceTypesThird"
          class="document-checkbox single-checkbox-wrapper flex-column align-items-start pb-0"
        >
          <div
            [ngClass]="[
              checkbox.params.oneDocumentDisabled ? ' counter-disabled' : '',
              checkbox.params.class ? checkbox.params.class : ''
            ]"
            class="custom-counter counter-alternate"
          >
            <div
              [ngClass]="checkbox.params.counter ? 'd-none' : 'd-flex'"
              class="counter-passive"
            >
              <button
                (click)="
                  elementService.increaseCountEmpty($event);
                  toggleEvdConfig($event)
                "
                class="button-plus-empty"
              >
                <span>+</span>
              </button>
            </div>
            <div
              [ngClass]="checkbox.params.counter ? 'd-flex' : 'd-none'"
              class="counter-active"
            >
              <button
                (click)="
                  elementService.increaseCountMinus(
                    $event,
                    checkbox.params.counter ? checkbox.params.counter : 0
                  );
                  toggleEvdConfig($event)
                "
                class="button-minus"
              >
                <span>-</span>
              </button>
              <input
                id="{{ checkbox.params.id }}"
                [attr.baseValue]="
                  checkbox.params.counter ? checkbox.params.counter : 0
                "
                type="text"
                readonly="readonly"
                class="custom-counter-count"
                value="{{
                  checkbox.params.counter ? checkbox.params.counter : 0
                }}"
                [ngClass]="checkbox.params.oneDocument ? 'd-none' : ''"
                [attr.verificationDisabled]="
                  checkbox.params.oneDocumentDisabled ? 'true' : ''
                "
              />
              <input
                *ngFor="let evidenceKey of checkbox.params.dataEvidenceKeys"
                [attr.data-Oftype]="checkbox.params.id"
                readonly="readonly"
                class="hidden-counter-field"
                value="{{ evidenceKey }}"
                type="hidden"
              />
              <button
                (click)="elementService.increaseCountPlus($event)"
                [ngClass]="checkbox.params.oneDocument ? 'd-none' : ''"
                class="button-plus"
              >
                <span>+</span>
              </button>
            </div>
            <div
              (click)="
                checkbox.params.oneDocument ? counterLabelClick($event) : ''
              "
              class="custom-counter-label unselectable"
            >
              {{ checkbox.params.label }}
            </div>
          </div>
          <div
            [ngClass]="{ 'd-none': !checkbox.params.counter }"
            class="evidence-config-lightbox-button"
          >
            <a (click)="configureEvidence($event)">Edit settings</a>
          </div>
        </div>
      </div>
    </div>
    <!-- ADD CUSTOM EVIDENCE DOCUMENTS -->
    <div class="evidence-fields custom-evidence-template-wrapper">
      <div
        style="padding-left: 5px"
        class="counter-group custom-evidence-counter-container-template"
      >
        <div
          class="counter-container custom-evidence-counter-container checkbox-container"
        >
          <div
            class="single-checkbox-wrapper"
            *ngFor="let checkbox of element.params.checkboxes"
          >
            <div class="custom-counter">
              <div
                [ngClass]="customEvidenceTypes.length ? 'd-none' : 'd-flex'"
                class="counter-passive pr-2"
              >
                <button
                  (click)="
                    increaseCountEmptyCustom(
                      $event,
                      true,
                      'custom-evidence-template-wrapper '
                    );
                    addCustomEvidenceTemplate($event)
                  "
                  class="button-plus-empty"
                >
                  <span>+</span>
                </button>
              </div>
              <div
                [ngClass]="customEvidenceTypes.length ? 'd-flex' : 'd-none'"
                style="bottom: -8px !important"
                class="counter-active counter-active-letters"
              >
                <button
                  (click)="
                    deleteCustomEvidenceTemplate($event);
                    increaseCountMinusCustom(
                      $event,
                      checkbox.params.counter ? checkbox.params.counter : 0,
                      true,
                      'custom-evidence-template-wrapper '
                    )
                  "
                  class="button-minus button-inactive"
                >
                  <span>-</span>
                </button>
                <input
                  id="{{ checkbox.params.id }}"
                  [attr.baseValue]="customEvidenceTypes.length"
                  type="text"
                  readonly="readonly"
                  class="custom-counter-count invisible"
                  value="{{ customEvidenceTypes.length }}"
                />
                <input
                  *ngFor="let evidenceKey of checkbox.params.dataEvidenceKeys"
                  [attr.data-Oftype]="checkbox.params.id"
                  readonly="readonly"
                  class="hidden-counter-field"
                  value="{{ evidenceKey }}"
                  type="hidden"
                />
                <button
                  (click)="
                    increaseCountPlusCustom(
                      $event,
                      true,
                      'custom-evidence-template-wrapper '
                    );
                    addCustomEvidenceTemplate($event)
                  "
                  class="custom-counter-add-more"
                >
                  Add more
                </button>
                <button class="d-none" (click)="openCustomConfig()"></button>
              </div>
              <div class="custom-counter-label pl-0">
                {{ checkbox.params.label }}
              </div>
            </div>
            <div
              *ngIf="customEvidenceTypes.length"
              class="custom-evidence-types-input-wrapper"
            >
              <div
                class="mt-2 custom-evidence-types-container"
                *ngFor="
                  let existingCustomType of customEvidenceTypes;
                  let i = index
                "
              >
                <!-- <span class="input-label"> Custom evidence type </span> -->
                <input
                  [value]="existingCustomType.customEvidenceType"
                  type="text"
                  class="text-input"
                  [id]="'customEvidenceType' + (i + 1)"
                />
                <div
                  (click)="existingCustomEvidenceDelete($event)"
                  class="custom-evidence-delete-button"
                ></div>
                <div class="evidence-config-lightbox-button">
                  <a (click)="toggleEvdConfigCustom($event)">Edit settings</a>
                </div>
              </div>
            </div>
            <div
              *ngIf="!customEvidenceTypes.length"
              class="custom-evidence-types-input-wrapper ml-0 mt-1 d-none"
            >
              <div class="custom-evidence-types-container mb-3">
                <!-- <span class="input-label"> Custom evidence type </span> -->
                <input
                  type="text"
                  class="text-input"
                  [id]="'customEvidenceType' + '1'"
                />
                <div class="custom-evidence-delete-button"></div>
                <div class="evidence-config-lightbox-button">
                  <a (click)="toggleEvdConfigCustom($event)">Edit settings</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- CONFIGURATION  -->
    <div
      [ngStyle]="{
        top: ConfigLightboxParam.top,
        left: ConfigLightboxParam.left
      }"
      [attr.dataId]="ConfigLightboxParam.currentEvidenceConfig.type"
      [attr.customId]="ConfigLightboxParam.currentEvidenceConfig.internalID"
      [attr.isCustom]="false"
      *ngIf="ConfigLightboxParam.display"
      class="evidence-config-lightbox"
    >
      <div class="d-flex justify-content-end lightbox-close-div">
        <a (click)="closeConfigLightbox($event)">
          <img
            id="configLightboxCloseImg"
            [src]="'assets/images/close_icon_teal.png'"
          />
        </a>
      </div>
      <h2>
        Configure
        {{
          ConfigLightboxParam.currentEvidenceConfig.customEvidenceType
            ? ConfigLightboxParam.currentEvidenceConfig.customEvidenceType
            : (ConfigLightboxParam.currentEvidenceConfig.label | lowercase)
        }}
      </h2>
      <div class="checkbox-purposes">
        <div class="py-2 input-transparent">
          <span class="input-label">Document purpose: </span>
          <span class="select-wrapper">
            <select
              class="select-input"
              style="width: 80%"
              id="currentDocumentPurpose"
              [value]="'emptySelect'"
            >
              <option
                value="{{ option.id }}"
                [selected]="
                  option.id ===
                  ConfigLightboxParam.currentEvidenceConfig.purposesOfUse[0]
                "
                *ngFor="let option of purposesOfUse"
              >
                {{ option.label }}
              </option>
            </select>
          </span>
          <div
            class="error-field"
            [attr.validatorMessage]="element.params.validatorMessage"
            [attr.validatorMessageField]="element.params.validatorMessage"
          ></div>
        </div>
      </div>
      <div class="element-wrapper mt-1">
        <div class="evidence-fields">
          <div class="element-wrapper mt-2 input-transparent">
            <span class="input-label">Not older than</span>
            <span class="select-wrapper">
              <select
                class="select-input"
                [disabled]="element.investorSide"
                id="currentNotOlderThan"
                name="Not older than"
                [attr.isRequired]="'false'"
                (change)="OnChangeNotOlderThan($event)"
              >
                <!-- if value is included in the array of default values, that is the current value if not it is custom -->
                <option
                  value="{{ option.key }}"
                  [selected]="isSelectedOption(option.key)"
                  *ngFor="let option of templatenotOlderThan"
                >
                  {{ option.name }}
                </option>
              </select>
              <div
                class="notOlderThan-text-input"
                [ngClass]="
                  ConfigLightboxParam.currentEvidenceConfig.notOlderThanM &&
                  !checkIfValueIsInTemplate(
                    ConfigLightboxParam.currentEvidenceConfig.notOlderThanM
                  )
                    ? 'd-block'
                    : 'd-none'
                "
              >
                <span style="margin-top: 13px" class="input-label d-flex"
                  >Custom number of months
                </span>
                <input
                  type="text"
                  class="text-input"
                  id="currentNotOlderThanCustom"
                  type="number"
                  min="1"
                  [value]="
                    ConfigLightboxParam.currentEvidenceConfig.notOlderThanM
                      ? ConfigLightboxParam.currentEvidenceConfig.notOlderThanM
                      : '5'
                  "
                />
              </div>
            </span>
            <div
              class="error-field"
              [attr.validatorMessage]="element.params.validatorMessage"
              [attr.validatorMessageField]="element.params.validatorMessage"
            ></div>
          </div>
        </div>
        <div
          class="error-field"
          [attr.validatorMessage]="element.params.validatorMessage"
          [attr.validatorMessageField]="element.params.validatorMessage"
        ></div>
        <div
          class="error-field-wrong-format d-none static-error"
          [attr.validatorMessage]="
            '*the date must be in the correct format: dd.mm.yyyy '
          "
        ></div>
      </div>
      <div class="element-wrapper mt-1">
        <!-- Textarea (autogrow) -->
        <span class="input-label"> Additional notes </span>
        <textarea
          maxlength="400"
          autosize
          class="text-input-div"
          id="evidenceNotes"
          name="evidenceNotes"
          (focus)="onEmit(elementService.OnInputFocus($event))"
          (blur)="onEmit(elementService.OnInputBlur($event))"
          >{{ ConfigLightboxParam.currentEvidenceConfig.Notes }}</textarea
        >
        <div
          class="error-field"
          [attr.validatorMessage]="element.params.validatorMessage"
          [attr.validatorMessageField]="element.params.validatorMessage"
        ></div>
      </div>
      <div class="element-wrapper config-button-group">
        <div class="d-flex justify-content-between align-items-center">
          <div
            class="save-button"
            (click)="
              saveConfigLightbox(
                $event,
                ConfigLightboxParam.currentEvidenceConfig.isCustom
              )
            "
          >
            Save
          </div>
          <div class="cancel-button" (click)="closeConfigLightbox($event)">
            Close
          </div>
        </div>
      </div>
    </div>
    <div
      [ngClass]="this.ConfigLightboxParam.display ? 'disable-buttons' : ''"
      style="margin-top: 30px"
      class="element-wrapper finalize-button-group"
    >
      <!-- BUTTON -->
      <button
        class="form-button form-button-1"
        (click)="onSaveTemplateData($event, false)"
      >
        {{ this.isFinalStep ? "Finalise configuration" : "Save" }}
      </button>
      <button
        *ngIf="!generalErrorState && !isFinalStep"
        class="form-button form-button-1"
        (click)="onSaveTemplateData($event, true)"
      >
        Complete configuration and exit
      </button>
      <div class="send-button-error button-error-field">
        *at least one verification type or evidence needs to be selected for the
        template
      </div>
      <div class="general-error-state button-error-field">
        *all of the selected types need to be configured before the template
        creation process can be completed
      </div>
      <div class="custom-documents-error-state button-error-field">
        *custom document cannot have an empty name
      </div>
    </div>
  </div>
  <div class="template-loading-container d-none">
    <img
      src="/assets/images/spinner-green.svg"
      width="66px"
      height="66px"
      class="my-auto mx-auto"
    />
  </div>
</div>
