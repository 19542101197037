import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { forkJoin, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { HelperServiceService } from "src/app/helper-service.service";
import { IdVerificationService } from "src/app/id-verification/id-verification.service";
import { PdfServerService } from "src/app/pdf-server.service";
import { UserService } from "src/app/user.service";
import { ElementsService } from "../../content-builder/content-elements/components/elements.service";

@Component({
  selector: "app-evidence-view-box",
  templateUrl: "./evidence-view-box.component.html",
  styleUrls: ["./evidence-view-box.component.scss"],
})
export class EvidenceViewBoxComponent implements OnInit {
  @Input() evidence;
  @Input() element;
  @Input() className = "";
  classStatus: string;
  showDownloadBtn = false;

  constructor(
    public idVerificationService: IdVerificationService,
    public elementService: ElementsService,
    public helperService: HelperServiceService,
    private userService: UserService,
    private pdfService: PdfServerService,
  ) {}

  ngOnInit(): void {
    this.classStatus = !this.element.investorSide
      ? this.evidence?.status?.toLowerCase().replace(/\s+/g, "-")
      : "";

    // checking if we can show the download btn on evidence box view
    if (this.evidence.type === "DigitalVerification") {
      if (
        this.evidence.status !== "Not requested" &&
        this.evidence.status !== "In progress"
      ) {
        this.showDownloadBtn = true;
      }
    } else if (this.evidence.state && this.evidence.state.documents === "HAS_DOCUMENTS") {
      this.showDownloadBtn = true;
    }
  }

  downloadEvidenceDocuments(event: any, evidence: any) {
    const observableBatch = [];
    evidence.documents?.forEach((element) => {
      console.log(
        "calling with params:",
        this.element.requestKey,
        "",
        true,
        evidence.key,
        element.Key ? element.Key : element.key ? element.key : element,
      );
      observableBatch.push(
        this.userService
          .getEndPoint(
            this.idVerificationService.buildEndPoint(
              "getDocumentsFile",
              this.idVerificationService.getActiveProject(),
              this.element.requestKey,
              "",
              true,
              evidence.key,
              element.Key ? element.Key : element.key ? element.key : element,
            ),
            { responseType: "json" },
            { observe: "response" },
            "funds",
          )
          .pipe(catchError((e) => of({}))),
      );
    });
    forkJoin(observableBatch).subscribe(async (response) => {
      // return original download button icon
      $(event.target).attr("src", "/assets/images/download.svg");
      response.forEach((docResponse) => {
        this.downloadCDDImage(docResponse);
      });
    });
  }

  downloadCDDImage(docResponse) {
    let data;
    // console.log('DOC RESPONSE', docResponse);
    if (
      docResponse["headers"].get("content-type") === "application/pdf" ||
      docResponse["body"].type === "buffer"
    ) {
      console.log("managing a buffer");
      const bufferArray = new Uint8Array(docResponse["body"].data);
      const blob = new Blob([bufferArray], { type: "application/pdf" });
      data = window.URL.createObjectURL(blob);
    } else {
      data =
        "data:application/png;base64," +
        this.idVerificationService.toBase64(docResponse["body"].data);
    }
    let filename = docResponse["headers"].get("filename");
    // done
    filename = this.helperService.generateDocumentFormat(docResponse, filename);
    // downloading the document
    this.helperService.downloadingDocument(data, filename);
  }

  addingImgToPDFGo(evidence, event) {
    // Adding the documents to the PDF
    const observableBatchDocuments = [];

    if (evidence.documents) {
      evidence.documents.map((document) => {
        observableBatchDocuments.push(
          this.userService.getEndPoint(
            this.idVerificationService.buildEndPoint(
              "getDocumentsFile",
              this.idVerificationService.getActiveProject(),
              this.element.requestKey,
              "",
              true,
              evidence.key,
              document.key ? document.key : document.Key,
            ),
            { responseType: "json" },
            { observe: "response" },
            "funds",
          ),
        );
      });
    }

    forkJoin(observableBatchDocuments).subscribe(async (documents) => {
      const documentFilesBufferPdf = [];
      documents.forEach(async (document) => {
        // add it to the list of documents
        if (document["headers"].get("content-type") === "application/pdf") {
          // pdf generator needs a buffer not a base64 string
          documentFilesBufferPdf.push({
            type: "application/pdf",
            data: {
              type: "Buffer",
              data: document["body"].data,
            },
            filename: document["headers"].headers.get("filename"),
          });
        } else {
          const imgObj = {
            type: "image",
            data:
              "data:image/png;base64," +
              this.helperService.toBase64(document["body"].data),
            filename: document["headers"].headers.get("filename"),
          };
          documentFilesBufferPdf.push(imgObj);
        }
      });

      this.idVerificationService.setActiveDigitalVerificationFiles(
        documentFilesBufferPdf,
      );
      this.idVerificationService
        .generateDVIdVerificationPDF(false, true)
        .then(() => $(event.target).attr("src", "/assets/images/download.svg"));
    });
  }

  addingImgToPDF(evidence, event) {
    // Adding the documents to the PDF
    const observableBatchDocuments = [];

    if (evidence.documents) {
      evidence.documents.map((document) => {
        observableBatchDocuments.push(
          this.userService.getEndPoint(
            this.idVerificationService.buildEndPoint(
              "getDocumentsFile",
              this.idVerificationService.getActiveProject(),
              this.element.requestKey,
              "",
              true,
              evidence.key,
              document.key ? document.key : document.Key,
            ),
            { responseType: "json" },
            { observe: "response" },
            "funds",
          ),
        );
      });
    }

    forkJoin(observableBatchDocuments).subscribe(async (documents) => {
      const documentFilesBufferPdf = [];
      documents.forEach(async (document) => {
        // add it to the list of documents
        if (document["headers"].get("content-type") === "application/pdf") {
          // pdf generator needs a buffer not a base64 string
          documentFilesBufferPdf.push({
            type: "application/pdf",
            data: {
              type: "Buffer",
              data: document["body"].data,
            },
            filename: document["headers"].headers.get("filename"),
          });
        } else {
          const imgObj = {
            type: "image",
            data:
              "data:image/png;base64," +
              this.helperService.toBase64(document["body"].data),
            filename: document["headers"].headers.get("filename"),
          };
          documentFilesBufferPdf.push(imgObj);
        }
      });

      this.idVerificationService.setActiveDigitalVerificationFiles(
        documentFilesBufferPdf,
      );
      this.idVerificationService
        .generateDVIdVerificationPDF()
        .then(() => $(event.target).attr("src", "/assets/images/download.svg"));
    });
  }

  downloadDVDocuments(event: any, evidence: any) {
    const evidenceKey = evidence.originalEvidenceKey
      ? evidence.originalEvidenceKey
      : evidence.key;
    const requestKey = this.element?.activeRelatedPartyKey
      ? this.element.activeRelatedPartyKey
      : this.element.requestKey;
    const fields = this.userService.getEndPoint(
      this.idVerificationService.buildEndPoint(
        "fillEvidenceField",
        this.idVerificationService.getActiveProject(),
        requestKey,
        "",
        true,
        evidenceKey,
      ),
      { responseType: "json" },
      { observe: "body" },
      "funds",
    );

    fields.subscribe(async (response) => {
      this.idVerificationService.setActiveVerification(evidenceKey);
      this.idVerificationService.setActiveDigitalVerificationDetails({
        response: response,
        files: [],
        filesFinishedParsing: false,
      });

      if (response["record"].type === "DigitalVerification") {
        if (response["record"].verificationData?.isResolved === true) {
          if (
            response["record"].verificationData.verificationType === "Identity"
          ) {
            const res =
              this.idVerificationService.parseIDVerificationData(response);
            this.addingImgToPDF(evidence, event);
          }
          if (
            response["record"].verificationData.verificationType ===
            "IdentityInstantAI"
          ) {
            const res =
              this.idVerificationService.parseIDVerificationData(response);
            this.addingImgToPDFGo(evidence, event);
          } else if (
            response["record"].verificationData.verificationType === "Address"
          ) {
            const res =
              this.idVerificationService.parseAVerificationData(response);
            const statuses =
              this.idVerificationService.resolveAvDetailsStatuses(res, true);
            this.idVerificationService
              .generateDVAddressVerificationPDF()
              .then(() =>
                $(event.target).attr("src", "/assets/images/download.svg"),
              );
          } else if (
            response["record"].verificationData.verificationType === "APS"
          ) {
            const res =
              this.idVerificationService.parseAPSVerificationData(response);
            const fieldTypes = this.idVerificationService.parseAPSData(res);

            response["record"].fieldTypesDV = fieldTypes;

            const vfeCode = evidenceKey ? evidenceKey.replace("EVD:", "") : "";

            this.idVerificationService
              .parseDVApsDataPdf(
                this.idVerificationService.getActiveDigitalVerificationDetails()
                  .response,
              )
              // temporary disable pdf generation so we could test the parsing
              .then((parsedAPS) => {
                const test = this.pdfService
                  .generatePDF(
                    "apsUpdated",
                    this.helperService.formatISODate(
                      new Date().toISOString(),
                      "yyyy-MM-dd-",
                      false,
                    ) + vfeCode,
                    JSON.stringify(parsedAPS),
                  )
                  .then(() => {
                    $(event.target).attr("src", "/assets/images/download.svg");
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              })
              .catch((error) => {
                console.error(error);
              });
          }
        }
      }
    });
  }

  downloadDocuments(event: any, evidence: any): void {
    $(event.target).attr("src", "/assets/images/spinner-green.svg");
    event?.stopPropagation();
    console.log("evidence", evidence);
    if (
      evidence.type === "DigitalVerification" &&
      evidence.verificationType != "Contract" &&
      evidence.verificationType !== "IdentityInstantAI"
    ) {
      this.downloadDVDocuments(event, evidence);
    } else if (
      evidence.type === "DigitalVerification" &&
      evidence.verificationType === "IdentityInstantAI" &&
      this.idVerificationService.isInvestorType()
    ) {
      this.downloadEvidenceDocuments(event, evidence);
    } else if (
      evidence.type === "DigitalVerification" &&
      evidence.verificationType === "IdentityInstantAI"
    ) {
      this.downloadDVDocuments(event, evidence);
    } else {
      this.downloadEvidenceDocuments(event, evidence);
    }
  }

  generateClickOnBox($event) {
    let requestedKey = this.evidence.originRequest
      ? this.evidence.originRequest
      : this.element.requestKey;
    let evidenceTypes = this.element.evidenceTypes;
    if (this.element.type === "relatedPartiesList") {
      requestedKey = this.element.activeRelatedPartyKey;
      evidenceTypes = this.element.activeRelatedParty.evidenceTypes;
    }
    if (this.element.investorSide && !this.element.beforeClaim) {
      if (!this.evidence.reviewState) {
        this.elementService.onReviewEvidenceClick(
          this.evidence.originalEvidenceKey
            ? this.evidence.originalEvidenceKey
            : this.evidence.key,
          requestedKey,
          this.evidence["documents"],
        );
      }
    } else if (
      this.evidence.verificationType !== "APS" &&
      this.evidence.status !== "Completed" &&
      this.evidence.status !== "Action required" &&
      this.evidence.state.documents == "NO_DOCUMENTS" &&
      !this.element.previewMode &&
      !this.evidence.subjectIsOwner &&
      !this.element.beforeClaim
    ) {
      this.elementService.documentUpdateFunction(
        $event,
        evidenceTypes,
        this.evidence,
        requestedKey,
        this.evidence.key,
      );
    } else if (!this.element.beforeClaim) {
      this.elementService.documentPreviewFunction(
        evidenceTypes,
        this.evidence,
        requestedKey,
        this.evidence.originalEvidenceKey
          ? this.evidence.originalEvidenceKey
          : this.evidence.key,
      );
    } else {
      this.elementService.documentPreviewFunctionOnClaim(
        evidenceTypes,
        this.evidence,
        requestedKey,
        this.evidence.originalEvidenceKey
          ? this.evidence.originalEvidenceKey
          : this.evidence.key,
      );
    }
  }
}
