<div
  id="{{ relatedParty.key }}"
  class="related-party-details mb-3"
  *ngIf="
    relatedParty.key == element.activeRelatedPartyKey && !element.investorSide
  "
>
  <div class="element-wrapper dashboard-tabs rp-tabs">
    <div class="tab-container">
      <div
        [ngClass]="tab.active ? 'tab active unselectable' : 'tab unselectable'"
        [attr.activatesTab]="tab.activates"
        [attr.fromTabGroup]="element.tabGroup"
        *ngFor="let tab of element.tabs"
        (click)="elementService.OnChangeDashTab($event)"
        id="{{ tab.activates }}"
      >
        <img
          class="img-icon"
          *ngIf="!tab.tooltip && tab.iconURL"
          src="{{ tab.iconURL }}"
        />
        <img
          [attr.activatesTab]="tab.activates"
          [attr.fromTabGroup]="element.tabGroup"
          (click)="elementService.OnChangeDashTab($event)"
          *ngIf="tab.tooltip && tab.iconURL"
          src="{{ tab.iconURL }}"
          [tooltip]="tab.tooltip"
        />
        <span>{{ tab.name }}</span>
      </div>
    </div>
  </div>
  <div
    [attr.tabGroup]="relatedParty.key"
    [attr.tabKey]="relatedParty.key + '_evidence'"
    *ngIf="element.evidenceTypes && element.evidenceTypes.length > 0"
    class="person-section-title title-t2 mb-2"
  >
    <h3>Evidence</h3>
  </div>
  <!-- RELATED PARTIES DOCS -->

  <div
    [attr.tabGroup]="relatedParty.key"
    [attr.tabKey]="relatedParty.key + '_evidence'"
    class="d-flex-low justify-content-between flex-wrap"
    [ngClass]="element.emptyRPEvidenceTypes ? 'd-none' : 'd-none'"
  >
    <app-evidence-view-box
      [evidence]="relatedPartyEvidence"
      [element]="element"
      *ngFor="
        let relatedPartyEvidence of element.activeRelatedParty.evidenceTypes
      "
    >
    </app-evidence-view-box>
  </div>

  <div
    class="related-document-add justify-content-between mt-2 d-none"
    [attr.tabGroup]="relatedParty.key"
    [attr.tabKey]="relatedParty.key + '_evidence'"
    *ngIf="element.showNewRelatedPartyButton"
  >
    <div
      style="margin-top: 10px !important; margin-bottom: 5px !important"
      [attr.data-evidenceKey]=""
      class="document-inner-entry no-documents"
      (click)="
        documentUploadScreenRelatedParty({
          requestKey: relatedParty.key,
          typeofInvestor: relatedParty.record.typeOfInvestor,
          existingEvidenceTypes: element.activeRelatedParty.evidenceTypes,
          isRelatedParty: true,
          existingDigitalVerifications:
            element.activeRelatedParty.digitalVerifications
        })
      "
    >
      <div
        style="background-color: #adadad"
        class="document-upload-entry-image"
      >
        +
      </div>
      <div class="document-upload-entry-detail">
        <h5 class="document-name">
          {{
            relatedParty.record.typeOfInvestor === "NaturalPerson"
              ? "Add documents or verifications"
              : "Add documents"
          }}
        </h5>
      </div>
    </div>
  </div>
  <!-- RELATED PARTIES FIELDS -->
  <div
    [ngClass]="element.emptyRPEvidenceTypes ? '' : ''"
    [attr.tabGroup]="relatedParty.key"
    [attr.tabKey]="relatedParty.key + '_details'"
  >
    <div class="d-flex w-100">
      <div style="margin-bottom: -1px" class="kvk-title mt-2 pt-2">
        {{ "appSpecific.Summary" | translate }}
      </div>
    </div>
    <div class="kvk-container related-parties-kvk-container pb-3 my-3">
      <div
        class="kvk-element-wrapper"
        [ngClass]="[
          tabelement['type'] !== 'html' &&
          tabelement['content'] !== undefined &&
          tabelement['title'] == true
            ? 'full-width-kvk'
            : '',
          tabelement['type'] !== 'html' &&
          tabelement['content'] == undefined &&
          tabelement['title'] == undefined
            ? 'd-none'
            : ''
        ]"
        *ngFor="let tabelement of element.activeRelatedPartyFields"
      >
        <div
          class="d-flex w-100"
          *ngIf="
            tabelement['type'] !== 'html' &&
            tabelement['content'] !== undefined &&
            tabelement['title'] == undefined
          "
        >
          <div class="kvk-label">{{ tabelement.key }}</div>
          <div class="kvk-information">{{ tabelement.content }}</div>
        </div>
        <div
          class="d-flex w-100"
          *ngIf="
            tabelement['type'] !== 'html' &&
            tabelement['content'] !== undefined &&
            tabelement['title'] == true
          "
        >
          <div style="margin-bottom: 16px" class="kvk-title mt-2 pt-2">
            {{ tabelement.content }}
          </div>
        </div>
        <div
          class="d-flex w-100"
          *ngIf="
            tabelement['type'] === 'html' && tabelement['content'] !== undefined
          "
        >
          <div class="kvk-label">{{ tabelement.key }}</div>
          <div class="kvk-information" [innerHTML]="tabelement.content"></div>
        </div>
      </div>
      <div
        *ngIf="!element.beforeClaim"
        class="element-wrapper mb-0 d-flex w-100"
      >
        <!--EDIT BUTTON -->
        <button
          class="ml-0 mb-0 form-button form-button-4"
          (click)="
            !element.claimed && !element.beforeClaim
              ? relatedPartyUpdate()
              : onDisplayLoader(
                  elementService.documentPreviewFunction(
                    element.activeRelatedPartyEvidenceTypes,
                    element.basicFieldEvidence,
                    element.activeRelatedPartyKey,
                    element.basicFieldEvidenceKey
                  )
                )
          "
        >
          <img src="/assets/images/update_white.svg" />
          <span>
            {{
              !element.claimed && !element.beforeClaim
                ? ("common.edit" | translate)
                : ("appSpecific.ViewDetails" | translate)
            }}
          </span>
        </button>
      </div>
      <!-- EXPIRED BASIC EVIDENCE WARNING -->
      <div
        [attr.tabGroup]="relatedParty.key"
        [attr.tabKey]="relatedParty.key + '_details'"
        *ngIf="element.isRPBasicFieldsExpired"
        class="element-wrapper mb-1 w-100 d-none"
      >
        <div class="d-flex flex-row align-items-center notification info">
          <img src="assets/images/icon-triangle-orange.svg" width="16px" />
          <div class="ml-2 description font-weight-bold">
            {{ "serviceprovider.basicFieldWarning" | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- RELATED PARTY VERIFICATIONS TAB -->
  <div
    *ngIf="
      element.activeRelatedPartyDV && element.activeRelatedPartyDV.length > 0
    "
    [attr.tabGroup]="relatedParty.key"
    [attr.tabKey]="relatedParty.key + '_checks'"
    class="element-wrapper d-none mt-0"
  >
    <!-- STATUS LIST -->
    <app-verification-statuses
      [statuses]="element.dvStatuses"
      [evidenceTypes]="element.activeRelatedPartyEvidenceTypes"
      [vtype]="element.vtype"
      [requestKey]="element.activeRelatedPartyKey"
    ></app-verification-statuses>
  </div>

  <div
    style="margin-top: 15px; margin-bottom: 20px"
    class="element-wrapper d-flex w-100"
    *ngIf="
      !element.investorSide &&
      !element.beforeClaim &&
      !element.sharedOn &&
      !element.claimed
    "
  ></div>
</div>
