import { Component, EventEmitter, Input, OnInit, Output, AfterContentInit } from '@angular/core';
import { UiService } from '../../ui.service';
import { HelperServiceService } from 'src/app/helper-service.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @Input() navBarData;
  @Input() logo;
  @Output() navLinkCLick = new EventEmitter<any>();
  @Output() changeActiveProfile= new EventEmitter<any>();
  @Output() processControllCompleted = new EventEmitter<any>();
  @Output() displayRightPanel = new EventEmitter<any>();

  navLinks  = [];
  activeProfile: string;
  currentEvidenceType: string;
  waitingStatus : boolean = false;
  percentageOfCompletedEvidence : number = 0;
  isCollapsed : boolean = true;
  allEvidenceCompleted : boolean = false;


  constructor(
    private helper: HelperServiceService,
  ) { }

  ngOnInit(): void {
    this.generateTheNavBar('mainProfile');
    this.generateActiveNonActiveNavBarLink(this.navLinks, true, 'isActive', true, '', true);
    this.calculateIcons();
  }

  updateprocessControllCompleted(state)
  {
    this.allEvidenceCompleted = state;
    this.processControllCompleted.emit(state);
  }

  generateTheCompletedEvidencePercentage()
  {
    let completed : number = 0;
    let all : number = 0;
    this.navBarData.forEach(profile =>{

      if(profile.evidence)
      {
        completed = completed + this.numberOfCOmpletedVerifications(profile.evidence);
        all = all + profile.evidence.length;

        let documentsEvidence = profile.evidence.find(evidence => evidence.type == 'documents');
        if (documentsEvidence && documentsEvidence.status.toUpperCase() != "COMPLETED")
        {
          completed = completed + this.numberOfCOmpletedVerifications(documentsEvidence.substeps);
          all = all + documentsEvidence.substeps.length-1;
        }
      }
    }
    )
    this.percentageOfCompletedEvidence = Math.floor((completed/all)*100);
  }


  calculateIcons() {
    let completed :boolean = true;
    let numberOfCompleted: number;
    for(let i=0; i< this.navLinks.length; i++) {
      if(this.navLinks[i].substeps)
      {
        let status = this.navLinks[i].status;
        numberOfCompleted = this.numberOfCOmpletedVerifications(this.navLinks[i].substeps)
        if( numberOfCompleted == this.navLinks[i].substeps.length)
        {
          status = "completed"
          this.navLinks[i].isActive = false;
        }
        else if( numberOfCompleted != 0)
        {
          status = "in-progress"
        }
        this.navLinks[i].class = this.generateClass(this.navLinks[i].isActive, status, this.navLinks[i].current, this.navLinks[i].type);
        this.navLinks[i].status = status;
        for(let j=0; j< this.navLinks[i]['substeps']?.length; j++) {
          this.navLinks[i]['substeps'][j].class = this.generateClass(this.navLinks[i]['substeps'][j].isActive, this.navLinks[i]['substeps'][j].status, this.navLinks[i]['substeps'][j].current, this.navLinks[i]['substeps'][j].type);
        }
      }
      else if(this.navLinks[i].evidence && this.navLinks[i].evidence.length)
      {
        let status = this.navLinks[i].status;
        numberOfCompleted = this.numberOfCOmpletedVerifications(this.navLinks[i].evidence)
        if(status.toUpperCase() != "LOADING")
        {
          if( numberOfCompleted == this.navLinks[i].evidence.length)
          {
            status = "completed"
            this.navLinks[i].isActive = false;
          }
          else if( numberOfCompleted != 0)
          {
            status = "in-progress"
          }
        }
        this.navLinks[i].class = this.generateClass(this.navLinks[i].isActive, status, this.navLinks[i].current, this.navLinks[i].type);
        this.navLinks[i].status = status;
      }
      else
      {
        if(this.navLinks[i].status.toUpperCase() == "COMPLETED")
        {
          this.navLinks[i].isActive = false;
        }
        this.navLinks[i].class = this.generateClass(this.navLinks[i].isActive, this.navLinks[i].status, this.navLinks[i].current, this.navLinks[i].type);
      }
      if(completed && this.navLinks[i].type != 'title' && !this.navLinks[i].class.includes('completed') )
      {
        completed = false
      }

    }
    this.updateprocessControllCompleted(completed);
    this.generateTheCompletedEvidencePercentage()
  }


  markCurrentStepAsCompleted(requestKey) {


    // console.log(currentst)
    let currentStepNavLinks;
    let currentStepNavData;

    for(let i=0; i< this.navLinks.length; i++) {
      if(this.navLinks[i].current) {
        currentStepNavLinks = this.navLinks[i];
    }}

    for(let j=0; j< this.navBarData.length; j++) {

      for(let z=0; z< this.navBarData[j].evidence?.length; z++) {
        if(JSON.stringify(currentStepNavLinks.keysArray) === JSON.stringify(this.navBarData[j].evidence[z].keysArray)) {
          currentStepNavData = this.navBarData[j].evidence[z];
        }
      }
    }

   
    currentStepNavLinks['status'] = "COMPLETED";
    currentStepNavLinks['isActive'] = false;
        
    currentStepNavData['status'] = "COMPLETED";
    currentStepNavData['isActive'] = false;
    this.calculateIcons();
    this.goToNextStep();
  }


  updateDVStatus(relatedPartyKey?) {


    let navBarData = this.navBarData[0].evidence;


    if(relatedPartyKey) {
      for(let j=0; j< this.navBarData.length; j++) {
        if(this.navBarData[j].id === relatedPartyKey) {
          navBarData = this.navBarData[j].evidence
      }}
    }
    
    navBarData?.forEach(evidence => {
      if(evidence.type === "digitalVerifications"  && evidence.status === "LOADING") {
        evidence.status = "PENDING";
        evidence.class = "active in-progress";
      }
    });

    this.generateTheNavBar(this.activeProfile, this.currentEvidenceType);
    if(this.waitingStatus)
    {
      this.generateActiveNonActiveNavBarLink(this.navLinks, false, 'current');
      this.generateActiveNonActiveNavBarLink(this.navLinks, true, 'isActive', true, '', true);
      this.waitingStatus = false;
    }

  }

  updateDVIniateLink(initiationLinks?, relatedPartyKey?)
  {

    let navBarDataEvidence = this.navBarData[0].evidence;


    if(relatedPartyKey) {

      for(let j=0; j< this.navBarData.length; j++) {
        if(this.navBarData[j].id === relatedPartyKey) {
          navBarDataEvidence = this.navBarData[j].evidence
      }}
    }
    

    for (const [key, value] of Object.entries(initiationLinks)) 
    {
      evidenceLoop:
      for(let j = 0; j < navBarDataEvidence?.length; j++)
      {
        if(navBarDataEvidence[j].keysArray?.includes(key))
        {
          navBarDataEvidence[j].verificationLink = value;
          navBarDataEvidence[j].status = "PENDING";
          navBarDataEvidence[j].class = "active in-progress";
          break evidenceLoop;
        }
      }
    }
    this.generateTheNavBar( this.activeProfile, this.currentEvidenceType);
      if(this.waitingStatus)
      {
        this.generateActiveNonActiveNavBarLink(this.navLinks, false, 'current');
        this.generateActiveNonActiveNavBarLink(this.navLinks, true, 'isActive', true, '', true);
        this.waitingStatus = false;
      }
  }

  updateRPData(newRPEvidence, key) {

    for(let i = 0; i< this.navBarData.length; i++ )
    {
      if(this.navBarData[i].id == key)
      {
        this.navBarData[i].evidence = newRPEvidence;
      }
    }
    this.generateTheNavBar(this.activeProfile, this.currentEvidenceType)
  }

  numberOfCOmpletedVerifications(verifications) : number
  {
    let sum : number = 0
    for(let i = 0; i< verifications.length; i++)
    {
      if(verifications[i].status?.toUpperCase() == "COMPLETED")
      {
        sum+=1;
      }
    }
    return sum;
  }


  goToNextStep() {

    navBarLoop: for(let i=0; i < this.navLinks.length ; i++   )
    {
      if(this.navLinks[i].current)
      {
        if(this.navLinks[i].substeps?.length > 0 && !this.navLinks[i].class.includes("completed"))
        {
          substepsLoop: for(let j=0; j< this.navLinks[i].substeps.length; j++)
          {
            if(this.navLinks[i].substeps[j].current)
            {
              this.generateActiveNonActiveNavBarLink(this.navLinks[i].substeps, false, 'current');
              this.generateActiveNonActiveNavBarLink(this.navLinks[i].substeps, true, 'isActive', true , 'documents', false, j+1)
              break substepsLoop;
            }
          }
        }
        else{
          this.generateActiveNonActiveNavBarLink(this.navLinks, false, 'current');
          this.generateActiveNonActiveNavBarLink(this.navLinks, true, 'isActive', true , '', true, i+1)
          break navBarLoop;
        }
      }
    }
    this.calculateIcons();
  }

  generateTheNavBar(activeProfile, currentEvidenceType ? : string)
  {
    this.navLinks = [];
    this.navBarData.forEach(profile => {
      if(profile.id== activeProfile)
      {
        this.activeProfile = profile.id;
        this.navLinks.push(
          {
          title : profile.title,
          type: "title",
          status: "",
          isActive: false,
          current: false,
          relatedPartyRoles: profile.relatedPartyRoles
        });
        this.navLinks.push(...profile.evidence);
      }
      else
      {
        this.navLinks.push(profile)
      }
    }
    )
    if(currentEvidenceType)
    {
      this.setCurrentElementByType(currentEvidenceType);
    }
    this.calculateIcons();
  }

  setCurrentElementByType(type:string)
  {
    navLinksLoop:
    for(let i = 0; i < this.navLinks.length; i++ )
    {
      if(this.navLinks[i].type == type)
      {
        this.navLinks[i].current = true
        this.navLinkCLick.emit({
          'navLink': this.navLinks[i],
          'displayRightPanel': true
        });
        break navLinksLoop;
      }
      if(this.navLinks[i].substeps)
      {
        subStepsLoop:
        for(let j=0 ; j< this.navLinks[i].substeps.length ; j++)
        {
          if(this.navLinks[i].substeps[j].type == type)
          {
            this.navLinks[i].current = true;
            this.navLinks[i].substeps[j].current = true;
            this.isCollapsed = false;
            this.navLinkCLick.emit({
              'navLink':  this.navLinks[i].substeps[j],
              'displayRightPanel': true
            });
            break navLinksLoop;
          }
        }
      }
    }
  }

  generateActiveNonActiveNavBarLink(navLinks, current: boolean, checkBy : string, emitType : boolean = false, type?: string, openSubsteps: boolean = false, startFrom: number =0)
  {

    for(let i = startFrom; i < navLinks.length; i++)
    {
      if (navLinks[i][checkBy] && !navLinks[i]?.class?.includes('loading'))
      {
        if(navLinks[i].type != "relatedParties" && navLinks[i].type != "mainProfile")
        {
          navLinks[i].current = current;
        }
        if(emitType)
        {

          this.navLinkCLick.emit({
            'navLink': navLinks[i]
          });
          this.currentEvidenceType = navLinks[i].type
        }
        if(type == 'documents' && openSubsteps)
        {
          this.isCollapsed = false;
        }
        if(navLinks[i].substeps?.length > 0)
        {
          this.generateActiveNonActiveNavBarLink(navLinks[i].substeps, current, checkBy, emitType, 'documents', openSubsteps);
        }
        if(navLinks[i].evidence?.length > 0)
        {
          this.changeActiveProfile.emit(navLinks[i].id);
          this.generateActiveNonActiveNavBarLink(navLinks[i].evidence, current, checkBy, current, '' , openSubsteps);
          this.generateTheNavBar(navLinks[i].id);
        }
        return;
      }
    }
    if(startFrom != 0)
    {
      this.generateActiveNonActiveNavBarLink(navLinks, current, checkBy, emitType, type, openSubsteps, 0);
      return;
    }

    if(this.numberOfCOmpletedVerifications(this.navLinks) != this.navLinks.length)
    {
      this.navLinkCLick.emit({
        'navLink': {type: 'waiting'}
      });
      this.waitingStatus = true;
    }
    return;
  }
  openCloseSidebar() {
    $("#mySidenav").toggleClass("collapse");
    // mozebi so .collapse
  }

  generateLink($event, link, type  : string, linkIndex : number, substepIndex? : number)
  {
    if(((!link.isActive || link.class.includes('completed')) && !link.class.includes("mainprofile") && !link.class.includes("relatedparties")) || link.class.includes('loading') )
    {
      $event.preventDefault()
      return;
    }
    this.linkClick(type, linkIndex, substepIndex);

  }
  linkClick(type : string, linkIndex : number, substepIndex? : number)
  {
    !this.allEvidenceCompleted ? this.generateActiveNonActiveNavBarLink(this.navLinks, false, 'current') : '' ;

    if(type == "relatedParties" || type == "mainProfile")
    {
      if(this.navLinks[linkIndex].status?.toUpperCase() != 'COMPLETED')
      {
        this.changeActiveProfile.emit(this.navLinks[linkIndex].id);
        this.generateActiveNonActiveNavBarLink(this.navLinks[linkIndex].evidence, true, 'isActive', true);
      }
      this.generateTheNavBar(this.navLinks[linkIndex].id);
    }
    else if(!this.allEvidenceCompleted)
    {
      this.navLinks[linkIndex].current= true;
      if(Number.isInteger(substepIndex))
      {
        this.navLinks[linkIndex].substeps[substepIndex].current = true;
        this.navLinkCLick.emit({
        'navLink': this.navLinks[linkIndex].substeps[substepIndex]
      });
    } else
      {
        this.isCollapsed = true;
        let collapsable: any;
        collapsable = $(".subSteps")
        collapsable.collapse('hide');
        this.navLinkCLick.emit({
          'navLink' : this.navLinks[linkIndex]
        });
      }
  }
    if(this.helper.isMobile())
    {
      let collapsable : any = $("#navbarSupportedContent");
      collapsable.collapse('hide');
    }

  }

  generateClass(isActive:boolean, status: string, current: boolean, type: string ):string
  {
    let className = status.replace(/\s/g, "-").toLocaleLowerCase() + " " + type.replace(/\s/g, "-").toLocaleLowerCase();
    if(isActive)
    {
      className +=" active"
    }
    if(current)
    {
      className += " current"
    }
    // ################da vidam za reject shto se deshava

    return className;
  }
  setSubstepsActive($event, linkIndex: number)
  {
    if(this.navLinks[linkIndex].isActive)
    {
      this.generateActiveNonActiveNavBarLink(this.navLinks, false, 'current');
      this.navLinks[linkIndex].current= true;
      this.generateActiveNonActiveNavBarLink(this.navLinks[linkIndex].substeps, true, 'isActive', true, 'documents');
      if(this.helper.isMobile())
      {
        let collapsable : any = $("#navbarSupportedContent");
        collapsable.collapse('hide');
        this.ChangeRightPanelStatus();
      }
    }
    else
    {
      $event.preventDefault()
      return;
    }

  }

  isKeyRelatedParty(requestKey) {
    const requestKeyTypu = requestKey.substring(0, 3);
    return requestKeyTypu === "RPE";
  }
  ChangeRightPanelStatus()
  {
    this.displayRightPanel.emit($('#collapseBtn').attr("aria-expanded")=='true'? true : false);
  }

}
