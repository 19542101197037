import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subject, of, forkJoin, concat, Subscription } from "rxjs";
import { buffer, catchError, share, takeUntil, toArray } from "rxjs/operators";
import { HelperServiceService } from "src/app/helper-service.service";
import { IdVerificationService } from "src/app/id-verification/id-verification.service";
import { UserService } from "src/app/user.service";

@Component({
  selector: "app-cdd-process-control-screen",
  templateUrl: "./cdd-process-control-screen.component.html",
  styleUrls: ["./cdd-process-control-screen.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CddProcessControlScreenComponent implements OnInit, OnDestroy {
  headerInfo: {};
  headerClasses: {};
  kvkResults: any;
  shareKey: string;
  fullyCompleted = false;
  verificationHeading;
  pills: any;
  showConfirmationWindow = false;
  confirmationChoice = false;
  confirmationLoading = false;
  paramArguments;
  projectkey;
  confirmationError;
  showPCSScreen = false;
  legalPersonTypeList;
  relatedLegalPersonTypeList;
  notAllowedLegalTypes;
  backgroundColor = "#ffffff";
  logo;
  relatedPartyRoles;
  showLanguageOption;
  displayInvestorReviewStatus: Subscription;
  reviewWindowClosed: Subscription;
  investorKey;
  investorData;
  reviewStatus;
  dvStatus;
  projectTextColorClass = "dark-color";
  basicInformationWindow = false;
  requestCallResponse;

  // if this is true that means the project is created and we're displaying  the PCS from the dashboard
  @Input()
  isReview;
  // function to start a different review process
  @Output() pcsReviewFunction = new EventEmitter<string>();
  activePill = null; // currently active pill
  // given that this is cdd pcs we only have two steps which are hard coded
  activeVFEs;

  contentDom = [];
  errorState = false;
  waitingState = false;
  stateMessage = this.translate.instant("ARPCS.errorMessage");
  displayLoader;
  record;
  // the asumption right now is that if we are on this page the user has already given
  // consent in the previous screen. Given that they may get to this screen
  // by typing url the consentParsed should be populated through a service
  consentParsed = true;
  constructor(
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private userService: UserService,
    private idVerificationService: IdVerificationService,
    private helperService: HelperServiceService,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.displayLoader = true;
    this.relatedPartyRoles = [
      ...this.idVerificationService.getRelatedPartyRoles(),
    ];
    this.verificationHeading = this.translate.instant("ARPCS.title");
    this.headerInfo = {
      headerType: "full", // 'inline',
      // 'headerTitle': 'Investor Dashboard',
      activeNavLink: "funds",
      projectType: "Investor",
      hideMainNav: true,
      isInvestor: true,
    };
    this.headerClasses = {
      backgroundClass: "tealish-gradient-bg",
      sectionClass: "ic_height_auto", // 'ic_height_auto',
    };

    this.shareKey = this.activatedRoute.snapshot.params.shareKey;
    this.projectkey = this.activatedRoute.snapshot.params.projectKey;

    // we need to check if the request has already gone trough the PCS process
    if (!this.isReview) {
      this.activeVFEs = [
        {
          id: "basicInfo",
          isAutomaticPill: false,
          displayPill: true,
          title: this.translate.instant("ARPCS.title6"),
          type: "basicInfo",
          subTitle: this.translate.instant("ARPCS.content4"),
          isActive: false,
          rejected: false,
          substeps: [],
          status: "PENDING",
          clickAction: "startBasicinformation",
        },
        {
          id: "reviewDocuments",
          isAutomaticPill: false,
          displayPill: true,
          title: this.translate.instant("ARPCS.title5"),
          type: "basicInfo",
          subTitle: this.translate.instant("ARPCS.content5"),
          isActive: false,
          rejected: false,
          substeps: [],
          status: "PENDING",
          clickAction: "startReviewProcess",
        },
      ];

      this.userService
        .getEndPoint(
          this.idVerificationService.buildEndPoint(
            "investors",
            this.projectkey,
          ),
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .subscribe(
          (response) => {
            if (response === undefined) {
              this.showConfirmationWindow = true;
              this.confirmationLoading = false;
              this.confirmationError = true;
              return;
            }
            this.showLanguageOption = true;

            this.requestCallResponse = response["record"];
            if (
               response["record"].projectBranding &&
               response["record"].projectBranding.projectLogo
            ) {
              this.logo =
                 response["record"].projectBranding.projectLogo;
              this.displayLoader = false;
            } else {
              let endpoint =
                "/api/investor/requests/" +
                this.shareKey +
                "/organization/logo";
              if (
                response["records"] &&
                 response["record"].serviceProviderProjectKey
              ) {
                endpoint =
                  "/api/projects/" +
                   response["record"].serviceProviderProjectKey +
                  "/logo";
              }
              this.userService
                .getEndPoint(
                  endpoint,
                  { responseType: "blob" },
                  { observe: "body" },
                  "funds",
                )
                .subscribe(
                  async (response2) => {
                    if (response2 === undefined) {
                      console.log("Error while fetching organization logo");
                      this.logo = "assets/images/logo-alternate.svg";
                      this.displayLoader = false;
                      return;
                    }
                    if (response2["type"] === "application/json") {
                      this.logo = JSON.parse(
                        await (response2 as Blob).text(),
                      ).link;
                      this.displayLoader = false;
                    } else {
                      const urlCreator = window.URL
                        ? window.URL
                        : (window as any).webkitURL;
                      this.logo = urlCreator.createObjectURL(response2);
                      this.displayLoader = false;
                    }
                  },
                  (error) => {
                    console.log("Error while fetching organization logo");
                    this.logo = "assets/images/logo-alternate.svg";
                    this.displayLoader = false;
                  },
                );
            }

            if (response["record"]) {
              if (
                response["record"].typeOfInvestor === 'AutomaticRequest'
              ) {
                this.showPCSScreen = true;
                this.legalPersonTypeList =
                  this.idVerificationService.getLegalPersontypeListCopyAR();
                this.relatedLegalPersonTypeList =
                  this.idVerificationService.getLegalPersontypeListCopyAR();
                this.notAllowedLegalTypes =
                response["record"].notAllowedLegalTypes;
                this.record =response["record"];
                // this.setActivePill(0);
                this.decideActivePill(response);
                // this.pills = this.generatePills();
                // this.generateContent();
                this.startBasicinformation();
                // check for branding
                if (response["record"].projectBranding) {
                  // branding present
                  const color =
                  response["record"].projectBranding.projectColor;
                  if (color) {
                    this.backgroundColor = color;
                    this.projectTextColorClass =
                      this.helperService.detectTextColor(
                        this.helperService.hexToRgb(color),
                      );
                  }
                }
              } else {
                // if it's not an AR navigate to the dashboard
                this.router.navigate([
                  `investor/dashboard/${this.projectkey}/`,
                ]);
              }
            } else {
              this.router.navigate([`investor/dashboard/${this.projectkey}/`]);
            }
          },
          (error) => {
            this.showConfirmationWindow = true;
            this.confirmationLoading = false;
            this.confirmationError = true;
            this.displayLoader = false;
            this.router.navigate([`investor/dashboard/`]);
          },
        );
    } else {
      // The request has gone trough the PCS proces or is not an automatic request, we proceed here
      this.displayInvestorReviewStatus =
        this.idVerificationService.detectisplayLoadInvestorReviewStatus.subscribe(
          async (statuses) => {
            if (statuses === "loading") {
              return;
            } else {
              this.displayLoader = false;
              this.showLanguageOption = true;
              this.investorData =
                this.idVerificationService.getActiveInvestorDetaisl();
              if (this.investorData.projectBranding) {
                // branding present
                const color = this.investorData.projectBranding.projectColor;
                if (color) {
                  this.backgroundColor = color;
                  this.projectTextColorClass =
                    this.helperService.detectTextColor(
                      this.helperService.hexToRgb(color),
                    );
                }
              }
              if (
                this.investorData.projectBranding &&
                this.investorData.projectBranding.projectLogo
              ) {
                this.logo = this.investorData.projectBranding.projectLogo;
              } else {
                // fetch the logo
                const endpoint =
                  "/api/projects/" +
                  this.investorData.serviceProviderProjectKey +
                  "/logo";
                if (
                  !this.logo &&
                  statuses.reviewState === "loading" &&
                  statuses.dvState === "loading"
                ) {
                  await this.userService
                    .getEndPoint(
                      endpoint,
                      { responseType: "blob" },
                      { observe: "body" },
                      "funds",
                    )
                    .subscribe(
                      async (response2) => {
                        if (response2 === undefined) {
                          console.log("Error while fetching organization logo");
                          this.logo = "assets/images/logo-alternate.svg";
                          return;
                        }

                        if (response2["type"] === "application/json") {
                          this.logo = JSON.parse(
                            await (response2 as Blob).text(),
                          ).link;
                        } else {
                          const urlCreator = window.URL
                            ? window.URL
                            : (window as any).webkitURL;
                          this.logo = urlCreator.createObjectURL(response2);
                        }
                      },
                      (error) => {
                        console.log("Error while fetching organization logo");
                        this.logo = "assets/images/logo-alternate.svg";
                      },
                    );
                }
              }
              if (this.investorData.projectBranding) {
                // branding present
                const color = this.investorData.projectBranding.projectColor;
                if (color) {
                  this.backgroundColor = color;
                }
              }
              // end logo fetch
              this.activeVFEs = [];
              if (this.investorData.record) {
                this.investorData = this.investorData.record;
              }
              let pillCounter = 0;
              // if it's an automatic request add the first step as completed
              if (
                this.investorData.isAutomaticRequest &&
                !this.investorData.convertedOn
              ) {
                this.activeVFEs.push({
                  id: "basicInfo",
                  isAutomaticPill: false,
                  displayPill: true,
                  title: this.translate.instant("ARPCS.title6"),
                  type: "basicInfo",
                  subTitle: this.translate.instant("ARPCS.content4"),
                  isActive: false,
                  rejected: false,
                  substeps: [],
                  status: "COMPLETED",
                });
                pillCounter++;
              }
              this.reviewStatus = statuses.reviewState;
              this.dvStatus = statuses.dvState;
              // if the reviews are finished and the DV initiations are finished and there's a link provided by the BE redirect to that link
              if (
                this.reviewStatus === "no-review" &&
                this.dvStatus === "no-warning"
              ) {
                if (this.investorData.redirectUrl) {
                  let redirectUrl = this.investorData.redirectUrl;
                  redirectUrl =
                    redirectUrl.indexOf("://") === -1
                      ? "https://" + redirectUrl
                      : redirectUrl;
                  window.location.replace(redirectUrl);
                }
              }
              let activeStepSet = false;
              // BASIC FIELDS
              let basicFieldsStepCompleted = false;
              this.activeVFEs.push({
                id: "basicInfo",
                isAutomaticPill: false,
                displayPill: true,
                title: this.translate.instant("review.pcs.title1"),
                type: "basicInfo",
                subTitle: this.translate.instant("review.pcs.subtitle1"),
                isActive: true,
                rejected: false,
                substeps: [],
                status: "PENDING",
                clickAction: "reviewBasicFields",
              });
              if (
                this.investorData.basicFieldsEvidence &&
                this.investorData.basicFieldsEvidence.reviewState
              ) {
                // basic fields step needs to be active
                if (!activeStepSet) {
                  this.setActivePill(pillCounter);
                  activeStepSet = true;
                }
              } else {
                // basic fields step nees to be shown as completed
                basicFieldsStepCompleted = true;
                this.activeVFEs[pillCounter].status = "COMPLETED";
                this.activeVFEs[pillCounter].isActive = false;
              }
              pillCounter++;
              // END BASIC FIELDS
              // DIGITAL VERIFICATIONS
              // wether to show DV step
              // first we save the indentityGo step
              let showDVStep = true;
              if (
                this.investorData.digitalVerifications &&
                this.investorData.digitalVerifications.length
              ) {
                let skipableStepCount = 0;
                if (!this.investorData.identityGoStep) {
                  this.investorData.identityGoStep =
                    this.investorData.originalBEDigitalVerifications.filter(
                      (type) => type.verificationType === "IdentityInstantAI",
                    );
                  skipableStepCount++;
                }
                if (!this.investorData.contractStep) {
                  this.investorData.contractStep =
                    this.investorData.originalBEDigitalVerifications.filter(
                      (type) => type.verificationType === "Contract",
                    );
                  skipableStepCount++;
                }

                if (
                  skipableStepCount <
                  this.investorData.digitalVerifications.length
                ) {
                  this.investorData.digitalVerifications =
                    this.investorData.digitalVerifications.filter(
                      (type) => type.verificationType !== "IdentityInstantAI",
                    );
                  this.investorData.digitalVerifications =
                    this.investorData.digitalVerifications.filter(
                      (type) => type.verificationType !== "Contract",
                    );
                }

                if (
                  this.investorData.digitalVerifications.length === 1 &&
                  this.investorData.digitalVerifications[0].verificationType ===
                    "IdentityInstantAI"
                ) {
                  showDVStep = false;
                }
              }

              if (
                this.investorData.digitalVerifications &&
                this.investorData.digitalVerifications.length &&
                showDVStep
              ) {
                this.investorData.originalBEDigitalVerifications =
                  this.investorData.originalBEDigitalVerifications.filter(
                    (type) => type.verificationType !== "IdentityInstantAI",
                  );
                const totalVerifications =
                  this.investorData.originalBEDigitalVerifications.length;
                let completedVerifications =
                  this.investorData.originalBEDigitalVerifications.length;

                if (
                  this.investorData.digitalVerifications &&
                  this.investorData.digitalVerifications.length &&
                  this.investorData.identityGoStep &&
                  this.investorData.identityGoStep[0] &&
                  this.reviewStatus === "no-review"
                ) {
                  this.investorData.digitalVerifications.push(
                    this.investorData.identityGoStep[0],
                  );
                  this.investorData.originalBEDigitalVerifications.push(
                    this.investorData.identityGoStep[0],
                  );
                }

                // if there is evidence add the evidence pill
                this.activeVFEs.push({
                  id: "evidenceTypes",
                  isAutomaticPill: false,
                  displayPill: true,
                  title: this.translate.instant("review.pcs.title2"),
                  type: "evidenceTypes",
                  subTitle: this.translate.instant("review.pcs.subtitle2", {
                    total: totalVerifications,
                    completed: completedVerifications,
                  }),
                  isActive: true,
                  rejected: false,
                  substeps: [],
                  status: "PENDING",
                  clickAction: "reviewDigitalVerifications",
                });

                for (
                  let i = 0;
                  i < this.investorData.originalBEDigitalVerifications.length;
                  i++
                ) {
                  if (
                    this.investorData.originalBEDigitalVerifications[i]
                      .status === "In progress"
                  ) {
                    completedVerifications = completedVerifications - 1;
                    this.activeVFEs[pillCounter].subTitle =
                      this.translate.instant("review.pcs.subtitle2", {
                        total: totalVerifications,
                        completed: completedVerifications,
                      });
                  }
                }
                // check if any digital verification needs to be reviewed\
                this.investorData.digitalVerifications.forEach((dv) => {
                  if (dv.reviewState === false) {
                    // this means that at least one DV evidence has been reviewed
                    // it has already started and has a "started" progres
                    this.activeVFEs[pillCounter].status = "STARTED";
                  }
                });
                if (
                  this.isDvReviewState(this.investorData.digitalVerifications)
                ) {
                  if (!activeStepSet) {
                    this.setActivePill(pillCounter);
                    activeStepSet = true;
                  }
                } else {
                  // mark it as completed
                  this.activeVFEs[pillCounter].status = "COMPLETED";
                  this.activeVFEs[pillCounter].isActive = false;
                  // check if any DVS are pending
                  this.investorData.digitalVerifications.forEach((dv) => {
                    if (dv.status === "In progress") {
                      this.activeVFEs[pillCounter].status = "WARNING";
                      this.activeVFEs[pillCounter].isActive = true;
                    }
                  });
                  // check if we are in a state where the iframe was completed and closed
                  // this.investorData.digitalVerifications.forEach(dv => {
                  //   if (dv.iframeStatus) {
                  //     this.activeVFEs[pillCounter].status = 'WARNING';
                  //     this.activeVFEs[pillCounter].isActive = false;
                  //     this.activeVFEs[pillCounter].subTitle = "TEST SUBTITLE";
                  //     this.activeVFEs[pillCounter].title = "test title";
                  //   }s
                  // });
                }
                pillCounter++;
              }
              // // END DIGITAL VERIFICATIONS
              // START IDENTITY GO STEP
              if (
                this.investorData.identityGoStep &&
                this.investorData.identityGoStep.length
              ) {
                // if there is evidence add the evidence pill
                this.activeVFEs.push({
                  id: "evidenceTypes",
                  isAutomaticPill: false,
                  displayPill: true,
                  title: this.translate.instant("verificationType.Identity"),
                  subTitle: this.translate.instant("review.pcs.subtitle1"),
                  type: "evidenceTypes",
                  isActive: true,
                  rejected: false,
                  substeps: [],
                  status: "PENDING",
                  clickAction: "reviewDigitalVerificationsIdentityInstantAI",
                });
                // check if any digital verification needs to be reviewed\
                this.investorData.identityGoStep.forEach((dv) => {
                  if (dv.reviewState === false) {
                    // this means that at least one DV evidence has been reviewed
                    // it has already started and has a "started" progres
                    this.activeVFEs[pillCounter].status = "STARTED";
                  }
                });
                if (this.isDvReviewState(this.investorData.identityGoStep)) {
                  if (!activeStepSet) {
                    this.setActivePill(pillCounter);
                    activeStepSet = true;
                  }
                } else {
                  // mark it as completed
                  this.activeVFEs[pillCounter].status = "COMPLETED";
                  this.activeVFEs[pillCounter].isActive = false;
                  // check if any DVS are pending
                  this.investorData.identityGoStep.forEach((dv) => {
                    if (dv.status === "In progress") {
                      this.activeVFEs[pillCounter].status = "WARNING";
                      this.activeVFEs[pillCounter].isActive = true;
                    }
                  });
                }
                pillCounter++;
              }
              // END IDENTITY GO STEP
              // START CONTRACT STEP
              if (
                this.investorData.contractStep &&
                this.investorData.contractStep.length
              ) {
                // if there is evidence add the evidence pill
                this.activeVFEs.push({
                  id: "evidenceTypes",
                  isAutomaticPill: false,
                  displayPill: true,
                  title: this.translate.instant("review.pcs.fillAndSign"),
                  subTitle: this.translate.instant(
                    "review.pcs.fillAndSignSubTitle",
                  ),
                  type: "evidenceTypes",
                  isActive: true,
                  rejected: false,
                  substeps: [],
                  status: "PENDING",
                  clickAction: "reviewDigitalVerificationsContract",
                });
                // check if any digital verification needs to be reviewed\
                this.investorData.contractStep.forEach((dv) => {
                  if (dv.reviewState === false) {
                    // this means that at least one DV evidence has been reviewed
                    // it has already started and has a "started" progres
                    this.activeVFEs[pillCounter].status = "STARTED";
                  }
                });
                if (this.isDvReviewState(this.investorData.contractStep)) {
                  if (!activeStepSet) {
                    this.setActivePill(pillCounter);
                    activeStepSet = true;
                  }
                } else {
                  // mark it as completed
                  this.activeVFEs[pillCounter].status = "COMPLETED";
                  this.activeVFEs[pillCounter].isActive = false;
                  // check if any DVS are pending
                  this.investorData.contractStep.forEach((dv) => {
                    if (dv.status === "In progress") {
                      this.activeVFEs[pillCounter].status = "WARNING";
                      this.activeVFEs[pillCounter].isActive = true;
                    }
                  });
                }
                pillCounter++;
              }
              // END CONTRACT STEP

              // EVIDENCE
              // check if there are evidence types
              if (
                this.investorData.evidenceTypes &&
                this.investorData.evidenceTypes.length
              ) {
                let totalVerifications =
                  this.investorData.originalBEEvidenceTypes.length;
                let completedVerifications = 0;
                // if there is evidence add the evidence pill
                this.activeVFEs.push({
                  id: "evidenceTypes",
                  isAutomaticPill: false,
                  displayPill: true,
                  title: this.translate.instant("review.pcs.title3"),
                  type: "evidenceTypes",
                  subTitle: this.translate.instant("review.pcs.subtitle3", {
                    total: totalVerifications,
                    completed: completedVerifications,
                  }),
                  isActive: true,
                  rejected: false,
                  substeps: [],
                  status: "PENDING",
                  clickAction: "reviewEvidenceTypes",
                });
                if (this.isDvReviewState(this.investorData.evidenceTypes)) {
                  if (!activeStepSet) {
                    this.setActivePill(pillCounter);
                    activeStepSet = true;
                  }
                  for (
                    let i = 0;
                    i < this.investorData.originalBEEvidenceTypes.length;
                    i++
                  ) {
                    if (
                      this.investorData.originalBEEvidenceTypes[i]
                        .reviewState === false
                    ) {
                      this.activeVFEs[pillCounter].status = "STARTED";
                      completedVerifications = completedVerifications + 1;
                      this.activeVFEs[pillCounter].subTitle =
                        this.translate.instant("review.pcs.subtitle3", {
                          total: totalVerifications,
                          completed: completedVerifications,
                        });
                    }
                  }
                } else {
                  // mark it as completed
                  this.activeVFEs[pillCounter].status = "COMPLETED";
                  totalVerifications =
                    this.investorData.originalBEEvidenceTypes.length;
                  completedVerifications =
                    this.investorData.originalBEEvidenceTypes.length;
                  this.activeVFEs[pillCounter].subTitle =
                    this.translate.instant("review.pcs.subtitle3", {
                      total: totalVerifications,
                      completed: completedVerifications,
                    });
                }
                pillCounter++;
              }
              // END EVIDENCE
              // RELATED PARTIES
              if (
                this.investorData.relatedParties &&
                this.investorData.relatedParties.length
              ) {
                this.activeVFEs.push({
                  id: "relatedParties",
                  isAutomaticPill: false,
                  displayPill: true,
                  title: this.translate.instant("review.pcs.title4"),
                  type: "relatedParties",
                  subTitle: this.translate.instant("review.pcs.subtitle4"),
                  isActive: true,
                  rejected: false,
                  substeps: [],
                  status: "PENDING",
                  clickAction: "reviewRelatedParties",
                });
                if (this.isRPReviewState(this.investorData.relatedParties)) {
                  if (!activeStepSet) {
                    this.setActivePill(pillCounter);
                    activeStepSet = true;
                  }
                } else {
                  // mark it as completed
                  this.activeVFEs[pillCounter].status = "COMPLETED";
                }

                this.investorData.relatedParties.forEach((rp) => {
                  // if at least one RP basic fields have been reviwed we will change the status to start
                  if (
                    rp.record &&
                    rp.record.basicFieldsEvidence &&
                    !rp.record.basicFieldsEvidence.reviewState
                  ) {
                    this.activeVFEs[pillCounter].status = "STARTED";
                  }
                });

                this.investorData.relatedParties.forEach((rp) => {
                  // check the DV
                  if (rp.record.digitalVerifications) {
                    for (const dv of rp.record.digitalVerifications) {
                      if (dv.reviewState === false) {
                        this.activeVFEs[pillCounter].status = "WARNING";
                      }
                    }
                  }
                });
              }
              pillCounter++;
              // END RELATED PARTIES

              this.showPCSScreen = true;
              this.pills = this.generatePills();
              this.generateContent();
            }
          },
        );
      this.reviewWindowClosed =
        this.idVerificationService.detectReviewWindowClosed.subscribe(
          (status) => {
            if (status === "resetData") {
              // resets the data from the BE
              const currentRoute = this.router.url;
              this.router
                .navigateByUrl("/verifications", { skipLocationChange: true })
                .then(() => {
                  this.router.navigate([currentRoute]); // navigate to same route
                });
            } else {
              // resets the data internally
              this.idVerificationService.parseElementsForReview(
                this.idVerificationService.getActiveProject(),
                this.idVerificationService.getActiveInvestor(),
                this.idVerificationService.getActiveInvestorDetaisl()
                  .relatedParties,
              );
            }
          },
        );
    }
  }

  isDvReviewState(digitalVerificaitons) {
    let ret = false;
    digitalVerificaitons.forEach((digitalVerification) => {
      if (digitalVerification.reviewState) {
        ret = true;
      }
    });
    return ret;
  }

  isRPReviewState(relatedParties) {
    let ret = false;
    relatedParties.forEach((relatedParty) => {
      // check the basic fields review state
      if (relatedParty.record.basicFieldsEvidence) {
        if (relatedParty.record.basicFieldsEvidence.reviewState) {
          ret = true;
        }
      }
      // check the digital verificaitons review state
      if (
        relatedParty.record.digitalVerifications &&
        relatedParty.record.digitalVerifications.length
      ) {
        relatedParty.record.digitalVerifications.forEach(
          (digitalVerification) => {
            if (digitalVerification.reviewState) {
              ret = true;
            }
          },
        );
      }
      // check the evidence review state
      if (
        relatedParty.record.evidenceTypes &&
        relatedParty.record.evidenceTypes.length
      ) {
        relatedParty.record.evidenceTypes.forEach((evidenceType) => {
          if (evidenceType.reviewState) {
            ret = true;
          }
        });
      }
    });
    return ret;
  }

  ngOnDestroy(): void {
    if (this.displayInvestorReviewStatus) {
      this.displayInvestorReviewStatus.unsubscribe();
    }
    if (this.reviewWindowClosed) {
      this.reviewWindowClosed.unsubscribe();
    }
  }

  reviewBasicFields() {
    this.pcsReviewFunction.emit("basicFields");
  }

  reviewDigitalVerifications() {
    this.pcsReviewFunction.emit("digitalVerifications");
  }

  reviewDigitalVerificationsContract() {
    this.pcsReviewFunction.emit("digitalVerificationsContract");
  }

  reviewDigitalVerificationsIdentityInstantAI() {
    this.pcsReviewFunction.emit("digitalVerificationsIdentityGo");
  }

  reviewRelatedParties() {
    this.pcsReviewFunction.emit("relatedParties");
  }

  reviewEvidenceTypes() {
    this.pcsReviewFunction.emit("evidenceTypes");
  }

  // function to parse the data and decide what active pill are we on
  decideActivePill(data) {
    if (data.record.isAutomaticRequest) {
      this.setActivePill(0);
    } else {
      this.setActivePill(1);
    }
  }

  setActivePill(index) {
    this.activeVFEs[index]["isActive"] = true;
    this.activePill = this.activeVFEs[index];
  }

  /**
   * check if the current pill should be made active
   * @param vfe - details about the vfe we are checking
   * @returns boolean
   */
  checkIsPillActive(pill) {
    if (pill && this.activePill) {
      return pill.id === this.activePill.id;
    }
  }

  /**
   * generate the content for the active (configured) pills/steps
   * @returns array of content elements for the active pills/steps
   */
  generatePills() {
    if (this.activeVFEs.length === 0) {
      return [];
    }

    // const pills = this.activeVFEs.map(pill => {
    //   pill.isActive = this.checkIsPillActive(pill);
    //   return pill;
    // });
    return this.activeVFEs;
  }

  /**
   * return only pills that should be displayed
   * @returns visible pills
   */
  visiblePills() {
    return this.pills.filter((pill) => pill.displayPill);
  }

  /**
   * Generates the content of the Process Control Screen
   * i.e. displays the list of pills and their corresponding statuses
   */
  generateContent() {
    let title = this.translate.instant("ARPCS.title2");
    let message = this.translate.instant("ARPCS.content");
    let imgClass = "d-none";
    let tooltipMessage = this.translate.instant("ARPCS.tooltip");
    if (this.fullyCompleted) {
      title = this.translate.instant("ARPCS.title3");
      message = this.translate.instant("ARPCS.content2");
      imgClass = "";
      this.verificationHeading = this.translate.instant("common.thankYou");
      tooltipMessage = "";
    }

    const res: any = {
      section_class: "results d-flex flex-column justify-items-center",
      group_name: "",
      result_container_class: "result-container my-2",
      is_collapsable: false,
      result_details: [
        {
          elements: [
            {
              type: "image",
              params: {
                src: "/assets/images/request_screening.svg",
                class: imgClass,
              },
            },
            {
              type: "title",
              class: "text-left",
              params: {
                content: title,
                tooltipMessage: tooltipMessage,
              },
            },
            {
              type: "paragraph",
              paragraphClass: "text-left",
              params: {
                content: message,
              },
            },
            {
              type: "stacked_pill_choices",
              params: {
                pills: this.visiblePills(),
              },
            },
          ],
        },
      ],
    };
    if (
      this.activePill &&
      !this.fullyCompleted &&
      this.activePill.substeps.length === 0
    ) {
      res.result_details[0]["elements"].push({
        type: "button_group",
        displayFlex: true,
        alignRight: true,
        params: [
          {
            content: this.translate.instant("common.start"),
            action:
              this.activePill && this.activePill.clickAction
                ? this.activePill.clickAction
                : "",
            display:
              this.activePill &&
              !this.fullyCompleted &&
              this.activePill.substeps.length === 0,
          },
        ],
      });
    }

    // this.contentDom.push(res);
    this.contentDom[0] = res;
  }

  /**
   * call this method on click of a Content Builder button (not a submit)
   * @param returnedAction event
   */
  parseContentReturn(returnedAction) {
    if (
      typeof returnedAction.method === "string" &&
      returnedAction.method !== ""
    ) {
      // allow only strings as acceptable method name
      let params = "";
      // if (Array.isArray(returnedAction.params)) {
      params = returnedAction.params;
      // }
      try {
        this[returnedAction.method](params); // call it
      } catch (error) {
        console.log(returnedAction.method, "is not declared as method!", error);
      }
    } else {
      console.log("**method name not string or empty string");
    }
  }

  /**
   * Generates the content of the Process Control Screen
   * i.e. displays the list of pills and their corresponding statuses
   */
  startBasicinformation() {
    this.basicInformationWindow = true;
    let title = this.translate.instant("ARPCS.title4");
    let message = this.translate.instant("ARPCS.content3-2");
    let imgClass = "d-none";
    if (this.fullyCompleted) {
      title = this.translate.instant("ARPCS.title3");
      message = this.translate.instant("ARPCS.content2");
      imgClass = "";
      this.verificationHeading = this.translate.instant("common.thankYou");
    }
    const res: any = {
      section_class: "results d-flex flex-column justify-items-center",
      group_name: "",
      result_container_class: "result-container my-2",
      is_collapsable: false,
      result_details: [
        {
          elements: [
            {
              type: "image",
              params: {
                src: "/assets/images/request_screening.svg",
                class: imgClass,
              },
            },
            {
              type: "selectEntityType",
              params: {
                record: this.record,
                legalEntityTypes: this.legalPersonTypeList,
                legalEntityRPTypes: this.relatedLegalPersonTypeList,
                legalEntityTypesOptionSelected: "emptySelect",
                relatedPartyRoles: this.relatedPartyRoles,
                relatedPartyRoleSelected: "emptySelect",
                submitMethod: "openConfirmationDialogue",
                openingTitle: title,
                notAllowedLegalTypes: this.notAllowedLegalTypes,
              },
            },
          ],
        },
      ],
    };
    this.verificationHeading = this.translate.instant("ARPCS.title8");
    this.contentDom[0] = res;
  }

  hideGeneralError() {
    $(".button-error-field ").removeClass("d-none");
  }

  startReviewProcess() {
    this.router.navigate([`investor/dashboard/${this.projectkey}/`]);
  }

  openConfirmationDialogue(params) {
    // validation check
    if (!$(".type-of-profile-link.active").length) {
      return;
    }
    this.paramArguments = params;
    this.finishBasicinformation(this.paramArguments);
    this.confirmationLoading = true;
  }

  finishBasicinformation(params) {
    // make the RP array calls first
    const rpArray = params.additionalParam.relatedProfiles;
    if (rpArray !== undefined) {
      let arrayOfRPRequests;
      arrayOfRPRequests = [];
      rpArray.forEach((relatedParty) => {
        // create the payload for each RP
        let payload;
        const rpType = relatedParty.type;
        if (rpType === "natural") {
          payload = {
            typeOfInvestor: "NaturalPerson",
            relatedPartyRole: relatedParty.naturalRole,
            investorFields: {
              FirstName: relatedParty.naturalFirstName,
              LastName: relatedParty.naturalLastName,
            },
          };
          if (relatedParty.isMinor) {
            payload.investorFields.isMinor = true;
          }
        } else {
          payload = {
            typeOfInvestor: "LegalPerson",
            relatedPartyRole: relatedParty.legalRole,
            investorFields: {
              Type: relatedParty.legalType,
              RegisteredName: relatedParty.legalName,
            },
          };
        }
        arrayOfRPRequests.push(
          this.userService.postEndPoint(
            this.idVerificationService.buildEndPoint(
              "pcsRPCreate",
              this.projectkey,
              this.shareKey,
            ),
            payload,
            { responseType: "json" },
            { observe: "body" },
            "funds",
          ),
        );
      });
      concat(...arrayOfRPRequests)
        .pipe(toArray())
        .subscribe(
          (responses) => {
            // go to the information request call
            this.createIRCall(params);
          },
          (error) => {
            this.confirmationLoading = false;
            this.confirmationError = true;
          },
        );
    } else {
      // if no related parties directly go to the information request call
      this.createIRCall(params);
    }
  }

  createIRCall(params) {
    let payload;
    const irType = params.additionalParam.type;
    const payloadInvestorFields = this.requestCallResponse.investorFields;
    if (irType === "natural") {
      payloadInvestorFields.FirstName = params.additionalParam.naturalFirstName;
      payloadInvestorFields.LastName = params.additionalParam.naturalLastName;

      payload = {
        typeOfInvestor: "NaturalPerson",
        investorFields: payloadInvestorFields,
      };
      if (params.additionalParam.isMinor) {
        payload.investorFields.isMinor = true;
      }
    } else {
      payloadInvestorFields.Type = params.additionalParam.legalType;
      payloadInvestorFields.RegisteredName = params.additionalParam.legalName;
      if (payloadInvestorFields.EmailAddress) {
        delete payloadInvestorFields.EmailAddress;
      }
      payload = {
        typeOfInvestor: "LegalPerson",
        investorFields: payloadInvestorFields,
      };
    }
    payload.fieldsSource = [];
    if (
      irType !== "natural" &&
      this.idVerificationService.localFundCreationData &&
      this.idVerificationService.localFundCreationData.fetchedCompanyListDeatils
    ) {
      const irFType = payload.investorFields.Type.slice();
      payload.investorFields =
        this.idVerificationService.localFundCreationData.investorFields;
      payload.investorFields.Type = irFType;
      // adds in the fieldSource from the company search
      payload.fieldsSource = [
        this.idVerificationService.localFundCreationData.fieldsSource,
      ];
    }
    // need to add in the fieldSource from the SP
    if (this.requestCallResponse.fieldsSource) {
      // if fieldsSource exists :
      if (!payload.fieldsSource) {
        payload.fieldsSource = this.requestCallResponse.fieldsSource;
      }
    }
    this.userService
      .postEndPoint(
        this.idVerificationService.buildEndPoint(
          "pcsIRCreate",
          this.projectkey,
          this.shareKey,
        ),
        payload,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            this.confirmationLoading = false;
            this.confirmationError = true;
            return;
          }
          this.confirmationLoading = false;
          this.activeVFEs[0].status = this.translate.instant("common.start");
          this.setActivePill(1);
          this.pills = this.generatePills();
          this.generateContent();
          this.verificationHeading = this.translate.instant("ARPCS.title5");
          this.router.navigate([`investor/dashboard/${this.projectkey}/`]);
        },
        (error) => {
          this.confirmationLoading = false;
          this.confirmationError = true;
        },
      );
  }

  // helper function to parse the BE response into usable arrays
  parseTypes(types) {
    let returnArray;
    returnArray = [];
    // if there's more than one type don't show the legal person that is just the placeholder / default option
    if (types.length > 1) {
      types = types.filter(function (type) {
        return type !== "LegalPerson";
      });
    }
    types.forEach((type) => {
      returnArray.push({
        key: type,
        name: this.helperService.translateLegalPersonType(type),
      });
    });
    returnArray.unshift({
      key: "emptySelect",
      name: "",
    });
    return returnArray;
  }
}
