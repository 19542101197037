<div
  [ngStyle]="{
    'background-color':
      lightboxClass && lightboxClass.projectColor
        ? lightboxClass.projectColor
        : ''
  }"
  class="form-lightbox {{ lightboxClass ? lightboxClass.class : '' }} {{
    contentDOM[0].lightbox_type ? contentDOM[0].lightbox_type : ''
  }} d-flex justify-content-center"
>
  <a
    href="#"
    class="close-form-button"
    [ngClass]="lightboxClass ? lightboxClass : ''"
    [attr.data-reloadfund]="
      lightboxClass && lightboxClass.reloadfund ? lightboxClass.reloadfund : ''
    "
    (click)="
      lightboxClass && lightboxClass.confirmDialogue
        ? OnCloseLightboxCheck($event)
        : OnCloseLightbox($event)
    "
  >
    <img
      id="lightBoxCloseImage"
      [attr.data-reloadfund]="
        lightboxClass && lightboxClass.reloadfund
          ? lightboxClass.reloadfund
          : ''
      "
      [src]="
        lightboxClass && lightboxClass.greyVersion
          ? 'assets/images/close_icon_teal.png'
          : 'assets/images/close_form_icon.png'
      "
      [attr.data-confirmDialogue]="
        lightboxClass && lightboxClass.confirmDialogue
          ? lightboxClass.confirmDialogue
          : ''
      "
    />
  </a>
  <a
    href="#"
    class="close-form-button close-form-button-hidden"
    [ngClass]="lightboxClass ? lightboxClass : ''"
    [attr.data-reloadfund]="
      lightboxClass && lightboxClass.reloadfund ? lightboxClass.reloadfund : ''
    "
    (click)="OnCloseLightbox($event)"
  >
    <img
      id="lightBoxCloseImage2"
      [attr.data-reloadfund]="
        lightboxClass && lightboxClass.reloadfund
          ? lightboxClass.reloadfund
          : ''
      "
      [src]="
        lightboxClass && lightboxClass.greyVersion
          ? 'assets/images/close_icon_teal.png'
          : 'assets/images/close_form_icon.png'
      "
      [attr.data-confirmDialogue]="
        lightboxClass && lightboxClass.confirmDialogue
          ? lightboxClass.confirmDialogue
          : ''
      "
    />
  </a>
  <a
    href="#"
    class="close-form-button close-form-button-hidden"
    [ngClass]="lightboxClass ? lightboxClass : ''"
    [attr.data-reloadfund]="
      lightboxClass && lightboxClass.reloadfundAlternate
        ? lightboxClass.reloadfundAlternate
        : ''
    "
    (click)="OnCloseLightboxAlternate($event)"
  >
    <img
      id="lightBoxCloseImage3"
      [attr.data-reloadfund]="
        lightboxClass && lightboxClass.reloadfundAlternate
          ? lightboxClass.reloadfundAlternate
          : ''
      "
      [src]="
        lightboxClass && lightboxClass.greyVersion
          ? 'assets/images/close_icon_teal.png'
          : 'assets/images/close_form_icon.png'
      "
      [attr.data-confirmDialogue]="
        lightboxClass && lightboxClass.confirmDialogue
          ? lightboxClass.confirmDialogue
          : ''
      "
    />
  </a>
  <div *ngIf="lightboxClass && lightboxClass.showDefaultLogo">
    <img
      *ngIf="!lightboxClass.greyVersion"
      _ngcontent-ccd-c107=""
      src="assets/images/meshId_logo.svg"
      class="lightbox-class-mesh-logo"
    />
    <img
      *ngIf="lightboxClass.greyVersion"
      _ngcontent-ccd-c107=""
      src="assets/images/logo-alternate.svg"
      class="lightbox-class-mesh-logo"
    />
  </div>
  <div
    *ngIf="lightboxClass && !lightboxClass.showStepsMobile"
    [ngClass]="lightboxClass.marginclass"
    class="form-steps-container"
  >
    <div
      *ngIf="lightboxClass && lightboxClass.logoImage"
      class="lightbox-class-logo-img"
      [ngClass]="lightboxClass.logoClass"
    >
      <img [src]="lightboxClass.logoImage" />
    </div>
    <div
      class="form-steps-title"
      [ngClass]="[
        lightboxClass.projectTextColorClass
          ? lightboxClass.projectTextColorClass
          : '',
        lightboxClass.stepsTitleClass ? lightboxClass.stepsTitleClass : ''
      ]"
    >
      {{ lightboxClass.stepsTitle }}
    </div>
    <div class="form-steps-subtitle">{{ lightboxClass.stepsSubtitle }}</div>
    <div class="form-steps-wrapper">
      <div
        *ngFor="let step of lightboxClass.steps"
        class="form-step {{ step.class }}"
      >
        <div (click)="(step.click)" class="form-step-order">
          {{ step.count }}
        </div>
        <div
          [attr.clickMethod]="step.clickMethod"
          [attr.count]="step.count"
          [attr.type]="step.name"
          (click)="clickSubstep($event)"
          class="form-step-title"
          [ngClass]="lightboxClass.projectTextColorClass"
        >
          {{ step.name }}
        </div>
        <div class="substeps" *ngIf="step.substeps">
          <div
            *ngFor="let substep of step.substeps"
            class="substep position-relative"
            [attr.clickMethod]="substep.clickMethod"
            [attr.count]="substep.count"
            [attr.type]="substep.name"
            (click)="clickSubstep($event)"
            [ngClass]="{
              'substep-active': substep.active,
              clickable: substep.clickMethod
            }"
          >
            <div class="substep-count"></div>
            <div
              class="{{ !substep.showWarning ? 'd-none' : '' }} substep-warning"
            >
              <img src="/assets/images/icon-triangle-orange.svg" />
            </div>
            <div class="substep-name unselectable button-disabled">
              {{ substep.label }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <form class="form-wrapper">
    <div
      class="form-container {{ formClass ? formClass : '' }}"
      [ngClass]="{
        formClass: formClass !== undefined,
        'form-container-mobile': lightboxClass && lightboxClass.showStepsMobile
      }"
    >
      <div
        [ngClass]="
          lightboxClass && lightboxClass.loaderClass
            ? lightboxClass.loaderClass
            : ''
        "
        class="d-flex form-loading-screen"
        *ngIf="displayLoader"
      >
        <div class="d-flex justify-content-center align-self-center mx-auto">
          <img
            src="/assets/images/spinner-green.svg"
            width="66px"
            height="66px"
            alt="loading"
          />
        </div>
      </div>
      <a
        href="#"
        class="close-form-button"
        [ngClass]="lightboxClass ? lightboxClass['closeFormClass'] : ''"
        (click)="OnCloseLightbox($event)"
      >
        <img src="assets/images/close_form_icon.png" />
      </a>
      <h1
        class="form-type-heading"
        [ngClass]="formTypeAlignment === 'center' ? 'text-center' : ''"
        *ngIf="formType"
      >
        {{ formType }}
      </h1>
      <h2 class="form-title-heading" *ngIf="formTitle">
        <img src="assets/images/form_title_triangle.png" />
        {{ formTitle }}
      </h2>
      <!-- STEPS MOBILE -->
      <div
        *ngIf="lightboxClass && lightboxClass.showStepsMobile"
        class="form-steps-container-mobile"
      >
        <div class="form-steps-wrapper">
          <div
            *ngIf="lightboxClass && lightboxClass.logoImage"
            class="lightbox-class-logo-img-mobile"
          >
            <img [src]="lightboxClass.logoImage" />
          </div>
          <ng-container
            *ngFor="let step of lightboxClass.steps; let first = first"
          >
            <div
              (click)="toggleInactiveMobileSteps()"
              *ngIf="step.class === 'form-step-active'"
              class="form-step-main"
              [ngClass]="{
                'mobile-main-step-with-logo':
                  lightboxClass && lightboxClass.logoImage && first
              }"
            >
              <div (click)="(step.click)" class="form-step-order">
                {{ step.count }}
              </div>
              <div
                class="form-step-title"
                [ngClass]="lightboxClass.projectTextColorClass"
              >
                {{ step.name }}
              </div>
              <div class="arrow-down"></div>
            </div>
          </ng-container>
          <div
            class="steps-inactive d-none"
            [ngClass]="{
              'mobile-inactive-steps-with-logo':
                lightboxClass && lightboxClass.logoImage
            }"
          >
            <ng-container *ngFor="let step of lightboxClass.steps">
              <div
                *ngIf="step.class !== 'form-step-active'"
                class="form-step-main"
              >
                <div (click)="(step.click)" class="form-step-order">
                  {{ step.count }}
                </div>
                <div
                  class="form-step-title"
                  [ngClass]="lightboxClass.projectTextColorClass"
                >
                  {{ step.name }}
                </div>
                <div class="arrow-down"></div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="mobiledropdown" *ngIf="evidenceMobileDropdown">
        <div
          class="form-steps-container-mobile form-steps-container-mobile-alternate"
        >
          <div class="form-steps-wrapper">
            <ng-container *ngFor="let step of mobileSteps">
              <div
                *ngIf="step.active"
                (click)="toggleInactiveMobileSteps()"
                class="form-step-main"
              >
                <div
                  class="form-step-title"
                  [ngClass]="lightboxClass.projectTextColorClass"
                >
                  <div class="mainev-step-name">{{ step.name }}</div>
                  <div class="mainev-step-subname" style="padding-right: 15px">
                    {{ step.subName }}
                  </div>
                </div>
                <div class="arrow-down"></div>
              </div>
            </ng-container>
            <div class="steps-inactive d-none">
              <ng-container *ngFor="let step of mobileSteps">
                <div
                  [attr.evidenceKey]="step.evidenceKey"
                  (click)="clickEvidence($event)"
                  [ngClass]="{
                    disabled: step.disabled,
                    'basic-field': step.basicField
                  }"
                  class="form-step-main"
                >
                  <div
                    class="form-step-title"
                    [ngClass]="lightboxClass.projectTextColorClass"
                  >
                    <div class="sub-step-name">{{ step.name }}</div>
                    <div class="sub-step-subname">{{ step.subName }}</div>
                  </div>
                  <div class="arrow-down"></div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="form-content-container">
        <!-- SUBTITLE -->
        <h3 class="form-subtitle" *ngIf="formSubTitle">{{ formSubTitle }}</h3>
        <!-- CONTENT BUILDER -->
        <app-content-builder
          *ngIf="contentDOM"
          [contentDOM]="contentDOM"
          (contentReturn)="parseContentReturn($event)"
          (displayLoader)="OnDisplayLoader($event)"
        ></app-content-builder>

        <!-- BUTTONS  -->
        <div class="element-wrapper" *ngIf="btnOkText || btnCancelText">
          <button
            class="form-button form-button-1"
            (click)="OnConfirmLightbox($event)"
            [disabled]="btnOkEnabled"
            [attr.isValidator]="formValidator"
            *ngIf="btnOkText"
          >
            {{ btnOkText }}
          </button>
          <button
            [ngClass]="
              this.lightboxClass ? this.lightboxClass.cancelBtnClass : ''
            "
            class="form-button form-button-2"
            (click)="OnCloseLightbox($event)"
            [disabled]="btnCancelEnabled"
            *ngIf="btnCancelText"
          >
            {{ btnCancelText }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
