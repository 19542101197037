import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, Subject, throwError, of, ReplaySubject } from "rxjs";
import { environment } from "./../../environments/environment";
import { HelperServiceService } from "../helper-service.service";
import { UserService } from "../user.service";
import { IpServiceService } from "./../ip-service.service";
import { tap, switchMap } from "rxjs/operators";
import { listenToTriggers } from "ngx-bootstrap/utils";

@Injectable({
  providedIn: "root",
})
export class VerificationsService {
  private invitationKey: string;
  private projectLogo: string;
  private projectColor: string;
  private projectMessage: string;
  private projectTextColorClass: string;
  private baseUrl = environment.APIEndpoint; // 'https://virtserver.swaggerhub.com/SingleTruth/singletruth/1.0.4/';
  private myVerificationToken = new Subject<any>();
  private myVerificationConsent = new Subject<any>();
  private myAddressVerificationToken = new Subject<any>();
  private myAddressVerificationConsent = new Subject<any>();
  private myAddressVerificationConfirm = new Subject<any>();
  private myVerificationLogo = new Subject<any>();
  public detectMyVerificationLogo = this.myVerificationLogo.asObservable();
  private myInvitationDetails = new Subject<any>();
  private myInvitationConsent = new Subject<any>();
  private ipAddress: string = null;
  private geoIpAddress: any = null;
  private ipInfo: string = null;
  // public detectmyVerificationTokenStatus = this.myVerificationToken.asObservable();

  constructor(
    private http: HttpClient,
    private helperService: HelperServiceService,
    private userService: UserService,
    private ip: IpServiceService,
  ) {
    this.projectColor = null;
    this.projectTextColorClass = "dark-color";
    this.projectLogo = null;
    this.projectMessage = null;
    // this.ip.getIPAddress().pipe(
    //   tap(resIpAddress => {
    //       this.ipAddress = resIpAddress['ip'];
    //   }),
    //   switchMap(resIpAddress => this.ip.getGeoIpAddress(resIpAddress['ip'])),
    //   tap(resGeoIpInfo => {
    //       this.geoIpAddress = resGeoIpInfo;
    //   })
    // )
    // .subscribe(() => {
    //   this.ipInfo = JSON.stringify({
    //     ipAddress: this.ipAddress,
    //     ipGeoInfo: this.geoIpAddress
    //   });
    //   // console.log('ipInfo JSON:', this.ipInfo);
    // });
  }

  getIpInfo() {
    return this.ipInfo;
  }

  /**
   * set project color
   * @param projectColor: string, hex value for the color
   */
  setProjectColor(projectColor: string) {
    if (projectColor !== "") {
      if (this.helperService.hexValidator(projectColor)) {
        this.projectColor = projectColor;
      } else {
        this.projectColor = "#ffffff";
      }
      this.projectTextColorClass = this.helperService.detectTextColor(
        this.helperService.hexToRgb(this.projectColor),
      );
    }
  }

  /**
   * get project color
   */
  getProjectColor() {
    return this.projectColor;
  }

  /**
   * get project text color class
   */
  getProjectTextColorClass() {
    return this.projectTextColorClass;
  }

  /**
   * set project logo
   * @param projectLogo: string, base64 string value for the image
   */
  setProjectLogo(projectLogo: string) {
    if (projectLogo !== "") {
      this.projectLogo = projectLogo;
    }
  }
  /**
   * get project color
   */
  getProjectLogo() {
    return this.projectLogo;
  }

  /**
   * set project personalized verification message
   * @param projectMessage: string, base64 string value for the image
   */
  setProjectMessage(projectMessage: string) {
    if (projectMessage !== "") {
      this.projectMessage = projectMessage;
    }
  }
  /**
   * get project color
   */
  getProjectMessage() {
    return this.projectMessage;
  }

  /**
   * call BE to check if the user should be granted access to the page
   * Token special for ID-Verification
   */
  getVerificationToken(verificationKey: string) {
    if (typeof verificationKey !== "string" || verificationKey === "") {
      this.myVerificationToken.next(null);
      return this.myVerificationToken.asObservable();
    }
    this.http
      .get(this.baseUrl + "api/verifications/" + verificationKey + "/identity")
      .subscribe(
        (response) => {
          this.myVerificationToken.next(response);
        },
        (error) => {
          console.log("Error occured while fetching the access token", error);
          this.myVerificationToken.next(null);
          // this.myVerificationToken.next({
          //   "url": "654879879879",
          //   "expiresOn": "2019-09-27T08:06:14.964Z",
          //   "projectName": "Finos Org.",
          //   "projectColor": "red",
          // });
        },
      );
    return this.myVerificationToken.asObservable();
  }

  /**
   * call BE to check if the user should be granted access to the page
   * Token  for id verification LANDING (choosing what step )
   */
  getVerificationLandingToken(verificationKey: string) {
    if (typeof verificationKey !== "string" || verificationKey === "") {
      this.myVerificationToken.next(null);
      return this.myVerificationToken.asObservable();
    }
    this.http
      .get(this.baseUrl + "api/verifications/" + verificationKey)
      .subscribe(
        (response) => {
          this.myVerificationToken.next(response);
        },
        (error) => {
          console.log("Error occured while fetching the access token", error);
          this.myVerificationToken.next(null);
          // this.myVerificationToken.next({
          //   "url": "654879879879",
          //   "expiresOn": "2019-09-27T08:06:14.964Z",
          //   "projectName": "Finos Org.",
          //   "projectColor": "red",
          // });
        },
      );
    return this.myVerificationToken.asObservable();
  }

  /**
   * call BE to check if the user should be granted access to the page
   */
  getAddressVerificationToken(addressVerificationKey: string) {
    if (
      typeof addressVerificationKey !== "string" ||
      addressVerificationKey === ""
    ) {
      this.myAddressVerificationToken.next(null);
      return this.myAddressVerificationToken.asObservable();
    }
    this.http
      .get(
        this.baseUrl +
          "api/addressverifications/data/" +
          addressVerificationKey,
      )
      .subscribe(
        (response) => {
          this.myAddressVerificationToken.next(response);
        },
        (error) => {
          console.log("Error occured while fetching the access token", error);
          this.myAddressVerificationToken.next(null);
          // this.myVerificationToken.next({
          //   "url": "654879879879",
          //   "expiresOn": "2019-09-27T08:06:14.964Z",
          //   "projectName": "Finos Org.",
          //   "projectColor": "red",
          // });
        },
      );
    return this.myAddressVerificationToken.asObservable();
  }

  /**
   * call BE to get the iDIN link to which we need to redirect
   * @param verificationKey the verrification key for the verification
   */
  async getIdinRedirectLink(verificationKey: string) {
    return await this.http
      .post(
        this.baseUrl +
          "api/verifications/" +
          verificationKey +
          "/idin/initiate",
        {},
      )
      // .subscribe(response => {
      //   console.log('idin link', response);
      // });
      .toPromise();
  }

  async recordGeneralConsent(verificationKey: string) {
    if (!verificationKey) {
      console.log("Could not resolve consent!");
      return;
    }

    return await this.http
      .post(`${this.baseUrl}api/verifications/${verificationKey}/consent`, "")
      .toPromise();
  }

  /**
   * call BE to check if there is a logo associated with this verification
   */
  getVerificationLogo(verificationKey: string) {
    if (typeof verificationKey !== "string" || verificationKey === "") {
      this.myVerificationLogo.next(null);
    }

    this.http
      .get(this.baseUrl + "api/verifications/" + verificationKey + "/logo", {
        responseType: "blob",
      })
      .subscribe(
        async (response) => {
          if (response["type"] === "application/json") {
            this.myVerificationLogo.next(
              JSON.parse(await (response as Blob).text()).link,
            );
          } else {
            this.createImageFromBlob(response);
          }
        },
        (error) => {
          console.log("Error occured while fetching the project logo", error);
          this.myVerificationLogo.next(null);
          console.log("setting to null");
        },
      );
  }

  /**
   * set the verification logo extracted from verification details through an observable
   */
  setVerificationLogo(logo: string) {
    this.myVerificationLogo.next(logo);
  }

  /**
   * create base64 image string from blob
   * @param image Blob, response returned from BE
   */
  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        this.myVerificationLogo.next(reader.result);
      },
      false,
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  /**
   * call BE to record an explicit consent for a verification key
   */
  recordConsent(verificationKey: string) {
    if (typeof verificationKey !== "string" || verificationKey === "") {
      this.myVerificationConsent.next(null);
      return this.myVerificationConsent.asObservable();
    }
    this.http
      .post(
        this.baseUrl +
          "api/verifications/" +
          verificationKey +
          "/identity/consent",
        "",
      )
      .subscribe(
        (response) => {
          this.myVerificationConsent.next(true);
        },
        (error) => {
          console.log(
            "Error occured while writing explicit consent to BE",
            error,
          );
          this.myVerificationConsent.next(false);
        },
      );
    return this.myVerificationConsent.asObservable();
  }

  /**
   * call BE to record an explicit consent for a verification key
   */
  recordAddressVerificationConsent(addressVerificationKey: string) {
    if (
      typeof addressVerificationKey !== "string" ||
      addressVerificationKey === ""
    ) {
      this.myAddressVerificationConsent.next(null);
      return this.myAddressVerificationConsent.asObservable();
    }
    this.http
      .post(
        this.baseUrl +
          "api/addressverifications/data/" +
          addressVerificationKey +
          "/consent",
        "",
      )
      .subscribe(
        (response) => {
          this.myAddressVerificationConsent.next(true);
        },
        (error) => {
          console.log(
            "Error occured while writing explicit consent to BE",
            error,
          );
          this.myAddressVerificationConsent.next(false);
        },
      );
    return this.myAddressVerificationConsent.asObservable();
  }

  /**
   * Confirm Address Verification
   * @param addressVerificationKey - address verification key
   * @param verifiedAddress - empty value if address has already been provided or json encoded
   *                          string containing address, zip code, city and country information if address has not been provided by SP
   * @param position - GEO location information such as latitude, longitute, accuracy etc.
   */
  confirmAddressVerification(
    addressVerificationKey: string,
    verifiedAddress: string = "",
    position: any = null,
    distanceMatrixInfo: any = null,
  ) {
    if (
      typeof addressVerificationKey !== "string" ||
      addressVerificationKey === ""
    ) {
      this.myAddressVerificationConfirm.next(null);
      return this.myAddressVerificationConfirm.asObservable();
    }

    // build possible params:
    const params = {};
    if (verifiedAddress !== "") {
      params["verifiedAddress"] = verifiedAddress;
    }
    if (position) {
      params["latitude"] = position.coords.latitude;
      params["longitude"] = position.coords.longitude;
      params["accuracy"] = position.coords.accuracy;
    }

    if (this.ipInfo) {
      params["ipInfo"] = this.ipInfo;
    }

    console.log("ipADDRESSInfo", this.ipInfo);
    // return;
    if (this.ipAddress) {
      params["ipAddress"] = this.ipAddress;
    }
    if (distanceMatrixInfo) {
      params["distanceMatrixInfo"] = distanceMatrixInfo;
    }

    this.http
      .post(
        this.baseUrl +
          "api/addressverifications/data/" +
          addressVerificationKey +
          "/confirm",
        params,
      )
      .subscribe(
        (response) => {
          this.myAddressVerificationConfirm.next(true);
        },
        (error) => {
          console.log(
            "Error occured while writing the confirmation of address to BE",
            error,
          );
          this.myAddressVerificationConfirm.next(false);
        },
      );
    return this.myAddressVerificationConfirm.asObservable();
  }

  /**
   * set active invitationKey
   * @param invitationKey string
   */
  setInvitationKey(invitationKey: string) {
    this.invitationKey = invitationKey;
  }

  /**
   *
   * @param invitationKey string, invitation key
   */
  getInvitationDetails() {
    const invitationKey = this.invitationKey;
    console.log("invitation id:", invitationKey);
    if (invitationKey === "") {
      this.myInvitationDetails.next(null);
      return this.myInvitationDetails.asObservable();
    }
    this.userService
      .getEndPoint(
        this.baseUrl + "api/invitations/" + invitationKey + "/details",
        { responseType: "json" },
        { observe: "body" },
      )
      .subscribe(
        (response) => {
          console.log("response", response);
          this.myInvitationDetails.next(response);
        },
        (error) => {
          console.log("Error occured while fetching the access token", error);
          this.myInvitationDetails.next(null);
          return this.myInvitationDetails.asObservable();
        },
      );
    return this.myInvitationDetails.asObservable();
  }

  /**
   * call BE to record an explicit consent for a verification key
   */
  recordContributorConsent() {
    const invitationKey = this.invitationKey;
    if (invitationKey === "") {
      this.myInvitationConsent.next(false);
      return this.myInvitationConsent.asObservable();
    }
    this.userService
      .postEndPoint(
        this.baseUrl + "api/invitations/" + invitationKey + "/join",
        "",
        { responseType: "json" },
        { observe: "body" },
      )
      .subscribe(
        () => {
          this.myInvitationConsent.next(true);
        },
        (error) => {
          console.log(
            "Error occured while writing explicit consent to BE",
            error,
          );
          this.myInvitationConsent.next(false);
        },
      );
    return this.myInvitationConsent.asObservable();
  }
}
