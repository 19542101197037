<div>
  <header
    class="d-flex flex-column p-2 p-sm-3 {{
      headerClasses['backgroundClass']
    }} {{ headerClasses['sectionClass'] }}"
    [ngStyle]="{ 'background-color': projectColor }"
    *ngIf="!resolved || !consent"
  >
    <div class="header-top d-flex justify-content-between mx-auto py-3">
      <div class="navbar mx-auto p-0">
        <a style="pointer-events: none" class="navbar-brand mr-0"
          ><img
            class="lightbox-class-logo-img"
            *ngIf="logo !== null"
            [src]="logo"
        /></a>
      </div>
    </div>
    <div
      *ngIf="!configCompleted && !displayLoader"
      class="d-flex flex-column flex-fill justify-content-center"
    >
      <div
        class="unauth-message text-center mx-auto"
        [ngClass]="projectTextColorClass"
        *ngIf="message !== ''"
        innerHtml="{{ message }}"
      ></div>
      <div
        class="unauth-message-large text-center mx-auto"
        [ngClass]="projectTextColorClass"
        *ngIf="
          message === '' &&
          iosUnsupported &&
          !isUnsupportedBrowser &&
          !androidUnsupported
        "
      >
        {{ "IDPCS.idVerification.validation" | translate }}
      </div>
      <div
        class="unauth-message-large text-center mx-auto"
        [ngClass]="projectTextColorClass"
        *ngIf="message === '' && isUnsupportedBrowser && !androidUnsupported"
      >
        {{ "IDPCS.idVerification.validation2" | translate }}
      </div>
      <div
        class="unauth-message-large text-center mx-auto"
        [ngClass]="projectTextColorClass"
        *ngIf="message === '' && androidUnsupported"
      >
        {{ "IDPCS.idVerification.validation3" | translate }}
      </div>
    </div>
    <div
      class="load-overlay d-flex position-fixed w-100 h-100"
      *ngIf="displayLoader"
    >
      <img
        src="/assets/images/spinner.svg"
        width="66px"
        height="66px"
        class="my-auto mx-auto"
      />
    </div>
    <div
      class="iframe-wrapper iframe-verifications d-flex flex-column"
      *ngIf="
        iframeSrc &&
        !iosUnsupported &&
        !isUnsupportedBrowser &&
        !androidUnsupported
      "
    >
      <iframe
        [src]="iframeSrc"
        allow="camera;accelerometer;gyroscope;magnetometer;fullscreen"
        allowfullscreen
        class="mx-auto tmpiframe"
      ></iframe>
    </div>
    <button
      (click)="goBackButton()"
      *ngIf="showBackButton"
      class="form-button form-button-arrow back-button"
    >
      {{ backbuttonText }}
    </button>
  </header>
</div>
