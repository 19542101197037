import { HelperServiceService } from "../helper-service.service";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

export const emailPattern =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const country_list_AV = [
  {
    value: "AF",
    label: "Afghanistan",
  },
  {
    value: "AX",
    label: "Åland Islands",
  },
  {
    value: "AL",
    label: "Albania",
  },
  {
    value: "DZ",
    label: "Algeria",
  },
  {
    value: "AS",
    label: "American Samoa",
  },
  {
    value: "AD",
    label: "Andorra",
  },
  {
    value: "AO",
    label: "Angola",
  },
  {
    value: "AI",
    label: "Anguilla",
  },
  {
    value: "AQ",
    label: "Antarctica",
  },
  {
    value: "AG",
    label: "Antigua and Barbuda",
  },
  {
    value: "AR",
    label: "Argentina",
  },
  {
    value: "AM",
    label: "Armenia",
  },
  {
    value: "AW",
    label: "Aruba",
  },
  {
    value: "AU",
    label: "Australia",
  },
  {
    value: "AT",
    label: "Austria",
  },
  {
    value: "AZ",
    label: "Azerbaijan",
  },
  {
    value: "BS",
    label: "Bahamas",
  },
  {
    value: "BH",
    label: "Bahrain",
  },
  {
    value: "BD",
    label: "Bangladesh",
  },
  {
    value: "BB",
    label: "Barbados",
  },
  {
    value: "BY",
    label: "Belarus",
  },
  {
    value: "BE",
    label: "Belgium",
  },
  {
    value: "BZ",
    label: "Belize",
  },
  {
    value: "BJ",
    label: "Benin",
  },
  {
    value: "BM",
    label: "Bermuda",
  },
  {
    value: "BT",
    label: "Bhutan",
  },
  {
    value: "BO",
    label: "Bolivia (Plurinational State of)",
  },
  {
    value: "BQ",
    label: "Bonaire, Sint Eustatius and Saba",
  },
  {
    value: "BA",
    label: "Bosnia and Herzegovina",
  },
  {
    value: "BW",
    label: "Botswana",
  },
  {
    value: "BV",
    label: "Bouvet Island",
  },
  {
    value: "BR",
    label: "Brazil",
  },
  {
    value: "IO",
    label: "British Indian Ocean Territory",
  },
  {
    value: "BN",
    label: "Brunei Darussalam",
  },
  {
    value: "BG",
    label: "Bulgaria",
  },
  {
    value: "BF",
    label: "Burkina Faso",
  },
  {
    value: "BI",
    label: "Burundi",
  },
  {
    value: "CV",
    label: "Cabo Verde",
  },
  {
    value: "KH",
    label: "Cambodia",
  },
  {
    value: "CM",
    label: "Cameroon",
  },
  {
    value: "CA",
    label: "Canada",
  },
  {
    value: "KY",
    label: "Cayman Islands",
  },
  {
    value: "CF",
    label: "Central African Republic",
  },
  {
    value: "TD",
    label: "Chad",
  },
  {
    value: "CL",
    label: "Chile",
  },
  {
    value: "CN",
    label: "China",
  },
  {
    value: "CX",
    label: "Christmas Island",
  },
  {
    value: "CC",
    label: "Cocos (Keeling) Islands",
  },
  {
    value: "CO",
    label: "Colombia",
  },
  {
    value: "KM",
    label: "Comoros",
  },
  {
    value: "CG",
    label: "Congo",
  },
  {
    value: "CD",
    label: "Congo, Democratic Republic of the",
  },
  {
    value: "CK",
    label: "Cook Islands",
  },
  {
    value: "CR",
    label: "Costa Rica",
  },
  {
    value: "CI",
    label: "Côte d'Ivoire",
  },
  {
    value: "HR",
    label: "Croatia",
  },
  {
    value: "CU",
    label: "Cuba",
  },
  {
    value: "CW",
    label: "Curaçao",
  },
  {
    value: "CY",
    label: "Cyprus",
  },
  {
    value: "CZ",
    label: "Czech_Republic_(Czechia)",
  },
  {
    value: "DK",
    label: "Denmark",
  },
  {
    value: "DJ",
    label: "Djibouti",
  },
  {
    value: "DM",
    label: "Dominica",
  },
  {
    value: "DO",
    label: "Dominican Republic",
  },
  {
    value: "EC",
    label: "Ecuador",
  },
  {
    value: "EG",
    label: "Egypt",
  },
  {
    value: "SV",
    label: "El Salvador",
  },
  {
    value: "GQ",
    label: "Equatorial Guinea",
  },
  {
    value: "ER",
    label: "Eritrea",
  },
  {
    value: "EE",
    label: "Estonia",
  },
  {
    value: "SZ",
    label: "Eswatini",
  },
  {
    value: "ET",
    label: "Ethiopia",
  },
  {
    value: "FK",
    label: "Falkland Islands (Malvinas)",
  },
  {
    value: "FO",
    label: "Faroe Islands",
  },
  {
    value: "FJ",
    label: "Fiji",
  },
  {
    value: "FI",
    label: "Finland",
  },
  {
    value: "FR",
    label: "France",
  },
  {
    value: "GF",
    label: "French Guiana",
  },
  {
    value: "PF",
    label: "French Polynesia",
  },
  {
    value: "TF",
    label: "French Southern Territories",
  },
  {
    value: "GA",
    label: "Gabon",
  },
  {
    value: "GM",
    label: "Gambia",
  },
  {
    value: "GE",
    label: "Georgia",
  },
  {
    value: "DE",
    label: "Germany",
  },
  {
    value: "GH",
    label: "Ghana",
  },
  {
    value: "GI",
    label: "Gibraltar",
  },
  {
    value: "GR",
    label: "Greece",
  },
  {
    value: "GL",
    label: "Greenland",
  },
  {
    value: "GD",
    label: "Grenada",
  },
  {
    value: "GP",
    label: "Guadeloupe",
  },
  {
    value: "GU",
    label: "Guam",
  },
  {
    value: "GT",
    label: "Guatemala",
  },
  {
    value: "GG",
    label: "Guernsey",
  },
  {
    value: "GN",
    label: "Guinea",
  },
  {
    value: "GW",
    label: "Guinea-Bissau",
  },
  {
    value: "GY",
    label: "Guyana",
  },
  {
    value: "HT",
    label: "Haiti",
  },
  {
    value: "HM",
    label: "Heard Island and McDonald Islands",
  },
  {
    value: "VA",
    label: "Holy See",
  },
  {
    value: "HN",
    label: "Honduras",
  },
  {
    value: "HK",
    label: "Hong Kong",
  },
  {
    value: "HU",
    label: "Hungary",
  },
  {
    value: "IS",
    label: "Iceland",
  },
  {
    value: "IN",
    label: "India",
  },
  {
    value: "ID",
    label: "Indonesia",
  },
  {
    value: "IR",
    label: "Iran (Islamic Republic of)",
  },
  {
    value: "IQ",
    label: "Iraq",
  },
  {
    value: "IE",
    label: "Ireland",
  },
  {
    value: "IM",
    label: "Isle of Man",
  },
  {
    value: "IL",
    label: "Israel",
  },
  {
    value: "IT",
    label: "Italy",
  },
  {
    value: "JM",
    label: "Jamaica",
  },
  {
    value: "JP",
    label: "Japan",
  },
  {
    value: "JE",
    label: "Jersey",
  },
  {
    value: "JO",
    label: "Jordan",
  },
  {
    value: "KZ",
    label: "Kazakhstan",
  },
  {
    value: "KE",
    label: "Kenya",
  },
  {
    value: "KI",
    label: "Kiribati",
  },
  {
    value: "KP",
    label: "Korea (Democratic People's Republic of)",
  },
  {
    value: "KR",
    label: "Korea, Republic of",
  },
  {
    value: "KW",
    label: "Kuwait",
  },
  {
    value: "KG",
    label: "Kyrgyzstan",
  },
  {
    value: "LA",
    label: "Lao People's Democratic Republic",
  },
  {
    value: "LV",
    label: "Latvia",
  },
  {
    value: "LB",
    label: "Lebanon",
  },
  {
    value: "LS",
    label: "Lesotho",
  },
  {
    value: "LR",
    label: "Liberia",
  },
  {
    value: "LY",
    label: "Libya",
  },
  {
    value: "LI",
    label: "Liechtenstein",
  },
  {
    value: "LT",
    label: "Lithuania",
  },
  {
    value: "LU",
    label: "Luxembourg",
  },
  {
    value: "MO",
    label: "Macao",
  },
  {
    value: "MG",
    label: "Madagascar",
  },
  {
    value: "MW",
    label: "Malawi",
  },
  {
    value: "MY",
    label: "Malaysia",
  },
  {
    value: "MV",
    label: "Maldives",
  },
  {
    value: "ML",
    label: "Mali",
  },
  {
    value: "MT",
    label: "Malta",
  },
  {
    value: "MH",
    label: "Marshall Islands",
  },
  {
    value: "MQ",
    label: "Martinique",
  },
  {
    value: "MR",
    label: "Mauritania",
  },
  {
    value: "MU",
    label: "Mauritius",
  },
  {
    value: "YT",
    label: "Mayotte",
  },
  {
    value: "MX",
    label: "Mexico",
  },
  {
    value: "FM",
    label: "Micronesia (Federated States of)",
  },
  {
    value: "MD",
    label: "Moldova, Republic of",
  },
  {
    value: "MC",
    label: "Monaco",
  },
  {
    value: "MN",
    label: "Mongolia",
  },
  {
    value: "ME",
    label: "Montenegro",
  },
  {
    value: "MS",
    label: "Montserrat",
  },
  {
    value: "MA",
    label: "Morocco",
  },
  {
    value: "MZ",
    label: "Mozambique",
  },
  {
    value: "MM",
    label: "Myanmar",
  },
  {
    value: "NA",
    label: "Namibia",
  },
  {
    value: "NR",
    label: "Nauru",
  },
  {
    value: "NP",
    label: "Nepal",
  },
  {
    value: "NL",
    label: "Netherlands",
  },
  {
    value: "NC",
    label: "New Caledonia",
  },
  {
    value: "NZ",
    label: "New Zealand",
  },
  {
    value: "NI",
    label: "Nicaragua",
  },
  {
    value: "NE",
    label: "Niger",
  },
  {
    value: "NG",
    label: "Nigeria",
  },
  {
    value: "NU",
    label: "Niue",
  },
  {
    value: "NF",
    label: "Norfolk Island",
  },
  {
    value: "MK",
    label: "North Macedonia",
  },
  {
    value: "MP",
    label: "Northern Mariana Islands",
  },
  {
    value: "NO",
    label: "Norway",
  },
  {
    value: "OM",
    label: "Oman",
  },
  {
    value: "PK",
    label: "Pakistan",
  },
  {
    value: "PW",
    label: "Palau",
  },
  {
    value: "PS",
    label: "Palestine, State of",
  },
  {
    value: "PA",
    label: "Panama",
  },
  {
    value: "PG",
    label: "Papua New Guinea",
  },
  {
    value: "PY",
    label: "Paraguay",
  },
  {
    value: "PE",
    label: "Peru",
  },
  {
    value: "PH",
    label: "Philippines",
  },
  {
    value: "PN",
    label: "Pitcairn",
  },
  {
    value: "PL",
    label: "Poland",
  },
  {
    value: "PT",
    label: "Portugal",
  },
  {
    value: "PR",
    label: "Puerto Rico",
  },
  {
    value: "QA",
    label: "Qatar",
  },
  {
    value: "RE",
    label: "Réunion",
  },
  {
    value: "RO",
    label: "Romania",
  },
  {
    value: "RU",
    label: "Russian Federation",
  },
  {
    value: "RW",
    label: "Rwanda",
  },
  {
    value: "BL",
    label: "Saint Barthélemy",
  },
  {
    value: "SH",
    label: "Saint Helena, Ascension and Tristan da Cunha",
  },
  {
    value: "KN",
    label: "Saint Kitts and Nevis",
  },
  {
    value: "LC",
    label: "Saint Lucia",
  },
  {
    value: "MF",
    label: "Saint Martin (French part)",
  },
  {
    value: "PM",
    label: "Saint Pierre and Miquelon",
  },
  {
    value: "VC",
    label: "Saint Vincent and the Grenadines",
  },
  {
    value: "WS",
    label: "Samoa",
  },
  {
    value: "SM",
    label: "San Marino",
  },
  {
    value: "ST",
    label: "Sao Tome and Principe",
  },
  {
    value: "SA",
    label: "Saudi Arabia",
  },
  {
    value: "SN",
    label: "Senegal",
  },
  {
    value: "RS",
    label: "Serbia",
  },
  {
    value: "SC",
    label: "Seychelles",
  },
  {
    value: "SL",
    label: "Sierra Leone",
  },
  {
    value: "SG",
    label: "Singapore",
  },
  {
    value: "SX",
    label: "Sint Maarten (Dutch part)",
  },
  {
    value: "SK",
    label: "Slovakia",
  },
  {
    value: "SI",
    label: "Slovenia",
  },
  {
    value: "SB",
    label: "Solomon Islands",
  },
  {
    value: "SO",
    label: "Somalia",
  },
  {
    value: "ZA",
    label: "South Africa",
  },
  {
    value: "GS",
    label: "South Georgia and the South Sandwich Islands",
  },
  {
    value: "SS",
    label: "South Sudan",
  },
  {
    value: "ES",
    label: "Spain",
  },
  {
    value: "LK",
    label: "Sri Lanka",
  },
  {
    value: "SD",
    label: "Sudan",
  },
  {
    value: "SR",
    label: "Suriname",
  },
  {
    value: "SJ",
    label: "Svalbard and Jan Mayen",
  },
  {
    value: "SE",
    label: "Sweden",
  },
  {
    value: "CH",
    label: "Switzerland",
  },
  {
    value: "SY",
    label: "Syrian Arab Republic",
  },
  {
    value: "TW",
    label: "Taiwan, Province of China",
  },
  {
    value: "TJ",
    label: "Tajikistan",
  },
  {
    value: "TZ",
    label: "Tanzania, United Republic of",
  },
  {
    value: "TH",
    label: "Thailand",
  },
  {
    value: "TL",
    label: "Timor-Leste",
  },
  {
    value: "TG",
    label: "Togo",
  },
  {
    value: "TK",
    label: "Tokelau",
  },
  {
    value: "TO",
    label: "Tonga",
  },
  {
    value: "TT",
    label: "Trinidad and Tobago",
  },
  {
    value: "TN",
    label: "Tunisia",
  },
  {
    value: "TR",
    label: "Turkey",
  },
  {
    value: "TM",
    label: "Turkmenistan",
  },
  {
    value: "TC",
    label: "Turks and Caicos Islands",
  },
  {
    value: "TV",
    label: "Tuvalu",
  },
  {
    value: "UG",
    label: "Uganda",
  },
  {
    value: "UA",
    label: "Ukraine",
  },
  {
    value: "AE",
    label: "United Arab Emirates",
  },
  {
    value: "GB",
    label: "United Kingdom of Great Britain and Northern Ireland",
  },
  {
    value: "US",
    label: "United States of America",
  },
  {
    value: "UM",
    label: "United States Minor Outlying Islands",
  },
  {
    value: "UY",
    label: "Uruguay",
  },
  {
    value: "UZ",
    label: "Uzbekistan",
  },
  {
    value: "VU",
    label: "Vanuatu",
  },
  {
    value: "VE",
    label: "Venezuela (Bolivarian Republic of)",
  },
  {
    value: "VN",
    label: "Viet Nam",
  },
  {
    value: "VG",
    label: "Virgin Islands (British)",
  },
  {
    value: "VI",
    label: "Virgin Islands (U.S.)",
  },
  {
    value: "WF",
    label: "Wallis and Futuna",
  },
  {
    value: "EH",
    label: "Western Sahara",
  },
  {
    value: "YE",
    label: "Yemen",
  },
  {
    value: "ZM",
    label: "Zambia",
  },
  {
    value: "ZW",
    label: "Zimbabwe",
  },
];
export const country_list_array = [
  "Afghanistan",
  "Åland_Islands",
  "Albania",
  "Algeria",
  "American_Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua_and_Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bonaire_Sint_Eustatius_and_Saba",
  "Bosnia_and_Herzegovina",
  "Botswana",
  "Brazil",
  "British_Indian_Ocean_Territory",
  "Brunei_Darussalam",
  "Bulgaria",
  "Burkina_Faso",
  "Burundi",
  "Cabo_Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman_Islands",
  "Central_African_Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas_Island",
  "Cocos_(Keeling)_Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo,_Democratic_Republic_of_the",
  "Cook_Islands",
  "Costa_Rica",
  "Côte d'Ivoire",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czech_Republic_(Czechia)",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican_Republic",
  "Ecuador",
  "Egypt",
  "El_Salvador",
  "Equatorial_Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland_Islands_(Malvinas)",
  "Faroe_Islands",
  "Fiji",
  "Finland",
  "France",
  "French_Guiana",
  "French_Polynesia",
  "French_Southern_Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard_Island_and_McDonald_Islands",
  "Holy_See",
  "Honduras",
  "Hong_Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle_of_Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Korea_(Democratic_People's_Republic_of)",
  "Korea,_Republic_of",
  "Kuwait",
  "Lao_People's_Democratic_Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall_Islands",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia_(Federated_States_of)",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New_Caledonia",
  "New_Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk_Island",
  "North_Macedonia",
  "Northern_Mariana_Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine,_State_of",
  "Panama",
  "Papua_New_Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto_Rico",
  "Qatar",
  "Réunion",
  "Romania",
  "Russian_Federation",
  "Rwanda",
  "Saint_Barthélemy",
  "Saint_Helena,_Ascension_and_Tristan_da_Cunha",
  "Saint_Kitts_&_Nevis",
  "Saint_Lucia",
  "Saint_Martin_(French_part)",
  "Saint_Pierre_and_Miquelon",
  "Saint_Vincent_and_the_Grenadines",
  "Samoa",
  "San_Marino",
  "Sao_Tome_&_Principe",
  "Saudi_Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra_Leone",
  "Singapore",
  "Sint_Maarten_(Dutch_part)",
  "Slovakia",
  "Slovenia",
  "Solomon_Islands",
  "Somalia",
  "South_Africa",
  "South_Georgia_and_the_South_Sandwich_Islands",
  "South_Sudan",
  "Spain",
  "Sri_Lanka",
  "Sudan",
  "Suriname",
  "Svalbard_and_Jan_Mayen",
  "Sweden",
  "Switzerland",
  "Syrian_Arab_Republic",
  "Taiwan,_Province_of_China",
  "Tajikistan",
  "Tanzania,_United_Republic_of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad_and_Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks_and_Caicos_Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United_Arab_Emirates",
  "United_Kingdom_of_Great_Britain_and_Northern_Ireland",
  "United_States_of_America",
  "United_States_Minor_Outlying_Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela_(Bolivarian_Republic_of)",
  "Vietnam",
  "Virgin_Islands_(British)",
  "Virgin_Islands_(U.S.)",
  "Wallis_and_Futuna",
  "Western_Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];
export const title_list = [
  { key: "emptySelect", name: "" },
  { key: "Mr.", name: "Mr." },
  { key: "Mrs.", name: "Mrs." },
  { key: "Ms.", name: "Ms." },
  { key: "Miss", name: "Miss" },
  { key: "Dr.", name: "Dr." },
];
export const gender_list = [
  { key: "emptySelect", name: "" },
  { key: "Male", name: "Male" },
  { key: "Female", name: "Female" },
  { key: "Unknown", name: "Unknown" },
  { key: "Transgender", name: "Transgender" },
  { key: "Non-binary", name: "Non-binary" },
];
export const yn_list = [
  { key: "emptySelect", name: "" },
  { key: "true", name: "Yes" },
  { key: "false", name: "No" },
];
export const templatenotOlderThan = [
  { key: 0, name: "Not applicable" },
  { key: 3, name: "3 months" },
  { key: 6, name: "6 months" },
  { key: 9, name: "9 months" },
  { key: "custom", name: "Custom value" },
];
export const id_type_list = [
  { key: "emptySelect", name: "All types" },
  { key: "Passport", name: "Passport" },
  { key: "idCard", name: "ID Card" },
  { key: "DrivingLicence ", name: "Driving Licence" },
];
export const id_type_list2 = [
  { key: "emptySelect", name: "All types" },
  { key: "PASSPORT", name: "Passport" },
  { key: "ID_CARD", name: "ID Card" },
  { key: "DRIVING_LICENCE ", name: "Driving Licence" },
];
export const legal_status_list = [
  { key: "emptySelect", name: "" },
  { key: "ACTIVE", name: "Active" },
  { key: "INACTIVE", name: "Inactive" },
];
export const idv_locale_list = [
  { key: "ar", name: "Arabic" },
  { key: "bg", name: "Bulgarian" },
  { key: "cs", name: "Czech" },
  { key: "da", name: "Danish" },
  { key: "de", name: "German" },
  { key: "el", name: "Greek" },
  { key: "en", name: "English" },
  { key: "en-GB", name: "British English" },
  { key: "es", name: "Spanish" },
  { key: "es-MX", name: "Mexican Spanish" },
  { key: "et", name: "Estonian" },
  { key: "fi", name: "Finnish" },
  { key: "fr", name: "French" },
  { key: "he", name: "Hebrew" },
  { key: "hr", name: "Croatian" },
  { key: "hu", name: "Hungarian" },
  { key: "hy", name: "Armenian" },
  { key: "id", name: "Indonesian" },
  { key: "it", name: "Italian" },
  { key: "ja", name: "Japanese" },
  { key: "ka", name: "Georgian" },
  { key: "km", name: "Khmer" },
  { key: "ko", name: "Korean" },
  { key: "lt", name: "Lithuanian" },
  { key: "ms", name: "Malay" },
  { key: "nl", name: "Dutch" },
  { key: "no", name: "Norwegian" },
  { key: "pl", name: "Polish" },
  { key: "pt", name: "Portuguese" },
  { key: "pt-BR", name: "Brazilian Portuguese" },
  { key: "ro", name: "Romanian" },
  { key: "ru", name: "Russian" },
  { key: "sk", name: "Slovak" },
  { key: "sv", name: "Swedish" },
  { key: "th", name: "Thai" },
  { key: "tr", name: "Turkish" },
  { key: "vi", name: "Vietnamese" },
  { key: "zh-CN", name: "Chinese(PRC)" },
  { key: "zh-HK", name: "Chinese(Hong Kong)" },
];
export const relatedPartyRoles = [
  { key: "emptySelect", name: "Choose a role" },
  { key: "Director", name: "Director" },
  { key: "Shareholder", name: "Shareholder" },
  { key: "UltimateBeneficialOwner", name: "Ultimate Beneficial Owner (UBO)" },
  { key: "Controller", name: "Other controller" },
  { key: "BeneficiaryVested", name: "Beneficiary" },
  { key: "Trustee", name: "Trustee" },
  { key: "Settlor", name: "Settlor" },
  { key: "Protector", name: "Protector" },
  { key: "Manager", name: "(Fund) Manager" },
  { key: "AuthorisedSignatory", name: "Authorised signatory" },
  { key: "Agent", name: "Agent" },
  { key: "Nominee", name: "Nominee" },
  { key: "Custodian", name: "Custodian" },
  { key: "Administrator", name: "Administrator" },
  { key: "InvestmentAdvisor", name: "Investment advisor" },
  { key: "GeneralPartner", name: "General partner" },
  // {'key': 'other', 'name': 'Other'},
];
export const legalPersonTypeList = [
  { key: "emptySelect", name: "" },
  { key: "Company", name: "(Limited Liability) Company" },
  { key: "Partnership", name: "Partnership" },
  { key: "Trust", name: "Trust" },
  { key: "Fund", name: "Regulated Fund" },
  { key: "Foundation", name: "Foundation" },
  { key: "LegalArrangements/partn.", name: "Other Legal Arrangement" },
  { key: "NonProfitOrganisations", name: "Non-profit Organisations" },
];

// "Company",
// "Partnership",
// "Trust",
// "Foundation",
// "LegalArrangementsInclGPs",
// "Fund",
// "NonProfitOrganisations",
// "UnitTrust",
// "Publicly traded company",
// "Regulated financial entity"
export const idv_workflow_list = [
  // {key : 'emptySelect', name : ''},
  { key: "200", name: "Camera or upload" },
  { key: "201", name: "Camera only" },
  { key: "202", name: "Upload only" },
];
export const fuzinessLevelList = [
  { key: "0", name: "0%" },
  { key: "0.1", name: "10%" },
  { key: "0.2", name: "20%" },
  { key: "0.3", name: "30%" },
  { key: "0.4", name: "40%" },
  { key: "0.5", name: "50%" },
  { key: "0.6", name: "60%" },
  { key: "0.7", name: "70%" },
  { key: "0.8", name: "80%" },
  { key: "0.9", name: "90%" },
  { key: "1", name: "100%" },
];
export const commentsOnWhitelistingHit = [
  'Reasons for marking as "not relevant":',
  "False positive - Name does not match",
  "False positive - Country does not match",
  "False positive - Date of birth/registration does not match",
  "False positive - Gender/legal form not as expected",
  "False positive - Other reason:",
  "Match but not relevant - severity is low",
  "Match but not relevant - unrelated",
  "Match but not relevant - acceptable",
  "Match but not relevant - Other reason:",
];
@Injectable({
  providedIn: "root",
})
export class DocumenTypetList {
  constructor(
    private helperService: HelperServiceService,
    public translate: TranslateService,
  ) {}
  documentTypeList = {
    NaturalPerson: {
      fields: [
        {
          type: "subtitle-alternate",
          class: "input-label-large increased-margin-top",
          params: {
            content: "Summary",
          },
        },
        {
          type: "inputText",
          params: {
            label: "First name ",
            id: "FirstName",
            name: "FirstNameNP",
            content: "",
            required: "true",
            labelClass: "font-weight-bold",
            maxLength: 60,
          },
        },
        {
          type: "inputText",
          class: "reduced-margin-top",
          length: "60",
          params: {
            label: "Last name",
            id: "LastName",
            name: "LastNameNP",
            content: "",
            required: "true",
            labelClass: "font-weight-bold",
            maxLength: 60,
          },
        },
        {
          type: "checkbox",
          class: "reduced-margin-top skip",
          params: {
            id: "isMinor",
            name: "IsMinorN",
            label: "The person is a minor (under the age of 18)",
            value: true,
          },
        },
        {
          type: "show_or_hide_details",
          method: "showMoreDetails",
          params: {},
        },
        {
          type: "select",
          class: "reduced-margin-top input-transparent tempHideInput",
          length: "60",
          params: {
            id: "Title",
            name: "TitleNP",
            label: "Title",
            labelClass: "font-weight-bold",
            options: title_list,
          },
        },
        {
          type: "inputText",
          class: "reduced-margin-top tempHideInput",
          length: "60",
          params: {
            label: "Middle names",
            id: "MiddleNames",
            name: "MiddleNamesNP",
            content: "",
            labelClass: "font-weight-bold",
            maxLength: 60,
            optional: true,
          },
        },
        {
          type: "inputText",
          class: "reduced-margin-top tempHideInput",
          length: "60",
          params: {
            label: "Former first names",
            id: "FormerFirstNames",
            name: "FormerFirstNamesNP",
            content: "",
            labelClass: "font-weight-bold",
            maxLength: 60,
            optional: true,
          },
        },
        {
          type: "inputText",
          class: "reduced-margin-top tempHideInput",
          length: "60",
          params: {
            label: "Former last names",
            id: "FormerLastNames",
            name: "FormerLastNamesNP",
            content: "",
            labelClass: "font-weight-bold",
            maxLength: 60,
            optional: true,
          },
        },
        {
          type: "inputText",
          class: "reduced-margin-top tempHideInput",
          length: "60",
          params: {
            id: "EmailAddress",
            name: "EmailAddressNP",
            label: "Email address",
            labelClass: "font-weight-bold",
            patternMatch: emailPattern,
            validatorMessage: this.translate.instant("validation.validation5"),
          },
        },
        {
          type: "select",
          class: "reduced-margin-top input-transparent tempHideInput",
          length: "60",
          params: {
            id: "Gender",
            name: "GenderNP",
            label: "Gender",
            labelClass: "font-weight-bold",
            options: gender_list,
          },
        },
        {
          type: "input_date",
          class: "reduced-margin-top tempHideInput",
          length: "60",
          params: {
            // using inputId instead of just id for date field
            id: "DateOfBirth",
            name: "DateOfBirth",
            label: "Date of birth",
            labelClass: "font-weight-bold",
            minDate: this.helperService.getAsStartDate("01.01.1915"),
            maxDate: this.helperService.getTodayAsMaxDate(),
            required: false,
          },
        },
        {
          type: "select",
          class: "reduced-margin-top input-transparent tempHideInput",
          length: "60",
          params: {
            id: "CountryOfBirth",
            name: "CountryOfBirthNP",
            label: "Country of birth",
            labelClass: "font-weight-bold",
            options: this.parseCountryOptions(),
          },
        },
        {
          type: "select",
          class: "reduced-margin-top input-transparent tempHideInput",
          length: "60",
          params: {
            id: "Nationality",
            name: "NationalityNP",
            label: "Nationality",
            labelClass: "font-weight-bold",
            options: this.parseCountryOptions(),
          },
        },
        {
          type: "select",
          class: "reduced-margin-top input-transparent tempHideInput",
          length: "60",
          params: {
            id: "DualNationality",
            name: "DualNationalityNP",
            label: "Dual nationality",
            labelClass: "font-weight-bold",
            options: this.parseCountryOptions(),
            optional: true,
          },
        },
        {
          type: "select",
          class: "reduced-margin-top input-transparent tempHideInput",
          length: "60",
          params: {
            id: "CountryOfResidence",
            name: "CountryOfResidenceNP",
            options: this.parseCountryOptions(),
            label: "Country of residence",
            labelClass: "font-weight-bold",
          },
        },
        {
          type: "inputText",
          class: "retuced-margin-top tempHideInput",
          params: {
            label: "Occupation",
            id: "Occupation",
            name: "OccupationNP",
            content: "",
            labelClass: "font-weight-bold",
            maxLength: 60,
            optional: true,
          },
        },
        {
          type: "inputText",
          class: "retuced-margin-top tempHideInput",
          params: {
            label: "Tax ID number",
            id: "TaxIDNumber",
            name: "TaxIDNumberNP",
            content: "",
            labelClass: "font-weight-bold",
            maxLength: 60,
            optional: true,
          },
        },
        {
          type: "subtitle-alternate",
          class: "input-label-large increased-margin-top tempHideInput",
          params: {
            content: "Address",
          },
        },
        {
          type: "inputGoogleAutocomplete",
          class: "addressVerificationManualField tempHideInput skip",
          params: {
            label: this.translate.instant("evidencePreview.addressLabel1"),
            placeholder: this.translate.instant(
              "evidencePreview.addressLabel2",
            ),
            id: "AddressInputField",
            labelClass: "font-weight-bold",
            required: false,
          },
        },
        {
          type: "checkbox",
          subType: "addressManualInputCheck",
          class: "checkboxUseClassicAddress tempHideInput skip",
          params: {
            id: "useClassicAddress",
            name: "useClassicAddress",
            label: "Manually enter address",
            value: "useClassicAddress",
            changemethod: "useClassicAddressToggle",
          },
        },
        {
          type: "inputText",
          class: "reduced-margin-top manualInput d-none",
          subType: "addressManualInput",
          length: "60",
          params: {
            id: "AddressStreet",
            name: "AddressStreetNP",
            label: "Street",
            labelClass: "font-weight-bold",
            maxLength: 60,
          },
        },
        {
          type: "inputText",
          class: "reduced-margin-top manualInput d-none",
          subType: "addressManualInput",
          length: "10",
          params: {
            id: "AddressNumber",
            name: "AddressNumberNP",
            label: "Number",
            labelClass: "font-weight-bold",
            maxLength: 50,
          },
        },
        {
          type: "inputText",
          class: "reduced-margin-top manualInput d-none",
          subType: "addressManualInput",
          length: "10",
          params: {
            id: "AddressPostalCode",
            name: "AddressPostalCodeNP",
            label: "Postal code",
            labelClass: "font-weight-bold",
            maxLength: 50,
          },
        },
        {
          type: "inputText",
          class: "reduced-margin-top manualInput d-none",
          subType: "addressManualInput",
          length: "60",
          params: {
            id: "AddressCity",
            name: "AddressCityNP",
            label: "City",
            labelClass: "font-weight-bold",
            maxLength: 60,
          },
        },
        {
          type: "select",
          class: "reduced-margin-top input-transparent manualInput d-none",
          subType: "addressManualInput",
          length: "60",
          params: {
            id: "AddressCountry",
            name: "AddressCountryNP",
            label: "Address country",
            labelClass: "font-weight-bold",
            options: this.parseCountryOptions(),
          },
        },
        {
          type: "button",
          params: {
            content: "Create",
            type: "submit",
            display: true,
            action: "htpmOnNewInvestorEnd",
            errorMessage:
              " *please make sure that all the required fields are properly filled in",
          },
        },
      ],
      checkbox_list: [
        {
          type: "subtitle-alternate",
          class: "input-label-large increased-margin-top",
          params: {
            content: "Choose items to add",
          },
        },
        {
          type: "show_or_hide_details",
          method: "showMoreDetails",
          text: "Add documents",
          class: "ml-2 mt-2",
        },
        {
          type: "counter_group",
          class: "checkbox-borderless mt-2 tempHideInput",
          params: {
            groupTitle: "Documents",
            checkboxes: [
              {
                type: "checkbox",
                params: {
                  id: "Passport",
                  name: "PassportN",
                  label: "Passport",
                  value: "Passport",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "IdentityCard",
                  name: "IdentityCardN",
                  label: "Identity card",
                  value: "IdentityCard",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "DriversLicense",
                  name: "DriversLicenseN",
                  label: "Drivers license",
                  value: "DriversLicense",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "TaxReturn",
                  name: "TaxReturnN",
                  label: "Tax Return",
                  value: "TaxReturn",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "Authorisation",
                  name: "Authorisation",
                  label: "Authorisation",
                  value: "Authorisation",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "TaxRegistration",
                  name: "TaxRegistration",
                  label: "Tax registration",
                  value: "TaxRegistration",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "UtilityBill",
                  name: "UtilityBill",
                  label: "Utility bill",
                  value: "UtilityBill",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "BankStatement",
                  name: "BankStatement",
                  label: "Bank statement",
                  value: "BankStatement",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "CreditCardStatement",
                  name: "CreditCardStatementN",
                  label: "Credit card statement",
                  value: "CreditCardStatement",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "InsuranceAgreement",
                  name: "InsuranceAgreementN",
                  label: "Insurance agreement",
                  value: "InsuranceAgreement",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "Investment/SecuritiesStatement",
                  name: "Investment/SecuritiesStatement",
                  label: "Investment/Securities statement",
                  value: "InvestmentSecuritiesStatement",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "MortgageStatement",
                  name: "MortgageStatementN",
                  label: "Mortgage statement",
                  value: "MortgageStatement",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "TaxBill",
                  name: "TaxBill",
                  label: "Tax bill",
                  value: "TaxBill",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "IntroductoryLetter",
                  name: "IntroductoryLetter",
                  label: "Introductory letter",
                  value: "IntroductoryLetter",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "TaxRateNotice",
                  name: "TaxRateNotice",
                  label: "Tax rate notice",
                  value: "TaxRateNotice",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "TenancyDeclaration",
                  name: "TenancyDeclaration",
                  label: "Tenancy declaration",
                  value: "TenancyDeclaration",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "MotorVehicleLicenceRenewal",
                  name: "MotorVehicleLicenceRenewal",
                  label: "Motor vehicle licence renewal",
                  value: "MotorVehicleLicenceRenewal",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "LetterFromRetirementVillage",
                  name: "LetterFromRetirementVillage",
                  label: "Letter from a retirement village",
                  value: "LetterFromRetirementVillage",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "LeaseRentalAgreement",
                  name: "LeaseRentalAgreement",
                  label: "Lease rental agreement",
                  value: "LeaseRentalAgreement",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "CourtOrder",
                  name: "CourtOrder",
                  label: "Court order",
                  value: "CourtOrder",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "DeclarationOfVisit",
                  name: "DeclarationOfVisit",
                  label: "Declaration of Visit",
                  value: "DeclarationOfVisit",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "MunicipalityBill",
                  name: "MunicipalityBill",
                  label: "Municipality bill",
                  value: "MunicipalityBill",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "SignedTaxReturns",
                  name: "SignedTaxReturns",
                  label: "Signed tax returns",
                  value: "SignedTaxReturns",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "SourceOfWealthDeclaration",
                  name: "SourceOfWealthDeclaration",
                  label: "Source of wealth declaration",
                  value: "Source of wealth declaration",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "GovernmentIssuedCorrespondence",
                  name: "GovernmentIssuedCorrespondence",
                  label: "Government issued correspondence",
                  value: "GovernmentIssuedCorrespondence",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "LocatorAddressLetter",
                  name: "LocatorAddressLetter",
                  label: "Locator address letter",
                  value: "LocatorAddressLetter",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "LastWillAndTestament",
                  name: "LastWillAndTestament",
                  label: "Last will and testament",
                  value: "LastWillAndTestament",
                },
              },
            ],
          },
        },
        // {
        //   'type': 'button',
        //   'params': {
        //     'content': 'Next',
        //     'type': 'submit',
        //     'display': true,
        //     'action': 'htpmOnSelectedDocuments',
        //     'errorMessage' : '*at least one new document type must be selected.'
        //   }
        // },
      ],
    },
    LegalPerson: {
      fields: [
        {
          type: "subtitle-alternate",
          class: "input-label-large increased-margin-top",
          params: {
            content: "Summary",
          },
        },
        {
          type: "select",
          class: "reduced-margin-top input-transparent",
          params: {
            label: "Country of registration",
            id: "CountryOfRegistration",
            name: "CountryOfRegistrationNP",
            options: this.parseCountryOptions(),
            labelClass: "font-weight-bold",
          },
        },
        {
          'type': 'inputText',
          'length': '60',
          'params': {
            'label': 'Registered name ',
            'id': 'RegisteredName',
            'name': 'RegisteredNameNP',
            'content': '',
            'required': 'true',
            'labelClass': 'font-weight-bold',
            'maxLength': 250,
          },
        },
        {
          type: "inputText",
          length: "20",
          params: {
            label: "Registration number",
            id: "RegistrationNumber",
            name: "RegistrationNumberNP",
            content: "",
            labelClass: "font-weight-bold",
            maxLength: 40,
          },
        },
        {
          type: "select",
          class: "reduced-margin-top input-transparent",
          params: {
            label: "Type",
            id: "Type",
            name: "Type",
            options: legalPersonTypeList,
            labelClass: "font-weight-bold",
            required: "true",
          },
        },
        {
          type: "show_or_hide_details",
          method: "showMoreDetails",
          params: {},
        },
        {
          type: "inputText",
          class: "reduced-margin-top tempHideInput",
          length: "60",
          params: {
            label: "Trade names ",
            id: "TradeNames",
            name: "TradeNamesNP",
            content: "",
            labelClass: "font-weight-bold",
            maxLength: 250,
            optional: true,
          },
        },
        {
          type: "input_date",
          class: "reduced-margin-top tempHideInput",
          params: {
            // using inputId instead of just id for date field
            id: "DateOfRegistration",
            name: "DateOfRegistration",
            label: "Date of registration",
            labelClass: "font-weight-bold",
            minDate: this.helperService.getAsMinDateMinusYears(100),
            maxDate: this.helperService.getTodayAsMaxDate(),
            startDate: this.helperService.getAsMinDateMinusYears(5),
            required: false,
          },
        },
        {
          type: "select",
          class: "reduced-margin-top input-transparent tempHideInput",
          params: {
            label: "Regulated",
            id: "Regulated",
            name: "RegulatedNP",
            options: yn_list,
            labelClass: "font-weight-bold",
          },
        },
        {
          type: "inputText",
          class: "reduced-margin-top tempHideInput",
          length: "20",
          params: {
            label: "Regulator ",
            id: "Regulator",
            name: "Regulator",
            content: "",
            labelClass: "font-weight-bold",
            maxLength: 60,
            optional: true,
          },
        },
        {
          type: "inputText",
          class: "reduced-margin-top tempHideInput",
          length: "20",
          params: {
            label: "Tax number or Tax ID",
            id: "TaxNumber",
            name: "Tax number",
            content: "",
            labelClass: "font-weight-bold",
            maxLength: 60,
            optional: true,
          },
        },
        {
          type: "select",
          class: "reduced-margin-top input-transparent tempHideInput",
          params: {
            label: "Status",
            id: "Status",
            name: "StatusNP",
            options: legal_status_list,
            labelClass: "font-weight-bold",
          },
        },
        {
          type: "subtitle-alternate",
          class: "input-label-large increased-margin-top tempHideInput",
          params: {
            content: "Registered address",
          },
        },
        {
          type: "inputGoogleAutocomplete",
          class: "addressVerificationManualField tempHideInput",
          params: {
            label: this.translate.instant("evidencePreview.addressLabel1"),
            placeholder: this.translate.instant(
              "evidencePreview.addressLabel2",
            ),
            id: "AddressInputField",
            labelClass: "font-weight-bold",
            required: false,
          },
        },
        {
          type: "checkbox",
          subType: "addressManualInputCheck",
          class: "checkboxUseClassicAddress tempHideInput skip",
          params: {
            id: "useClassicAddress",
            name: "useClassicAddress",
            label: "Manually enter address",
            value: "useClassicAddress",
            changemethod: "useClassicAddressToggle",
          },
        },
        {
          type: "inputText",
          subType: "addressManualInput",
          class: "reduced-margin-top manualInput d-none",
          length: "60",
          params: {
            label: "Address street",
            id: "RegisteredAddressStreet",
            name: "RegisteredAddressStreetNP",
            content: "",
            labelClass: "font-weight-bold",
            maxLength: 60,
          },
        },
        {
          type: "inputText",
          subType: "addressManualInput",
          class: "reduced-margin-top manualInput d-none",
          length: "10",
          params: {
            label: "Address number",
            id: "RegisteredAddressNumber",
            name: "RegisteredAddressNumberNP",
            content: "",
            labelClass: "font-weight-bold",
            maxLength: 50,
          },
        },
        {
          type: "inputText",
          subType: "addressManualInput",
          class: "reduced-margin-top manualInput d-none",
          length: "10",
          params: {
            label: "Postal code",
            id: "RegisteredAddressPostalCode",
            name: "RegisteredAddressPostalCodeNP",
            content: "",
            labelClass: "font-weight-bold",
            maxLength: 50,
          },
        },
        {
          type: "inputText",
          subType: "addressManualInput",
          class: "reduced-margin-top manualInput d-none",
          length: "60",
          params: {
            label: "Address city",
            id: "RegisteredAddressCity",
            name: "RegisteredAddressCityNP",
            content: "",
            labelClass: "font-weight-bold",
            maxLength: 60,
          },
        },
        {
          type: "select",
          subType: "addressManualInput",
          class: "reduced-margin-top input-transparent manualInput d-none",
          params: {
            label: "Address country",
            id: "RegisteredAddressCountry",
            name: "RegisteredAddressCountryNP",
            options: this.parseCountryOptions(),
            labelClass: "font-weight-bold",
          },
        },
        {
          type: "button",
          params: {
            content: "Create",
            type: "submit",
            display: true,
            action: "htpmOnNewInvestorEnd",
            errorMessage:
              " *please make sure that all the required fields are properly filled in",
          },
        },
      ],
      checkbox_list: [
        {
          type: "subtitle-alternate",
          class: "input-label-large increased-margin-top",
          params: {
            content: "Choose items to add",
          },
        },
        {
          type: "counter_group",
          id: "ProofOfRegistration",
          class: "checkbox-borderless",
          params: {
            groupTitle: "Documents",
            checkboxes: [
              {
                type: "checkbox",
                params: {
                  id: "ArticlesOfAssociation",
                  name: "ArticlesOfAssociation",
                  label: "Articles of association",
                  value: "ArticlesOfAssociation",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "TaxReturn",
                  name: "TaxReturn",
                  label: "Tax return",
                  value: "TaxReturn",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "Authorisation",
                  name: "Authorisation",
                  label: "Authorisation",
                  value: "Authorisation",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "CertificateOfIncorporation",
                  name: "CertificateOfIncorporationN",
                  label: "Certificate of incorporation",
                  value: "CertificateOfIncorporation",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "TaxRegistration",
                  name: "TaxRegistrationN",
                  label: "Tax registration",
                  value: "TaxRegistration",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "CertificateOfIncumbency",
                  name: "CertificateOfIncumbency",
                  label: "Certificate of incumbency",
                  value: "CertificateOfIncumbency",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "LatestFinancialStatements/Accounts",
                  name: "LatestFinancialStatements/Accounts",
                  label: "Latest financial statements/accounts",
                  value: "LatestFinancialStatements/Accounts",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "MemorandumOfArticlesAndAssociation",
                  name: "MemorandumOfArticlesAndAssociation",
                  label: "Memorandum of articles and association",
                  value: "MemorandumOfArticlesAndAssociation",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "TrustDeed/Agreement",
                  name: "TrustDeed/Agreement",
                  label: "Trust deed/agreement",
                  value: "TrustDeed/Agreement",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "AmendmentToDeed",
                  name: "AmendmentToDeed",
                  label: "Amendment to deed",
                  value: "AmendmentToDeed",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "RegistryExtract",
                  name: "RegistryExtract",
                  label: "Registry extract",
                  value: "RegistryExtract",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "ExtractOfTrustInstrument",
                  name: "ExtractOfTrustInstrument",
                  label: "Extract of trust instrument",
                  value: "ExtractOfTrustInstrument",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "SignedLetterFromTrustee",
                  name: "SignedLetterFromTrustee",
                  label: "Signed letter from trustee",
                  value: "SignedLetterFromTrustee",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "Charter",
                  name: "Charter",
                  label: "Charter",
                  value: "Charter",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "PartnershipAgreement",
                  name: "PartnershipAgreement",
                  label: "Partnership agreement",
                  value: "PartnershipAgreement",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "CertificationOfRegistration",
                  name: "CertificationOfRegistration",
                  label: "Certification of registration",
                  value: "CertificationOfRegistration",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "IntroductoryLetter",
                  name: "IntroductoryLetter",
                  label: "Introductory letter",
                  value: "IntroductoryLetter",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "ShareRegister/RegisterOfMembers",
                  name: "ShareRegister/RegisterOfMembers",
                  label: "Share register/register of members",
                  value: "ShareRegister/RegisterOfMembers",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "AuthorizedSignatoryList",
                  name: "AuthorizedSignatoryList",
                  label: "Authorized signatory list",
                  value: "AuthorizedSignatoryList",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "RegisterOfDirectors",
                  name: "RegisterOfDirectors",
                  label: "Register of directors",
                  value: "RegisterOfDirectors",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "RegisterOfPartners",
                  name: "RegisterOfPartners",
                  label: "Register of partners",
                  value: "RegisterOfPartners",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "BoardResolution",
                  name: "BoardResolution",
                  label: "Board resolution",
                  value: "BoardResolution",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "CommitteeResolution",
                  name: "CommitteeResolution",
                  label: "Committee resolution",
                  value: "Committee resolution",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "MeetingMinutes",
                  name: "Meeting minutes",
                  label: "Meeting minutes",
                  value: "MeetingMinutes",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "InvestmentCommitteeResolution",
                  name: "InvestmentCommitteeResolution",
                  label: "Investment committee resolution",
                  value: "InvestmentCommitteeResolution",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "SourceOfFundsDeclaration",
                  name: "SourceOfFundsDeclaration",
                  label: "Source of funds declaration",
                  value: "SourceOfFundsDeclaration",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "SourceOfWealthDeclaration",
                  name: "SourceOfWealthDeclaration",
                  label: "Source of wealth declaration",
                  value: "Source of wealth declaration",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "LLCOperatingAgreement",
                  name: "LLCOperatingAgreement",
                  label: "LLC Operating Agreement",
                  value: "LLCOperatingAgreement",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "AMLAssurancesLetter",
                  name: "AMLAssurancesLetter",
                  label: "AML Letter",
                  value: "AMLAssurancesLetter",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "RoyalDecree",
                  name: "RoyalDecree",
                  label: "Royal decree",
                  value: "RoyalDecree",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "CertificateOfFormation",
                  name: "CertificateOfFormation",
                  label: "Certificate of formation",
                  value: "CertificateOfFormation",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "InvestmentManagementAgreement",
                  name: "InvestmentManagementAgreement",
                  label: "Investment management agreement",
                  value: "InvestmentManagementAgreement",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "ProofOfRegulation",
                  name: "ProofOfRegulation",
                  label: "Proof of regulation",
                  value: "ProofOfRegulation",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "Prospectus/OfferingMemorandum",
                  name: "Prospectus/OfferingMemorandum",
                  label: "Prospectus / Offering memorandum",
                  value: "Prospectus /OfferingMemorandum",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "Structure/OrganizationChart",
                  name: "Structure/OrganizationChart",
                  label: "Structure / organization chart",
                  value: "Structure/OrganizationChart",
                },
              },
              {
                type: "checkbox",
                params: {
                  id: "StockListingEvidence",
                  name: "StockListingEvidence",
                  label: "Stock listing evidence",
                  value: "StockListingEvidence",
                },
              },
            ],
          },
        },
        // {
        //   'type': 'button',
        //   'params': {
        //     'content': 'Next',
        //     'type': 'submit',
        //     'display': true,
        //     'action': 'htpmOnSelectedDocuments',
        //     'errorMessage' : '*at least one new document type must be selected.'
        //   }
        // },
      ],
    },
    LegalPersonKompany: {
      fields: [
        {
          type: "subtitle-alternate",
          class: "input-label-large increased-margin-top",
          params: {
            content: "Find a legal person",
          },
        },
        {
          type: "kompanyInput",
          params: {},
        },
        {
          type: "button",
          params: {
            content: "Create",
            class: "button-disabled ",
            type: "submit",
            display: true,
            action: "htpmOnNewInvestorEnd",
            errorMessage:
              " *please make sure that all the required fields are properly filled in",
          },
        },
      ],
    },
    PurposesOfUse: [
      "ProofOfIdentity",
      "ProofOfAddress",
      "ProofOfControl",
      "ProofOfOwnership",
      "ProofOfGoodStanding",
      "ProofOfAuthority",
      "EvidenceOfSourceOfWealth",
      "ProofOfRegulation",
    ],
    evidenceFields: {
      NaturalPerson: [
        { id: "PersonalInformation", type: "title", label: "Personal information", subtitle : "Provide additional information about you or your entity.", class: "hr big-title", },
        {
          id: "Title",
          type: "selectOptions",
          label: "Title",
          dynamicSelect: "title_list",
          selectOptionsList: title_list,
        },
        { 'id': 'FirstName', 'type': 'inputText', 'label': 'First name', 'maxLength': 60 },
        { 'id': 'MiddleNames', 'type': 'inputText', 'label': 'Middle Names', 'maxLength': 60, 'optional': true },
        {
          id: "LastName",
          type: "inputText",
          label: "Last name",
          maxLength: 60,
        },
        { 'id': 'FormerFirstNames', 'type': 'inputText', 'label': 'Former first names', 'maxLength': 60, 'optional': true },
        {
          'id': 'FormerLastNames', 'type': 'inputText', 'label': 'Former last names',
          'maxLength': 60, 'optional': true
        },
        { 'id': 'EmailAddress', 'type': 'inputText', 'label': 'Email address', 'patternMatch': emailPattern.toString(), 
        'validatorMessage': this.translate.instant('validation.validation5') },
        {
          id: "Gender",
          type: "selectOptions",
          dynamicSelect: "gender_list",
          label: "Gender",
          selectOptionsList: gender_list,
        },
        {
          id: "DateOfBirth",
          type: "date",
          label: "Date of birth",
          minDate: this.helperService.getAsStartDate("01.01.1915"),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(5),
        },
        {
          id: "CountryOfBirth",
          type: "selectOptions",
          label: "Country of birth",
          selectOptionsList: this.parseCountryOptions(),
        },
        {
          id: "Nationality",
          type: "selectOptions",
          label: "Nationality",
          selectOptionsList: this.parseCountryOptions(),
        },
        {
          id: "DualNationality",
          type: "selectOptions",
          label: "Dual Nationality",
          selectOptionsList: this.parseCountryOptions(),
          optional: true,
        },
        {
          id: "CountryOfResidence",
          type: "selectOptions",
          label: "Country of residence",
          selectOptionsList: this.parseCountryOptions(),
        },
        {
          id: "TaxIDNumber",
          type: "inputText",
          label: "Tax ID number",
          maxLength: 60,
          optional: true,
          prioLabel: "Tax identification number",
          tooltip: `e.g. BSN for the Netherlands. Please note that your local TIN might have a different abbreviation. Please consult the OECD <a target="_blank" href='https://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/tax-identification-numbers'>list of accepted TINs </a> per country`,
        },
        {
          id: "Occupation",
          type: "inputText",
          label: "Occuption",
          maxLength: 60,
          optional: true,
        },
        {
          id: "Address",
          type: "title",
          label: "Address: ",
          maxLength: 60,
          showAsRequired: true,
        },
        {
          id: "GoogleAddressLocator ",
          type: "inputGoogleAutocomplete",
          prioLabel: "Google address locator",
          label: "Google address locator",
          maxLength: 60,
          hideCondition: "useClassicAddress",
          skipParse: true,
          placeholder: this.translate.instant("evidencePreview.addressLabel2"),
        },
        {
          id: "useClassicAddress",
          type: "checkbox",
          prioLabel: "Manually enter address",
          label: "Manually enter address",
          maxLength: 60,
          skipParse: true,
        },
        {
          id: "AddressStreet",
          type: "inputText",
          prioLabel: "Street",
          label: "Street",
          maxLength: 60,
          displayCondition: "useClassicAddress",
        },
        {
          id: "AddressNumber",
          type: "inputText",
          prioLabel: "Number",
          label: "Number",
          maxLength: 50,
          displayCondition: "useClassicAddress",
          optional: true,
        },
        {
          id: "AddressPostalCode",
          type: "inputText",
          prioLabel: "Postal code",
          label: "Postal code",
          maxLength: 50,
          displayCondition: "useClassicAddress",
        },
        {
          id: "AddressCity",
          test: "AddressCity",
          type: "inputText",
          prioLabel: "City",
          label: "City",
          maxLength: 60,
          displayCondition: "useClassicAddress",
        },
        {
          id: "AddressCountry",
          type: "selectOptions",
          prioLabel: "Country",
          label: "Country",
          selectOptionsList: this.parseCountryOptions(),
          displayCondition: "useClassicAddress",
        },
      ],
      LegalPerson: [
        { id: "CompanyInformation", type: "title", label: "Company information", subtitle: "Provide additional information about you or your entity.", class: "hr big-title" },
        {
          id: "CountryOfRegistration",
          type: "selectOptions",
          label: "Country of registration",
          selectOptionsList: this.parseCountryOptions(),
          changemethod: "changeCountryReviewFlow",
        },
        { id: "CompanySearch", type: "CompanySearch" },
        {
          id: "RegisteredName",
          type: "inputText",
          label: "Registered name",
          maxLength: 250,
        },
        {
          'id': 'Type', 'type': 'selectOptions', 'label': 'Type', 'dynamicSelect': 'legalPersonTypeList',
          'selectOptionsList': legalPersonTypeList,  'changemethod': 'changeLegalPersonType'
        },
        {
          id: "RegistrationNumber",
          type: "inputText",
          label: "Registration number",
          maxLength: 40,
        },
        {
          id: "DateOfRegistration",
          type: "date",
          label: "Date of registration",
          minDate: this.helperService.getAsMinDateMinusYears(100),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(5),
        },
        {
          id: "Type",
          type: "selectOptions",
          label: "Type",
          dynamicSelect: "legalPersonTypeList",
          selectOptionsList: legalPersonTypeList,
        },
        {
          id: "Regulated",
          type: "selectOptions",
          label: "Regulated",
          dynamicSelect: "yn_list",
          selectOptionsList: yn_list,
        },
        {
          id: "Regulator",
          type: "inputText",
          label: "Regulator",
          maxLength: 60,
          optional: true,
        },
        {
          id: "TaxNumber",
          type: "inputText",
          label: "Tax number",
          maxLength: 60,
          optional: true,
        },
        {
          id: "Status",
          type: "selectOptions",
          label: "Type",
          dynamicSelect: "legal_status_list",
          selectOptionsList: legal_status_list,
        },
        {
          id: "RegisteredAddress",
          type: "title",
          label: "Address: ",
          showAsRequired: true,
        },
        {
          id: "GoogleAddressLocator ",
          type: "inputGoogleAutocomplete",
          prioLabel: "Google address locator",
          label: "Google address locator",
          maxLength: 60,
          hideCondition: "useClassicAddress",
          skipParse: true,
          placeholder: this.translate.instant("evidencePreview.addressLabel2"),
        },
        {
          id: "useClassicAddress",
          type: "checkbox",
          prioLabel: "Manually enter address",
          label: "Manually enter address",
          maxLength: 60,
          skipParse: true,
        },
        {
          id: "RegisteredAddressStreet",
          prioLabel: "Street",
          type: "inputText",
          label: "Address street",
          maxLength: 60,
          displayCondition: "useClassicAddress",
        },
        {
          id: "RegisteredAddressNumber",
          prioLabel: "Number",
          optional: true,
          type: "inputText",
          label: "Address number",
          maxLength: 50,
          displayCondition: "useClassicAddress",
        },
        {
          id: "RegisteredAddressPostalCode",
          prioLabel: "Postal code",
          type: "inputText",
          label: "Postal code",
          maxLength: 50,
          displayCondition: "useClassicAddress",
        },
        {
          id: "RegisteredAddressCity",
          prioLabel: "City",
          type: "inputText",
          label: "Address city",
          maxLength: 60,
          displayCondition: "useClassicAddress",
        },
        {
          id: "RegisteredAddressCountry",
          type: "selectOptions",
          prioLabel: "Country",
          label: "Address country",
          selectOptionsList: this.parseCountryOptions(),
          displayCondition: "useClassicAddress",
        },
      ],
      Passport: [
        {
          id: "ExpiryDate",
          type: "date",
          label: "Expiry Date",
          startDate: this.helperService.getTodayAsMaxDate(),
          minDate: this.helperService.getTodayAsMaxDate(),
          maxDate: this.helperService.getAsMaxDatePlusYears(20),
        },
        {
          id: "IssuingDate",
          type: "date",
          label: "Issuing Date",
          startDate: this.helperService.getAsMinDateMinusYears(5),
          maxDate: this.helperService.getTodayAsMaxDate(),
          minDate: this.helperService.getAsMinDateMinusYears(20),
        },
        {
          id: "IssuingAuthority",
          type: "inputText",
          label: "Issuing Authority",
          maxLength: 60,
        },
        {
          id: "IssuingCountry",
          type: "selectOptions",
          label: "Issuing Country",
          selectOptionsList: this.parseCountryOptions(),
        },
        {
          id: "DocumentNumber",
          type: "inputText",
          label: "Document Number",
          maxLength: 60,
        },
      ],
      IdentityCard: [
        {
          id: "ExpiryDate",
          label: "Expiry Date",
          type: "date",
          startDate: this.helperService.getTodayAsMaxDate(),
          minDate: this.helperService.getTodayAsMaxDate(),
          maxDate: this.helperService.getAsMaxDatePlusYears(20),
        },
        {
          id: "IssuingAuthority",
          type: "inputText",
          label: "Issuing Authority",
          maxLength: 60,
        },
        {
          id: "IssuingCountry",
          label: "Issuing Country",
          type: "selectOptions",
          selectOptionsList: this.parseCountryOptions(),
        },
        {
          id: "DocumentNumber",
          label: "Document Number",
          type: "inputText",
          maxLength: 60,
        },
      ],
      DriversLicense: [
        {
          id: "ExpiryDate",
          label: "Expiry Date",
          type: "date",
          startDate: this.helperService.getTodayAsMaxDate(),
          minDate: this.helperService.getTodayAsMaxDate(),
          maxDate: this.helperService.getAsMaxDatePlusYears(20),
        },
        {
          id: "IssuingAuthority",
          type: "inputText",
          label: "Issuing Authority",
          maxLength: 60,
        },
        {
          id: "IssuingCountry",
          label: "Issuing Country",
          type: "selectOptions",
          selectOptionsList: this.parseCountryOptions(),
        },
        {
          id: "DocumentNumber",
          label: "Document Number",
          type: "inputText",
          maxLength: 60,
        },
      ],
      ArticlesOfAssociation: [
        {
          id: "DateSigned",
          label: "Date Signed",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(100),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(5),
        },
      ],
      TaxReturn: [
        { id: "Year", label: "Year", type: "inputText", maxLength: 10 },
        { id: "DocumentDate", type: "date" },
      ],
      Authorisation: [
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(100),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(5),
        },
      ],
      CertificateOfIncorporation: [
        { id: "IncorporationDate", type: "date" },
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(100),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(5),
        },
      ],
      TaxRegistration: [
        {
          id: "RegistrationDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(100),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(5),
        },
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(100),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(5),
        },
      ],
      CertificateOfIncumbency: [
        { id: "IssuingAuthority", type: "inputText", maxLength: 60 },
        {
          id: "IssuingCountry",
          type: "selectOptions",
          selectOptionsList: this.parseCountryOptions(),
        },
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(100),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(5),
        },
      ],
      "LatestFinancialStatements/Accounts": [
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(100),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(5),
        },
        {
          id: "IndependentlyAudited",
          type: "selectOptions",
          dynamicSelect: "yn_list",
          selectOptionsList: yn_list,
        },
      ],
      MemorandumOfArticlesAndAssociation: [
        { id: "DocumentDate", type: "date" },
      ],
      "TrustDeed/Agreement": [
        { id: "DateSigned", type: "date" },
        {
          id: "CurrentlyInEffect",
          type: "selectOptions",
          selectOptionsList: yn_list,
        },
      ],
      AmendmentToDeed: [
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(100),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(5),
        },
      ],
      RegistryExtract: [
        { id: "RegistryName", type: "inputText", maxLength: 60 },
        {
          id: "DateExtracted",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(2),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusMonths(3),
        },
      ],
      ExtractOfTrustInstrument: [
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(100),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(5),
        },
      ],
      SignedLetterFromTrustee: [
        {
          id: "DateSigned",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(2),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusMonths(3),
        },
      ],
      Charter: [
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(100),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(5),
        },
      ],
      PartnershipAgreement: [
        {
          id: "DateSigned",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(100),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(5),
        },
      ],
      CertificationOfRegistration: [
        { id: "IssuingAuthority", type: "inputText", maxLength: 60 },
        {
          id: "IssuingCountry",
          type: "selectOptions",
          selectOptionsList: this.parseCountryOptions(),
        },
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(100),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(5),
        },
      ],
      UtilityBill: [
        { id: "UtilityCompany", type: "inputText", maxLength: 60 },
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(2),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusMonths(3),
        },
      ],
      BankStatement: [
        { id: "BankName", type: "inputText" },
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(2),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusMonths(3),
        },
      ],
      CreditCardStatement: [
        { id: "CreditIssuer", type: "inputText", maxLength: 60 },
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(2),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusMonths(3),
        },
      ],
      InsuranceAgreement: [
        { id: "InsuranceProvider", type: "inputText", maxLength: 60 },
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(100),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(5),
        },
      ],
      "Investment/SecuritiesStatement": [
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(2),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusMonths(3),
        },
      ],
      MortgageStatement: [
        { id: "MortgageIssuer", type: "inputText", maxLength: 60 },
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(2),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusMonths(3),
        },
      ],
      TaxBill: [
        { id: "TaxAuthority", type: "inputText", maxLength: 60 },
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(2),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusMonths(3),
        },
      ],
      IntroductoryLetter: [
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(2),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusMonths(3),
        },
        { id: "Introducer", type: "inputText", maxLength: 60 },
      ],
      TaxRateNotice: [
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(100),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(5),
        },
      ],
      TenancyDeclaration: [
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(100),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(5),
        },
      ],
      MotorVehicleLicenceRenewal: [
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(100),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(5),
        },
      ],
      LetterFromRetirementVillage: [
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(2),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusMonths(3),
        },
      ],
      LeaseRentalAgreement: [
        { id: "DateSigned", type: "date" },
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(3),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusMonths(3),
        },
      ],
      CourtOrder: [
        { id: "NameOfCourt", type: "inputText", maxLength: 60 },
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(100),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(5),
        },
      ],
      DeclarationOfVisit: [
        { id: "DateOfVisit", type: "date" },
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(100),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(5),
        },
      ],
      MunicipalityBill: [
        { id: "Municipality", type: "inputText", maxLength: 60 },
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(2),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusMonths(3),
        },
      ],
      "ShareRegister/RegisterOfMembers": [
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(100),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(5),
        },
      ],
      "Structure/OrganizationChart": [{ id: "DateSigned", type: "date" }],
      AuthorizedSignatoryList: [
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(100),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(5),
        },
      ],
      RegisterOfDirectors: [
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(100),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(5),
        },
      ],
      RegisterOfPartners: [
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(100),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(5),
        },
      ],
      BoardResolution: [
        {
          id: "ResolutionDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(50),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(1),
        },
      ],
      CommitteeResolution: [
        {
          id: "ResolutionDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(50),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(1),
        },
      ],
      MeetingMinutes: [
        {
          id: "MeetingDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(50),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(1),
        },
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(50),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(1),
        },
      ],
      InvestmentCommitteeResolution: [
        {
          id: "ResolutionDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(50),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(1),
        },
      ],
      SignedTaxReturns: [
        {
          id: "DateSigned",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(3),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusMonths(3),
        },
      ],
      SourceOfFundsDeclaration: [
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(2),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusMonths(3),
        },
      ],
      SourceOfWealthDeclaration: [
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(2),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusMonths(3),
        },
      ],
      GovernmentIssuedCorrespondence: [
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(2),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusMonths(3),
        },
      ],
      LLCOperatingAgreement: [
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(50),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(1),
        },
      ],
      LocatorAddressLetter: [
        {
          id: "DateExecuted",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(50),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(1),
        },
      ],
      AMLAssurancesLetter: [
        {
          id: "DateExecuted",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(50),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(1),
        },
      ],
      RoyalDecree: [
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(50),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(1),
        },
      ],
      CertificateOfFormation: [
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(100),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(5),
        },
      ],
      LastWillAndTestament: [
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(100),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(5),
        },
      ],
      InvestmentManagementAgreement: [
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(50),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(1),
        },
      ],
      ProofOfRegulation: [
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(50),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(1),
        },
      ],
      "Prospectus/OfferingMemorandum": [
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(50),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(1),
        },
      ],
      StockListingEvidence: [
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(50),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(1),
        },
      ],
      Other: [
        {
          id: "DocumentDate",
          type: "date",
          minDate: this.helperService.getAsMinDateMinusYears(50),
          maxDate: this.helperService.getTodayAsMaxDate(),
          startDate: this.helperService.getAsMinDateMinusYears(1),
        },
      ],
    },
    digitalVerifications: {
      type: "counter_group",
      class: "checkbox-borderless mt-3 verification-configuration-screen",
      params: {
        groupTitle: "Digital verifications",
        checkboxes: [
          {
            type: "checkbox",
            params: {
              id: "IdentityInstantAI",
              name: "IdentityInstantAI",
              label: "Identity verification (instant AI)",
              value: "IdentityInstantAI",
              oneDocument: true,
              class: "counter-alternate",
            },
          },
          {
            type: "checkbox",
            params: {
              id: "IdentityVerification",
              name: "IdentityVerification",
              label: "Identity verification",
              value: "IdentityVerification",
              oneDocument: true,
              class: "counter-alternate",
            },
          },
          {
            type: "checkbox",
            params: {
              id: "AddressVerification",
              name: "AddressVerification",
              label: "Address verification",
              value: "AddressVerification",
              oneDocument: true,
              class: "counter-alternate",
            },
          },
          {
            type: "checkbox",
            params: {
              id: "ContractVerification",
              name: "ContractVerification",
              label: "Contract verification",
              value: "ContractVerification",
              oneDocument: true,
              class: "counter-alternate",
            },
          },
        ],
      },
    },
    digitalVerificationsLegalPerson: {
      type: "counter_group",
      class: "checkbox-borderless mt-3",
      params: {
        groupTitle: "Digital verifications",
        checkboxes: [
          {
            type: "checkbox",
            params: {
              id: "ContractVerification",
              name: "ContractVerification",
              label: "Contract verification",
              value: "ContractVerification",
              oneDocument: true,
              class: "counter-alternate",
            },
          },
        ],
      },
    },
  };
  getDocumentTypeList() {
    return this.documentTypeList;
  }
  parseCountryOptions(emptyLabel?) {
    // build country list
    const ret_country_list = [];
    country_list_array.forEach((elem) => {
      ret_country_list.push({
        key: elem,
        name: this.helperService.parseCountryName(elem),
      });
    });
    ret_country_list.sort((countryA, countryB) =>
      countryA.name > countryB.name ? 1 : -1,
    );
    ret_country_list.unshift({
      key: "emptySelect",
      name: emptyLabel ? emptyLabel : "",
    });
    return ret_country_list;
  }
}
